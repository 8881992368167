import React from "react";
import { Paper, Grid, Typography, Box } from "@mui/material";
import "./Style.css";
import AnimateFromDown from "../../components/AnimateFromDown";
const MedhaanshIntro = () => {
  return (
    <Paper elevation={0}>
      <Paper
        sx={{
          height: "100%",
          minHeight: "750px",
          display: "grid",
          placeItems: "center",
          pb: 5,
          pt: 5,
        }}
        elevation={0}
      >
        <AnimateFromDown>
          <Grid container sx={{}}>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12} sx={{}}>
              <Box sx={{ width: "90%", mx: "auto" }}>
                {/* <Typography sx={TitleStyle}>MEDHAANSH </Typography> */}
                <Typography sx={TitleStyle}>UPCOMING </Typography>
                <Typography
                  sx={{
                    ...TitleStyle,
                    fontSize: {
                      xl: "20px",
                      lg: "20px",
                      md: "20px",
                      sm: "20px",
                      xs: "20px",
                    },
                  }}
                >
                  <span style={{ color: "rgba(0, 0, 0, 0.4)" }}>
                    @SHELA EXTENSION
                  </span>
                </Typography>
                <Typography sx={TitleBottomText}>
                  Commune Living. Right Here
                </Typography>
                <h1 className="InvernessIntroText sequence fadeInBottom">
                  Our upcoming Shela extension represents a township that builds
                  a community that still believes in the sweet essence of green
                  and serene living quarters, a community that still believes in
                  interacting with one another in person and stepping out into
                  the fresh and lush gardens to walk, run and experience the
                  world beyond technology. And yet has the right amenities to
                  cater to this community’s modern-day living standards and
                  luxuries. With Medhaansh, we decided to create homes. We
                  decided to create communities. With the perks of all spheres
                  of life right here, we have created comfort and warmth right
                  next-door. All this perched right amidst institutions of
                  knowledge and repute, all planned as a part of the township.
                  With no other high-rise development in the affordable segment
                  at Shela extension road, it becomes the first step towards a
                  booming tomorrow in the real estate market. It is serene,
                  private and yet approachable to high-density living in the
                  vicinity.
                </h1>
              </Box>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <Box
                sx={{
                  width: "100%",
                  maxWidth: "597px",
                  ml: "auto",
                  mr: "auto",
                  mt: {
                    xl: "0px",
                    lg: "0px",
                    md: "20px",
                    sm: "20px",
                    xs: "20px",
                  },
                }}
              >
                <div className="MedhanshIntroImgTwo img-zoom"></div>
              </Box>
            </Grid>
          </Grid>
        </AnimateFromDown>
      </Paper>
    </Paper>
  );
};

export default MedhaanshIntro;

const TitleStyle = {
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "32px",
  lineHeight: "45px",
  color: "#333333",
  textAlign: "left",
  textTransform: "uppercase",
};

const TitleChildStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: { xl: "16px", lg: "16px", md: "14px", sm: "14px", xs: "14px" },
  mt: 2,
  lineHeight: "26px",
  color: "#808186",
  textAlign: "Justify",
  animation: "slide-up 2s ease-in-out",
  "@keyframes slide-up": {
    "0%": {
      opacity: 0,
      lineHeight: "30px",
    },
    "100%": {
      opacity: 1,
      lineHeight: "26px",
    },
  },
};

const TitleBottomText = {
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 400,
  mt: 1,
  mb: 1,
  fontSize: "24px",
  lineHeight: "28px",
  textAlign: "left",
  color: "#252733",
};

const BottomText = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "32px",
  textAlign: "left",
  color: "#333333",
};
