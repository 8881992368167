import React, { useEffect } from "react";
import { Paper, Stack } from "@mui/material";
import InvernessAB from "../../Pages/Inverness/InvernessAB";
// import InvernessGalary from "../../Pages/Inverness/InvernessGalary";
import OurArchitects from "../../Pages/Inverness/OurArchitects";
import OurPartners from "../../Pages/Inverness/OurPartners";
// import LegalAdvisor from "../../Pages/Inverness/LegalAdvisor";
import ContactInformation from "../../Pages/Inverness/ContactInformation";
import Amentise from "../../Pages/Inverness/Amentise";
import DownloadB from "../../Pages/Inverness/DownloadB";
import HeroSection from "../../Pages/Inverness/HeroSection";
// import GalaryGridView from "../../Pages/Inverness/GalaxyGridView/GalaryGridView";
import Header from "../../components/Header/HomeHeader";
import InvernessGalaryNew from "../../Pages/Inverness/InvernessGlaryNew";

const Inverness = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Paper elevation={0}>
      <Header />
      <HeroSection />
      <Stack sx={{ width: "90%", mx: "auto", maxWidth: "2400px" }}>
        <InvernessAB />
        <InvernessGalaryNew />
        <Amentise />
        <DownloadB />
        <OurArchitects />
      </Stack>
      <OurPartners />
      <Stack sx={{ width: "90%", mx: "auto", maxWidth: "2400px" }}>
        {/* <LegalAdvisor /> */}
        <ContactInformation />
      </Stack>
    </Paper>
  );
};

export default Inverness;
