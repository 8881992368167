import React, { useEffect, useState, useLayoutEffect } from "react";
import { Stack } from "@mui/system";
import { Paper, Grid } from "@mui/material";
import HeroSection from "../../Pages/HomePages/HeroSection";
import AnimateFromSideLoader from "../../components/AnimateFromSideLoader";
import WhatOurClientSays from "../../Pages/HomePages/WhatOurClientSays";
import NewSlider from "../../Pages/HomePages/NewSlider";
import Logo from "../../assets/Images/FooterImg/Logo.png";
import OurProject from "../../Pages/HomePages/OurVerticals";
import AboutUs from "../../Pages/HomePages/AbousUs";
import Exprience from "../../Pages/HomePages/Exprience";
import PreviouProjects from "../../Pages/HomePages/PreviousProjects";
import MobileHeroSection from "../../Pages/HomePages/MobileHeroSection";
import CaroClient from "../../Pages/HomePages/CaroClient";

import MainLoader from "../../assets/Images/mainLoader.jpg";
import CaraouselNew from "../../components/CarouselNew/CarouselNew";
import Header from "../../components/Header/HomeHeader";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";

import { Howl } from "howler";
import audio from "../../assets/Music/newaudio1.mp3";
import LeafImage from "../../assets/Images/leaf.png";
import GIHEDLogo from "../../assets/Images/gihedImg.jpg";

// import HomeModal from "../../components/HomeModal";
import { MdClose } from "react-icons/md";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { GrFormClose } from "react-icons/gr";

const MusicPlay = () => {
  const [musicPlay, setMusicPlay] = useState(false);

  const sound = new Howl({
    src: [audio],
    html5: true,
  });
  return (
    <Grid container>
      <PauseIcon
        onClick={() => {
          sound.pause();
        }}
      />
      <PlayArrowIcon
        onClick={() => {
          sound.play();
        }}
      />
    </Grid>
  );
};
const Home = ({ MainBox }) => {
  const [open, setOpen] = React.useState(true);
  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    localStorage.setItem("modalload", "loaded");
  };

  useEffect(() => {
    if (MainBox) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [MainBox]);

  const [openhead, setOpenHead] = useState(false);

  window.addEventListener("scroll", () => {
    if (window.scrollY > 0) {
      setOpenHead(true);
    } else {
      setOpenHead(false);
    }
  });

  // useEffect(() => {
  //   let loaddata = localStorage.getItem("modalload");
  //   if (loaddata) {
  //     setOpen(false);
  //   } else {
  //     setOpen(true);
  //   }
  // }, []);

  if (MainBox) {
    return (
      <>
        <Grid container>
          <Grid
            item
            sx={{
              display: {
                xl: "inline",
                lg: "inline",
                md: "inline",
                sm: "none",
                xs: "none",
              },
              zIndex: "1000000",
              height: "100vh",
              width: "100%",
              placeItems: "center",
              position: "relative",
            }}
          >
            <img
              className="front-img-zoom "
              src={MainLoader}
              style={{
                height: "100vh",
                width: "100vw",
                objectFit: "cover",
              }}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid
            item
            sx={{
              display: {
                xl: "none",
                lg: "none",
                md: "inline",
                sm: "inline",
                xs: "inline",
              },
              zIndex: "1000000",
              height: "100vh",
              width: "100%",
              placeItems: "center",
              position: "relative",
            }}
            className="HomeLoaderMobile"
          >
            {/* <AnimateFromSideLoader> */}
            {/* <div className="front-img-RL"></div> */}
            {/* <img
              className="front-img-RL"
              src={MainLoader}
              style={{
                height: "100vh",
                width: "100vw",
                objectFit: "cover",
              }}
            /> */}
            {/* </AnimateFromSideLoader> */}
          </Grid>
        </Grid>
      </>
    );
  }
  return (
    <Paper elevation={0}>
      <Header />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <button
            style={{
              // position: "absolute",
              marginRight: "0px",
              marginLeft: "auto",
              background: "transparent",
              border: "none",
              width: "20px",
              height: "20px",
              cursor: "pointer",
              float: "right",
              p: 0,
            }}
            onClick={() => handleClose()}
          >
            <GrFormClose fill="black" size="25" style={{ fontWeight: 400 }} />
          </button>

          <Grid
            container
            sx={{
              width: "100%",
              mx: "auto",
            }}
          >
            {/* <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              sx={{ textAlign: "center", mt: 2 }}
            >
              <Box
                sx={{
                  width: {
                    xl: "100px",
                    lg: "100px",
                    md: "100px",
                    sm: "100px",
                    xs: "100px",
                  },
                  height: "100%",
                  mx: "auto",
                }}
              >
                <img
                  src={Logo}
                  width="100%"
                  height="auto"
                  style={{ marginLeft: "auto", marginRight: "auto" }}
                />
              </Box>
            </Grid>
            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              sx={{ textAlign: "center", mt: 2 }}
            >
              <Box
                sx={{
                  width: {
                    xl: "100px",
                    lg: "100px",
                    md: "80px",
                    sm: "70px",
                    xs: "60px",
                  },
                  mx: "auto",
                }}
              >
                <img
                  src={GIHEDLogo}
                  width="100%"
                  height="auto"
                  style={{ marginLeft: "auto", marginRight: "auto" }}
                />
              </Box>
            </Grid> */}
            {/* <Grid item xl={12} lg={12} md={12} sm={12} xs={12} >
            <img
                  src={GIHEDLogo}
                  width="100%"
                  height="auto"
                  style={{ marginLeft: "auto", marginRight: "auto" }}
                />
            </Grid> */}
          </Grid>
        </Box>
      </Modal>

      <Paper
        elevation={0}
        sx={{
          display: {
            xl: "block",
            lg: "block",
            md: "block",
            sm: "none",
            xs: "none",
          },
          visibility: "visible",
          width: "100%",
          maxWidth: "100%",
          overFlow: "hidden",
        }}
      >
        <CaraouselNew />
      </Paper>
      <Paper
        elevation={0}
        sx={{
          display: {
            xl: "none",
            lg: "none",
            md: "none",
            sm: "block",
            xs: "block",
          },
        }}
      >
        <MobileHeroSection />
      </Paper>
      <Stack sx={{ width: "100%", maxWidth: "2400px", mx: "auto" }}>
        <AboutUs />
        <OurProject />
        <PreviouProjects />
        <CaroClient />
        <Exprience />
      </Stack>
    </Paper>
  );
};

export default Home;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: { xl: "500px", lg: "500px", md: "450px", sm: "280px", xs: "280px" },
  boxShadow: 10,
  borderRadius: "10px",
  py: 0,
  pb: 1,
  px: 2,
  backgroundImage: `url(${GIHEDLogo})`,
  backgroundSize: {
    xl: "cover",
    lg: "cover",
    md: "cover",
    sm: "contain",
    xs: "contain",
  },
  minHeight: {
    xl: "250px",
    lg: "250px",
    md: "250px",
    sm: "140px",
    xs: "140px",
  },
  minWidth: "200px",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  textAlign: "center",
  outline: "0px",
};
