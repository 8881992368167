import React, { useState } from "react";
import { Paper, Grid, Typography, Box, Button, Input } from "@mui/material";
import AnimateFromDown from "../../components/AnimateFromDown";
import { Link } from "react-router-dom";
const ContactInformation = () => {
  const [name, setFirstName] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [from, setFrom] = useState("Alloa Hills");
  async function formSubmit() {
    let form = new FormData();

    form.append("name", name);
    form.append("phone", phone);
    form.append("message", message);
    form.append("from", from);
    if (name === "" || phone === "" || message === "") {
      alert("Please fill all the fields");
    } else {
      fetch("https://formbold.com/s/9XL59", {
        method: "POST",
        body: form,
      });
      alert("Thank you. your form was submited");
      form.reset();
    }
  }
  return (
    <Paper
      sx={{
        height: "100%",
        pb: "5%",
        pt: "5%",
      }}
      elevation={0}
    >
      <Paper elevation={0} sx={PaperStyle}>
        <AnimateFromDown>
          <Grid container>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <Box sx={ContactInformationBox} className="hover-shadow">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    pt: 5,
                  }}
                >
                  <Typography sx={MainTitleStyle}>LET S TALK</Typography>
                  <Typography sx={TextStyle}>+91 9898048844</Typography>
                  <Typography sx={TextStyle}> +079 26445208</Typography>
                  <Typography sx={MailTextStyle}>info@unispace.in</Typography>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              sx={{ mt: { xl: 0, lg: 0, md: 0, sm: 3, xs: 3 } }}
            >
              <Box sx={ContactInformationBox} className="hover-shadow">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    pt: 5,
                  }}
                >
                  <Typography sx={MainTitleStyle}>CORPORATE OFFICE</Typography>
                  <Typography sx={TextStyle}>
                    8AB Centrepoint, Panchvati
                  </Typography>
                  <Typography sx={TextStyle}>
                    crossroads, Ambawadi, Ahmedabad
                  </Typography>
                  <Typography sx={TextStyle}>380006</Typography>
                  <a
                    href="/contactus/#mapId"
                    style={{ textDecoration: "none" }}
                  >
                    <Typography sx={MailTextStyle}>=Get Directions</Typography>
                  </a>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </AnimateFromDown>
        <Box sx={{ width: "100%", mt: 20 }}>
          <AnimateFromDown>
            <Typography sx={MainTitleStyleTwo}>INQUIRY</Typography>
            <Typography sx={ParaStyle}>
              Request you to kindly fill all the required details.
            </Typography>
            <Paper
              elevation={0}
              sx={{ mt: "40px", width: "100%", mx: "auto", maxWidth: "800px" }}
            >
              <form onSubmit={(e) => e.preventDefault()}>
                <Grid
                  container
                  sx={{ mt: "50px", maxWidth: "500px", mx: "auto" }}
                >
                  <Grid
                    item
                    xl={6}
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    sx={{ mt: "10px" }}
                  >
                    <input
                      className="InputBoxStyle1"
                      type="text"
                      placeholder="First Name"
                      required
                      name="FirstName"
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </Grid>

                  <Grid
                    item
                    xl={6}
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    sx={{
                      textAlign: {
                        xl: "center",
                        lg: "center",
                        md: "center",
                        sm: "center",
                        xs: "center",
                      },
                      mt: "10px",
                    }}
                  >
                    <input
                      className="InputBoxStyle2"
                      type="text"
                      placeholder="Contact Info"
                      name="Phone"
                      required
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </Grid>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <textarea
                      style={{
                        fontFamily: "Poppins",
                        paddingLeft: "10px",
                        paddingTop: "10px",
                        resize: "none",
                      }}
                      className="LastInputBoxStyle"
                      type="text"
                      placeholder="Write Message"
                      name="Message"
                      required
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Button
                      sx={{
                        mt: {
                          xl: "40px",
                          lg: "40px",
                          md: "30px",
                          sm: "45px",
                          xs: "45px",
                        },
                        mb: {
                          xl: "0px",
                          lg: "0px",
                          md: "0px",
                          sm: "80px",
                          xs: "80px",
                        },
                        width: "180px",
                        height: "50px",
                        background: "#F4F4F4",
                        borderRadius: "0px",
                        fontSize: "18px",
                        textAlign: "center",
                        letterSpacing: "0.05em",
                        textTransform: "uppercase",
                        color: "#BABABA",
                        "&:hover": {
                          backgroundColor: "black",
                          color: "white",
                        },
                      }}
                      onClick={formSubmit}
                    >
                      Send
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Paper>
          </AnimateFromDown>
        </Box>
      </Paper>
    </Paper>
  );
};

export default ContactInformation;

const ContactInformationBox = {
  width: "90%",
  maxWidth: "410px",
  height: "100%",
  minHeight: "200px",
  background: "#F9F9F9",
  mx: "auto",
  borderRadius: "0px",
  mt: "50px",
};

const MainTitleStyle = {
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: { xl: "24px", lg: "24px", md: "24px", sm: "22px", xs: "22px" },
  mb: 2,
  lineHeight: "32px",
  color: "#333333",
  textAlign: "left",
  letterSpacing: "0.7px",
  textTransform: "uppercase",
};

const MainTitleStyleTwo = {
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "45px",
  mb: 2,
  lineHeight: "65px",
  color: "#333333",
  textAlign: "center",
  letterSpacing: "0.7px",
  textTransform: "uppercase",
};

const MailTextStyle = {
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  mt: "15px",
  fontWeight: 600,
  fontSize: "14px",
  lineHeight: "26px",
  color: "#333333",
  textAlign: "left",
  letterSpacing: "0.7px",
  textTransform: "uppercase",
};

const TextStyle = {
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: { xl: "18px", lg: "16px", md: "14px", sm: "14px", xs: "14px" },
  lineHeight: "22px",
  color: "#868686",
  mt: 1,
  textAlign: "left",
};

const ParaStyle = {
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "22px",
  color: "#868686",
  mt: 1,
  textAlign: "center",
};
const PaperStyle = {
  width: "100%",
  maxWidth: "1000px",
  height: "100%",
  mx:"auto"
};
