import React from "react";
import { Paper, Grid, Typography, Box } from "@mui/material";
import { Link } from "react-router-dom";
import Logo from "../../../assets/Images/FooterImg/Logo.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import AnimateFromDown from "../../../components/AnimateFromDown";
const OurArchitects = () => {
  return (
    <Paper elevation={0}>
      <Grid container pt={20}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <AnimateFromDown>
            <Typography sx={MainTitleStyle}>
              <span style={{ color: "rgba(0, 0, 0, 0.4)", fontWeight: 500 }}>
                OUR{" "}
              </span>
              Team
            </Typography>
          </AnimateFromDown>
        </Grid>
      </Grid>
      {/* <Grid container pt={5}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <AnimateFromDown>
            <img src={Logo} alt='logo' style={{height:'100%',width:'200px'}}/>
          </AnimateFromDown>
        </Grid>
      </Grid> */}
      <Paper
        elevation={0}
        sx={{
          height: "100%",
          minHeight: "500px",
          pt: { xl: 10, lg: 10, md: 8, sm: 5, xs: 5 },
          display: "grid",
          placeItems: "center",
        }}
      >
        <Grid
          container
          sx={{
            width: "100%",
            mx: "auto",
          }}
        >
          <Grid
            item
            xl={3}
            lg={3}
            md={3}
            sm={6}
            xs={12}
            sx={{ mx: "auto", mt: 3 }}
          >
            <Box
              sx={{
                width: "85%",
                maxWidth: "600px",
                textAlign: "center",
                mx: "auto",
              }}
            >
              {/* <LazyLoadImage
                      delayTime={2000}
                      src={StudioArchitec}
                      width="160px"
                      alt="PrabhakarImg"
                    /> */}
              <Typography sx={NameText}>ASHISH KHURANA</Typography>
              <Typography sx={ArchitecNameStyle}>MSK ENGG</Typography>

              <h1 className="ourArchitecText sequence fadeInBottom">
                Mr. Khurana is an alumnus of Mayo who started his construction
                business MSK Engineering in 1989.
              </h1>
              {/* <Link to="/" style={{ textDecoration: "none" }}>
                      <h1 className="LinkStyleText">www.link.com</h1>
                    </Link> */}
            </Box>
          </Grid>
          <Grid
            item
            xl={3}
            lg={3}
            md={3}
            sm={6}
            xs={12}
            sx={{ mx: "auto", mt: 3 }}
          >
            <Box
              sx={{
                width: "85%",
                maxWidth: "600px",
                textAlign: "center",
                mx: "auto",
              }}
            >
              {/* <LazyLoadImage
                delayTime={2000}
                src={MozacImg}
                width="160px"
                alt="PrabhakarImg"
              /> */}
              <Typography sx={NameText}>ANKUR JOBALIYA</Typography>
              <Typography sx={ArchitecNameStyle}>KUSHAL COMPLIANCE</Typography>

              <h1 className="ourArchitecText sequence fadeInBottom">
                Mr. Jobaliya is the director of Kushal Compliance and has a
                major client base of corporates.
              </h1>
              {/* <Link to="/" style={{ textDecoration: "none" }}>
                <h1 className="LinkStyleText">www.link.com</h1>
              </Link> */}
            </Box>
          </Grid>
          <Grid
            item
            xl={3}
            lg={3}
            md={3}
            sm={6}
            xs={12}
            sx={{ mx: "auto", mt: 3 }}
          >
            <Box
              sx={{
                width: "85%",
                maxWidth: "600px",
                textAlign: "center",
                mx: "auto",
              }}
            >
              {/* <LazyLoadImage
                delayTime={2000}
                src={HirenPatel}
                width="160px"
                alt="PrabhakarImg"
              /> */}
              <Typography sx={NameText}>Hemang Patel</Typography>
              <Typography sx={ArchitecNameStyle}>OMNI LENS</Typography>
              <h1 className="ourArchitecText sequence fadeInBottom">
                Hemang Patel is the founder of an ophthalmic manufacturing
                company - ‘Omni Lens’ and initiated the Aalloa Hills project in
                1996.
              </h1>
              {/* <a
                href="www.omnilens.in"
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <h1 className="LinkStyleText">www.omnilens.in</h1>
              </a> */}
            </Box>
          </Grid>
          <Grid
            item
            xl={3}
            lg={3}
            md={3}
            sm={6}
            xs={12}
            sx={{ mx: "auto", mt: 3 }}
          >
            <Box
              sx={{
                width: "85%",
                maxWidth: "600px",
                textAlign: "center",
                mx: "auto",
              }}
            >
              {/* <LazyLoadImage
                delayTime={2000}
                src={PDSLogo}
                width="98px"
                alt="PrabhakarImg"
              /> */}
              <Typography sx={NameText}>SIDDHARTH BAGADIA</Typography>
              <Typography sx={ArchitecNameStyle}>UNISPACE</Typography>

              <h1 className="ourArchitecText sequence fadeInBottom">
                {/* Umesh is an Architect & Urban Designer with twenty-four years of
                progressively responsible experience including contribution to
                 of international repute, urban
                design in PPP projects. */}
                Siddharth Bagadia is the Chairman of the Unispace Group, whose
                sole focus is to curate spaces that spread happiness.
              </h1>
              {/* <a
                href="https://www.unispace.in/"
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <h1 className="LinkStyleText">www.unispace.in</h1>
              </a> */}
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Paper>
  );
};

export default OurArchitects;

const NameText = {
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: { xl: "19px", lg: "19px", md: "16px", sm: "16px", xs: "16px" },
  lineHeight: "29px",
  color: "#333333",
  textAlign: "center",
  textTransform: "uppercase",
  mt: "20px",
  mb: "20px",
};

const BottomText = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: { xl: "16px", lg: "16px", md: "15px", sm: "14px", xs: "14px" },
  lineHeight: "24px",
  color: "#808186",
  textAlign: "center",
  animation: "slide-up 1.1s ease-in-out",
  "@keyframes slide-up": {
    "0%": {
      opacity: 0.2,
      lineHeight: "28px",
    },
    "100%": {
      opacity: 1,
      lineHeight: "24px",
    },
  },
};

const MainTitleStyle = {
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: { xl: "36px", lg: "36px", md: "30px", sm: "26px", xs: "26px" },
  lineHeight: "43px",
  color: "#333333",
  textAlign: "center",
  textTransform: "uppercase",
};

const LinkTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "14px",
  lineHeight: "31px",
  textAlign: "center",
  letterSpacing: "0.04em",
  color: "#5392BF",
  mt: "20px",
};

const ArchitecNameStyle = {
  fontSamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "12px",
  lineHeight: "21px",
  textAlign: "center",
  color: "#4F4F4F",
  mt: "30px",
};
