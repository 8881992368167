import React, { useState, useEffect } from "react";
import { Paper, Grid, Box, Typography, Button } from "@mui/material";
import Logo from "../../assets/Images/FooterImg/Logo.png";
import { Link } from "react-router-dom";
import "./Header.css";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoMdClose } from "react-icons/io";
import { useLocation } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import LogoWhite from "../../assets/Images/FooterImg/newLogoWhiteOne.png";
import "./headerfix.css";

const HomeHeader = () => {
  let location = useLocation();
  const [navState, setNavState] = useState("NavbarStyle");
  const [newnavbarstate, setNewNavbarState] = useState("NewNavbarStyle");
  const [dropdownstate, setDropdownState] = useState(
    "rgba(000, 000, 000, 0.2)"
  );

  const [isOpen, setIsOpen] = useState(false);
  const [navStyle, setNavStyle] = useState(false);

  window.addEventListener("scroll", () => {
    if (window.scrollY > 100) {
      setNavStyle(true);
      setNavState("NavbarStyleActive");
      setDropdownState("#fff");
      setNewNavbarState("NewNavbarStyleUnActive");
    } else {
      setNavStyle(false);
      setNavState("NavbarStyle");
      setDropdownState("rgba(000, 000, 000, 0.2)");
      setNewNavbarState("NewNavbarStyle");
    }
    if (window.scrollY === 0) {
      setIsOpen(false);
    }
  });

  async function openNacv() {
    setIsOpen(true);
    setNavStyle(true);
  }
  async function closeNav() {
    setIsOpen(false);
    setNavStyle(false);
  }

  return (
    <>
      <div className={navState}>
        <Paper
          elevation={0}
          sx={{
            display: {
              xl: "block",
              lg: "block",
              md: "block",
              sm: "none",
              xs: "none",
            },
            bgcolor: "transparent",
            maxWidth: "2400px",
            mx: "auto",
          }}
        >
          <Grid container>
            <Grid
              item
              xl={8}
              lg={8}
              md={7}
              sm={12}
              xs={12}
              sx={{ position: "relative" }}
            >
              <Box
                sx={{
                  width: "150px",
                  ml: "7%",
                  mt: "1.5%",
                  height: "100%",
                }}
              >
                <Link to="/">
                  <img src={Logo} alt="Logo" width="100%" />
                </Link>
              </Box>
            </Grid>
            <Grid item xl={4} lg={4} md={5} sm={12} xs={12}>
              <Box
                sx={{
                  display: {
                    xl: "flex",
                    lg: "flex",
                    md: "flex",
                    xs: "none",
                    sm: "block",
                  },
                  width: "400px",
                  justifyContent: {
                    xl: "space-evenly",
                    lg: "space-evenly",
                    md: "space-evenly",
                    xs: "space-evenly",
                    sm: "space-evenly",
                  },
                  mt: 1,
                }}
              >
                <Link to="/projects" style={{ textDecoration: "none" }}>
                  <li className="dropdown dropdown-8" style={{ height: "48%" }}>
                    <Link to={"/projects"} style={{ textDecoration: "none" }}>
                      <Typography sx={TextStyle} position="relative">
                        Projects
                      </Typography>
                    </Link>
                    <ul
                      className="dropdown_menu_home"
                      style={{ background: "#fff" }}
                    >
                      <div
                        to="/allohaHills"
                        style={{ textDecoration: "none" }}
                        className="DropdownOne"
                      >
                        <li
                          style={{ background: dropdownstate }}
                          // className="dropdown_item"
                        >
                          <button className="Dropdowntextheader">
                            Development
                          </button>
                        </li>
                        <ul className="dropdown_item_two">
                          <div>
                            <Link
                              to="/allohaHills"
                              style={{ textDecoration: "none" }}
                            >
                              <li style={{ background: "#fff" }}>
                                <button className="Dropdowntextheader">
                                  Inverness @Aalloa Hills
                                </button>
                              </li>
                            </Link>
                            <Link
                              to="/medhaansh"
                              style={{ textDecoration: "none" }}
                            >
                              <li
                                style={{
                                  background: "#fff",
                                  textAlign: "left",
                                }}
                              >
                                <button
                                  className="Dropdowntextheader"
                                  style={{ textAlign: "center" }}
                                >
                                  {/* Medhaansh @Shela Extension */}
                                  Upcoming @Shela Extension
                                </button>
                              </li>
                            </Link>
                            <Link
                              to="/inverness"
                              style={{ textDecoration: "none" }}
                            >
                              <li
                                style={{
                                  background: "#fff",
                                  textAlign: "center",
                                }}
                              >
                                <button
                                  className="Dropdowntextheader"
                                  style={{ textAlign: "center" }}
                                >
                                  Inverness @Chekhla
                                </button>
                              </li>
                            </Link>
                          </div>
                        </ul>
                      </div>

                      <div
                        style={{ textDecoration: "none" }}
                        className="DropdownTwo"
                      >
                        <li style={{ background: dropdownstate }}>
                          <button className="Dropdowntextheader">
                            Contractual
                          </button>
                        </li>
                        <ul
                          className="dropdown_item_three"
                          style={{ background: "#fff" }}
                        >
                          <div>
                            <Link
                              to="/inverness3"
                              style={{ textDecoration: "none" }}
                            >
                              <li
                                style={{ background: "#fff" }}
                                className="dropdown_item"
                              >
                                <button className="Dropdowntextheader">
                                  Inverness 3
                                </button>
                              </li>
                            </Link>
                            <Link
                              to="/inverness3"
                              style={{ textDecoration: "none" }}
                            >
                              <li
                                style={{ background: "#fff" }}
                                className="dropdown_item"
                              ></li>
                            </Link>
                            <Link
                              to="/inverness4"
                              style={{ textDecoration: "none" }}
                            >
                              <li
                                style={{
                                  background: "#fff",
                                }}
                                className="dropdown_item"
                              >
                                <button className="Dropdowntextheader">
                                  Inverness 4 <sub>Rambaugh</sub>
                                </button>
                              </li>
                            </Link>
                          </div>
                        </ul>
                      </div>
                    </ul>
                  </li>
                </Link>
                <Link
                  to="/aboutus"
                  style={{ textDecoration: "none", marginTop: "20px" }}
                >
                  <Typography sx={TextStyle}>About Us</Typography>
                </Link>
                <Link
                  to="/contactus"
                  style={{
                    textDecoration: "none",
                    marginTop: "20px",
                    marginLeft: "10px",
                  }}
                >
                  <Typography sx={TextStyle}>Contact Us</Typography>
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </div>

      <div className={newnavbarstate}>
        <Paper
          elevation={0}
          sx={{
            display: {
              xl: "block",
              lg: "block",
              md: "block",
              sm: "none",
              xs: "none",
            },
            bgcolor: "transparent",
            maxWidth: "2400px",
            mx: "auto",
          }}
        >
          <Grid container>
            <Grid
              item
              xl={8}
              lg={8}
              md={7}
              sm={12}
              xs={12}
              sx={{ position: "relative" }}
            >
              <Box
                sx={{
                  width: "150px",
                  ml: "7%",
                  mt: "0.5%",
                  height: "100%",
                }}
              >
                <Link to="/">
                  <img src={LogoWhite} alt="Logo" width="100%" />
                </Link>
              </Box>
            </Grid>
            <Grid item xl={4} lg={4} md={5} sm={12} xs={12}>
              <Box
                sx={{
                  display: {
                    xl: "flex",
                    lg: "flex",
                    md: "flex",
                    xs: "none",
                    sm: "block",
                  },
                  width: "400px",
                  justifyContent: {
                    xl: "space-evenly",
                    lg: "space-evenly",
                    md: "space-evenly",
                    xs: "space-evenly",
                    sm: "space-evenly",
                  },
                  mt: 1,
                }}
              >
                <Link to="/projects" style={{ textDecoration: "none" }}>
                  <li className="dropdown dropdown-8" style={{ height: "48%" }}>
                    <Link
                      to={"/projects"}
                      style={{
                        textDecoration: "none",
                      }}
                    >
                      <Typography sx={TextStyleText} position="relative">
                        Projects
                      </Typography>
                    </Link>
                    <ul
                      className="dropdown_menu_home"
                      style={{ marginLeft: "-50px" }}
                      // style={{ background: "rgba(000, 000, 000, 0.2)" }}
                    >
                      <div
                        style={{
                          textDecoration: "none",
                        }}
                        className="DropdownOne"
                      >
                        <li
                          style={{ background: "rgba(000, 000, 000, 0.2)" }}
                          // className="dropdown_item"
                        >
                          <button
                            className="newDropDownText"
                            style={{ textAlign: "center" }}
                          >
                            Development
                          </button>
                        </li>
                        <ul className="dropdown_item_two">
                          <div>
                            <Link
                              to="/allohaHills"
                              style={{ textDecoration: "none" }}
                            >
                              <li
                                style={{
                                  background: "rgba(000, 000, 000, 0.2)",
                                  width: "100%",
                                }}
                              >
                                <button className="newDropDownText">
                                  Inverness @Aalloa Hills
                                </button>
                              </li>
                            </Link>
                            <Link
                              to="/medhaansh"
                              style={{ textDecoration: "none" }}
                            >
                              <li
                                style={{
                                  background: "rgba(000, 000, 000, 0.2)",
                                  width: "100%",
                                }}
                              >
                                <button
                                  className="newDropDownText"
                                  style={{ textAlign: "center" }}
                                >
                                  {/* Medhaansh @Shela Extensionss */}
                                  Upcoming @Shela Extension
                                </button>
                              </li>
                            </Link>
                            <Link
                              to="/inverness"
                              style={{ textDecoration: "none" }}
                            >
                              <li
                                style={{
                                  background: "rgba(000, 000, 000, 0.2)",
                                  width: "100%",
                                }}
                              >
                                <button className="newDropDownText">
                                  Inverness @Chekhla
                                </button>
                              </li>
                            </Link>
                          </div>
                        </ul>
                      </div>

                      <div
                        style={{
                          textDecoration: "none",
                          width: "200px",
                        }}
                        className="DropdownTwo"
                      >
                        <li
                          style={{ background: "rgba(000, 000, 000, 0.2)" }}
                          // className="dropdown_item"
                        >
                          <button
                            className="newDropDownText"
                            style={{ textAlign: "center" }}
                          >
                            Contractual
                          </button>
                        </li>
                        <ul className="dropdown_item_three">
                          <div>
                            <Link
                              to="/inverness3"
                              style={{ textDecoration: "none" }}
                            >
                              <li
                                style={{
                                  background: "rgba(000, 000, 000, 0.2)",
                                }}
                              >
                                <button className="newDropDownText">
                                  Inverness 3
                                </button>
                              </li>
                            </Link>
                            <Link
                              to="/inverness4"
                              style={{ textDecoration: "none" }}
                            >
                              <li
                                style={{
                                  background: "rgba(000, 000, 000, 0.2)",
                                }}
                              >
                                <button
                                  className="newDropDownText"
                                  style={{ textAlign: "left" }}
                                >
                                  Inverness 4 <sub>Rambaugh</sub>
                                </button>
                              </li>
                            </Link>
                          </div>
                        </ul>
                      </div>
                    </ul>
                  </li>
                </Link>
                <Link
                  to="/aboutus"
                  style={{ textDecoration: "none", marginTop: "20px" }}
                >
                  <Typography sx={TextStyleText}>About Us</Typography>
                </Link>
                <Link
                  to="/contactus"
                  style={{
                    textDecoration: "none",
                    marginTop: "20px",
                    marginLeft: "10px",
                  }}
                >
                  <Typography sx={TextStyleText}>Contact Us</Typography>
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </div>

      <Paper
        sx={{
          display: {
            xl: "none",
            lg: "none",
            md: "none",
            sm: "block",
            xs: "block",
          },
          width: "100%",
          bgcolor: "transparent",
          position: "fixed",
          zIndex: 10,
          height: "100px",
        }}
        elevation={0}
      >
        <Grid container sx={{ bgcolor: navStyle ? "#fff" : "transparent" }}>
          <Grid item xs={10} sm={10}>
            <Box
              sx={{
                width: "130px",
                ml: "7%",
                mt: "1%",
                height: "100%",
              }}
            >
              {navStyle ? (
                <Link to="/">
                  <img src={Logo} alt="Logo" width="100%" />{" "}
                </Link>
              ) : (
                <Link to="/">
                  <img src={LogoWhite} alt="Logo" width="100%" />
                </Link>
              )}
            </Box>
          </Grid>
          <Grid item sm={2} xs={2}>
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "grid",
                placeItems: "center",
              }}
            >
              {isOpen ? (
                <Button
                  onClick={() => {
                    closeNav();
                  }}
                >
                  <IoMdClose size={30} color="#000" />
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    openNacv();
                  }}
                >
                  {navStyle ? (
                    <GiHamburgerMenu size={25} color="#000" />
                  ) : (
                    <GiHamburgerMenu size={25} color="#fff" />
                  )}
                </Button>
              )}
            </Box>
          </Grid>

          <Grid item sm={12} xs={12}>
            {isOpen ? (
              <Box sx={{ bgcolor: "white", height: "90vh", pt: 3 }}>
                <Grid container>
                  {/* <Grid item xl={3} lg={3} md={3} sm={3} xs={3}></Grid> */}
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Accordion
                      elevation={0}
                      sx={{
                        mt: 0,
                        pt: 0,
                        minHeight: "10px",
                        bgcolor: "#fff",
                      }}
                    >
                      <AccordionSummary
                        elevation={0}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{
                          mt: "0px",
                          minHeight: "10px",
                          bgcolor: "#fff",
                        }}
                      >
                        <Link
                          to="/projects"
                          onClick={() => setIsOpen(false)}
                          style={{ textDecoration: "none" }}
                        >
                          <Typography sx={ProjectTextStyleMobile}>
                            Projects
                          </Typography>
                        </Link>
                      </AccordionSummary>
                      <AccordionDetails elevation={0}>
                        <Paper elevation={0}>
                          <Accordion elevation={0} sx={{ mt: 0, pt: 0 }}>
                            <AccordionSummary
                              elevation={0}
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              sx={{ bgcolor: "#fff" }}
                            >
                              <Typography sx={ProjectTextStyleMobile}>
                                Development
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Link
                                to="/allohaHills"
                                style={{ textDecoration: "none" }}
                              >
                                <li
                                  style={{
                                    background: dropdownstate,
                                    paddingBottom: "10px",
                                    paddingTop: "20px",
                                  }}
                                  className="dropdown_item"
                                >
                                  <button className="Dropdowntextheader">
                                    Inverness @Aalloa Hills
                                  </button>
                                </li>
                              </Link>
                              <Link
                                to="/medhaansh"
                                style={{ textDecoration: "none" }}
                              >
                                <li
                                  style={{
                                    background: dropdownstate,
                                    paddingBottom: "10px",
                                    paddingTop: "10px",
                                  }}
                                  className="dropdown_item"
                                >
                                  <button className="Dropdowntextheader">
                                    {/* Medhaansh @Shela Extension */}
                                    Upcoming @Shela Extension
                                  </button>
                                </li>
                              </Link>
                              <Link
                                to="/inverness"
                                style={{ textDecoration: "none" }}
                              >
                                <li
                                  style={{
                                    background: dropdownstate,
                                    paddingBottom: "10px",
                                    paddingTop: "10px",
                                  }}
                                  className="dropdown_item"
                                >
                                  <button className="Dropdowntextheader">
                                    Inverness @Chekhla
                                  </button>
                                </li>
                              </Link>
                            </AccordionDetails>
                          </Accordion>
                        </Paper>
                        <Paper elevation={0}>
                          <Accordion elevation={0}>
                            <AccordionSummary
                              elevation={0}
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                              sx={{ bgcolor: "#fff" }}
                            >
                              <Typography sx={ProjectTextStyleMobile}>
                                Contractual
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Link
                                to="/inverness3"
                                style={{ textDecoration: "none" }}
                              >
                                <li
                                  style={{
                                    background: dropdownstate,
                                    paddingBottom: "10px",
                                    paddingTop: "20px",
                                  }}
                                  className="dropdown_item"
                                >
                                  <button className="Dropdowntextheader">
                                    Inverness 3
                                  </button>
                                </li>
                              </Link>
                              <Link
                                to="/inverness4"
                                style={{ textDecoration: "none" }}
                              >
                                <li
                                  style={{
                                    background: dropdownstate,
                                    paddingBottom: "10px",
                                    paddingTop: "10px",
                                  }}
                                  className="dropdown_item"
                                >
                                  <button className="Dropdowntextheader">
                                    Inverness 4 <sub>Rambaugh</sub>
                                  </button>
                                </li>
                              </Link>
                              {/* <Link to="/" style={{ textDecoration: "none" }}>
                                <li
                                  style={{
                                    background: dropdownstate,
                                    paddingBottom: "10px",
                                    paddingTop: "10px",
                                  }}
                                  className="dropdown_item"
                                >
                                  <button className="Dropdowntextheader">
                                    Project 3
                                  </button>
                                </li>
                              </Link> */}
                            </AccordionDetails>
                          </Accordion>
                        </Paper>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                </Grid>
                <Link
                  to="/aboutus"
                  onClick={() => setIsOpen(false)}
                  style={{ textDecoration: "none" }}
                >
                  <Typography sx={TextStyleMobile}>About Us</Typography>
                </Link>
                <Link
                  to="/contactus"
                  onClick={() => setIsOpen(false)}
                  style={{ textDecoration: "none" }}
                >
                  <Typography sx={TextStyleMobile}>Contact Us</Typography>
                </Link>
              </Box>
            ) : null}
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default HomeHeader;

const NavbarStyle = {
  position: "fixed",
  left: "0%",
  right: "0%",
  top: "0%",
  bottom: "1.09%",
  background: "#fff",
  borderRadius: "0px",
  height: { xl: "80px", md: "80px", lg: "80px", sm: "60px", xs: "60px" },
  zIndex: "10",
  mx: "auto",
};

const TextStyle = {
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "30px",
  textAlign: "center",
  letterSpacing: "1px",
  textTransform: "uppercase",
  color: "#000",
  opacity: "0.7",
  "&:hover": {
    opacity: 1,
  },
};

const TextStyleText = {
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "30px",
  textAlign: "center",
  letterSpacing: "1px",
  textTransform: "uppercase",
  color: "#fff",
  opacity: "0.7",
  "&:hover": {
    opacity: 1,
  },
};

const TextStyleMobile = {
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "30px",
  textAlign: "left",
  letterSpacing: "1px",
  textTransform: "uppercase",
  color: "#000",
  opacity: 0.8,
  mt: "25px",
  pt: "25px",
  ml: "25px",
  mb: "20px",
};

const ProjectTextStyleMobile = {
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "10px",
  textAlign: "left",
  letterSpacing: "1px",
  textTransform: "uppercase",
  color: "#000",
  opacity: 0.8,
  mt: "0px",
  pt: "0px",
  ml: "10px",
  mb: "0px",
};

const AccordionText = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "13px",
  lineHeight: "30px",
  ml: "25px",
  textAlign: "left",
  letterSpacing: "1px",
  textTransform: "uppercase",
  color: "#000",
  opacity: 0.8,
  mt: "5px",
  pt: "10px",
  mb: "5px",
};

const DropDownText = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "30px",
  textAlign: "center",
  color: "#fff",
};

const newDropDownText = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "20px",
  textAlign: "center",
  background: "transparent",
  border: "none",
  color: "#000",
  "&:hover": {
    color: "red",
  },
};

// <Accordion elevation={0} sx={{ mt: 2, p: 0 }}>
//   <AccordionSummary
//     expandIcon={<ExpandMoreIcon />}
//     aria-controls="panel1a-content"
//     id="panel1a-header"
//   >
//     <Link
//       to="/projects"
//       onClick={() => setIsOpen(false)}
//       style={{ textDecoration: "none" }}
//     >
//       <Typography sx={ProjectTextStyleMobile}>
//         Projects
//       </Typography>
//     </Link>
//   </AccordionSummary>
//   <AccordionDetails>
//   <Accordion elevation={0} sx={{ mt: 2, p: 0 }}>
//   <AccordionSummary
//     expandIcon={<ExpandMoreIcon />}
//     aria-controls="panel1a-content"
//     id="panel1a-header"
//   >
//       <Typography sx={ProjectTextStyleMobile}>
//         Contractual
//       </Typography>
//   </AccordionSummary>
//   <AccordionDetails>
//     hello
//   </AccordionDetails>
// </Accordion>
// <Accordion elevation={0} sx={{ mt: 2, p: 0 }}>
//   <AccordionSummary
//     expandIcon={<ExpandMoreIcon />}
//     aria-controls="panel1a-content"
//     id="panel1a-header"
//   >
//       <Typography sx={ProjectTextStyleMobile}>
//         self-built
//       </Typography>
//   </AccordionSummary>
//   <AccordionDetails>
//     hello,bro
//   </AccordionDetails>
// </Accordion>
//   </AccordionDetails>
// </Accordion>
