import { Grid, Paper, Typography } from "@mui/material";
import React from "react";

import "react-lazy-load-image-component/src/effects/blur.css";
import "./Style.css";

import childrenPlay from "../../assets/Images/amenities/Childrenplay.png";
import garden from "../../assets/Images/amenities/Garden.png";
import jogging from "../../assets/Images/amenities/jogging.png";
import swim from "../../assets/Images/amenities/swim.png";
import water from "../../assets/Images/amenities/water.png";
import AmenitiesBox from "../../components/amenities";
import commonplot from "../../assets/Images/amenities/commonplot.png";
import carrom from "../../assets/Images/amenities/carrom.png";
import chess from "../../assets/Images/amenities/chess-pawn.png";
import football from "../../assets/Images/amenities/football.png";
import lake from "../../assets/Images/amenities/lake.png";
import pool from "../../assets/Images/amenities/pool.png";
import tableTennis from "../../assets/Images/amenities/table-tennis.png";
import tent from "../../assets/Images/amenities/tent.png";
import cricketground from "../../assets/Images/amenities/cricketground.png";
import beautifulllandscape from "../../assets/Images/amenities/beautifulllandscape.png";
import clubhousenew from "../../assets/Images/amenities/clubhousenew.png";
const Amentise = () => {
  return (
    <Paper
      sx={{
        height: "100%",
        minHeight: "500px",
        maxHeight: "auto",
        marginTop: "100px",
      }}
      elevation={0}
    >
      <Grid container mt={5}>
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{ textAlign: "center" }}
        >
          <Typography sx={TitleTextStyle}>AMENITIES</Typography>
        </Grid>

        <Grid
          item
          xl={11}
          lg={11}
          md={11}
          sm={11}
          xs={11}
          sx={{
            height: "40%",
            overflow: "scroll",
            mx: "auto",
            mt: "20px",
          }}
        >
          <Grid container>
            <Grid item xl={3} lg={3} md={3} sm={3} xs={6}>
              <AmenitiesBox imageUrl={tent} text={"Gazebo"} />
            </Grid>
            <Grid item xl={3} lg={3} md={3} sm={3} xs={6}>
              <AmenitiesBox
                imageUrl={childrenPlay}
                text={"Children's Play Area"}
              />
            </Grid>
            <Grid item xl={3} lg={3} md={3} sm={3} xs={6}>
              <AmenitiesBox imageUrl={lake} text={"Mini Lake"} />
            </Grid>
            <Grid item xl={3} lg={3} md={3} sm={3} xs={6}>
              <AmenitiesBox imageUrl={garden} text={"Garden"} />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xl={3} lg={3} md={3} sm={3} xs={6}>
              <AmenitiesBox imageUrl={jogging} text={"Jogging Track"} />
            </Grid>
            <Grid item xl={3} lg={3} md={3} sm={3} xs={6}>
              <AmenitiesBox imageUrl={pool} text={"Pool"} />
            </Grid>
            {/* <Grid item xl={3} lg={3} md={3} sm={3} xs={6}>
              <AmenitiesBox imageUrl={chess} text={"Chess"} />
            </Grid> */}
            <Grid item xl={3} lg={3} md={3} sm={3} xs={6}>
              <AmenitiesBox imageUrl={tableTennis} text={"Table Tennis"} />
            </Grid>
            <Grid item xl={3} lg={3} md={3} sm={3} xs={6}>
              <AmenitiesBox imageUrl={football} text={"Football"} />
            </Grid>
          </Grid>
          <Grid container>
            {/* <Grid item xl={3} lg={3} md={3} sm={3} xs={6}>
              <AmenitiesBox imageUrl={carrom} text={"Carrom"} />
            </Grid> */}
            <Grid item xl={3} lg={3} md={3} sm={3} xs={6}>
              <AmenitiesBox imageUrl={clubhousenew} text={"Two Club Houses"} />
            </Grid>
            {/* <Grid item xl={3} lg={3} md={3} sm={3} xs={6}>
              <AmenitiesBox imageUrl={water} text={"Water Supply"} />
            </Grid> */}
            <Grid item xl={3} lg={3} md={3} sm={3} xs={6}>
              <AmenitiesBox imageUrl={swim} text={"Swimming Pool"} />
            </Grid>
            <Grid item xl={3} lg={3} md={3} sm={3} xs={6}>
              <AmenitiesBox
                imageUrl={cricketground}
                text={"Mini Cricket Pitch"}
              />
            </Grid>
            <Grid item xl={3} lg={3} md={3} sm={3} xs={6}>
              <AmenitiesBox
                imageUrl={beautifulllandscape}
                text={"Beautiful Landscape "}
              />
            </Grid>
          </Grid>
          <Grid container>
            {/* <Grid item xl={3} lg={3} md={3} sm={3} xs={6}>
              <AmenitiesBox imageUrl={commonplot} text={"Common Plot"} />
            </Grid> */}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Amentise;

const TitleTextStyle = {
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "32px",
  lineHeight: "38px",
  color: "#000000",
  textAlign: "center",
};

const TextStyle = {
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "18px",
  lineHeight: "30px",
  color: "#BABABA",
  Opacity: "0.8",
  mt: 2,
  textAlign: "justify",
};

const OnHoverTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "18px",
  lineHeight: "30px",
  color: "#000",
  Opacity: "0.8",
  mt: 2,
  textAlign: "justify",
};
