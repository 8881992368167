import React, { useState } from "react";
import {
  Paper,
  Grid,
  Box,
  Typography,
  // Input,
  Button,
  // TextField,
  // Stack,
} from "@mui/material";
import "./conversion.css";
import "./startConversation.css";
import AnimateFromDown from "../../components/AnimateFromDown";
import { SendEvent } from "../../utils/sendEvent";

const StartConversation = () => {
  const [name, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [from, setFrom] = useState("Alloa Hills");

  async function formSubmit() {
    let form = new FormData();
    form.append("name", name);
    form.append("lastname", lastname);
    form.append("email", email);
    form.append("message", message);
    form.append("from", from);
    if (name === "" || lastname === "" || message === "" || email === "") {
      alert("Please fill all the fields");
    } else {
      fetch("https://formbold.com/s/9XL59", {
        method: "POST",
        body: form,
      });
      SendEvent("Contact us form submit");
      alert("Thank you. your form was submited");
      form.reset();
    }
  }
  return (
    <Paper elevation={0} sx={{ py: 10 }} id="mapId">
      <Grid container mb={8}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Box sx={{ width: "98%", mx: "auto", height: "100px" }}>
            <Typography sx={MainTextStyle}>
              START A{" "}
              <span style={{ fontWeight: 600, color: "black" }}>
                CONVERSATION
              </span>
            </Typography>
            <AnimateFromDown>
              <Typography sx={ChildTextStyle}>
                DROP US A MESSAGE AND WE’LL GET BACK TO YOU
              </Typography>
            </AnimateFromDown>
          </Box>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <Box>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m19!1m8!1m3!1d20415.09813113849!2d72.55024570908539!3d23.02389710589735!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x395e84e4cdb7817d%3A0x4199c379f3d79aaa!2sunispace!3m2!1d23.0224694!2d72.55570349999999!5e0!3m2!1sen!2sin!4v1663666019398!5m2!1sen!2sin"
              width="100%"
              height="550px"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            >
              <a href="https://www.google.com/maps/dir/23.0197481,72.5546438/unispace/@23.0214022,72.5491162,15.75z/data=!4m9!4m8!1m1!4e1!1m5!1m1!1s0x395e84e4cdb7817d:0x4199c379f3d79aaa!2m2!1d72.5557035!2d23.0224694">
                measure area map
              </a>
            </iframe>
          </Box>
        </Grid>
        <Grid item xl={0.5} lg={0.5} md={0.5} xs={12} sm={12}></Grid>
        <Grid
          item
          xl={5}
          lg={5}
          md={5}
          sm={12}
          xs={12}
          sx={{
            textAlign: "center",
            display: "grid",
            placeItems: "center",
            height: "auto",
            border: {
              xl: "2px solid black",
              lg: "2px solid black",
              md: "2px solid black",
              sm: "none",
              xs: "none",
            },
          }}
        >
          <Box
            sx={{
              width: "95%",
              maxWidth: "550px",
              height: "100%",
              minHeight: "500px",
            }}
          >
            <form onSubmit={(e) => e.preventDefault()}>
              <Box sx={{ width: "100%", height: "100%", mx: "auto" }}>
                <Grid container sx={{ mt: "50px" }}>
                  <Grid
                    item
                    xl={6}
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    sx={{ mt: "10px" }}
                  >
                    <input
                      type="text"
                      placeholder="First Name"
                      className="InputBoxStyle1"
                      required
                      name="FirstName"
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </Grid>

                  <Grid
                    item
                    xl={6}
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    sx={{
                      textAlign: {
                        xl: "center",
                        lg: "center",
                        md: "center",
                        sm: "center",
                        xs: "center",
                      },
                      mt: "10px",
                    }}
                  >
                    <input
                      type="text"
                      placeholder="Last name"
                      className="InputBoxStyle2"
                      name="Phone"
                      required
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </Grid>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <input
                      type="text"
                      placeholder="Email"
                      className="ContactInputMsgBox"
                      name="Email"
                      required
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12} mt={2}>
                    <textarea
                      style={{
                        fontFamily: "Poppins",
                        paddingLeft: "10px",
                        paddingTop: "10px",
                        resize: "none",
                      }}
                      placeholder="Message"
                      className="LastInputBoxStyle"
                      fullWidth
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Button
                      sx={{
                        mt: {
                          xl: "40px",
                          lg: "40px",
                          md: "40px",
                          sm: "40px",
                          xs: "40px",
                        },
                        width: "180px",
                        height: "50px",
                        background: "#F4F4F4",
                        borderRadius: "0px",
                        fontSize: "18px",
                        textAlign: "center",
                        letterSpacing: "0.05em",
                        textTransform: "uppercase",
                        color: "#BABABA",
                        "&:hover": {
                          backgroundColor: "black",
                          color: "white",
                        },
                      }}
                      onClick={() => formSubmit()}
                    >
                      Send
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </form>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default StartConversation;

const MainTextStyle = {
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: { xl: "40px", lg: "36px", md: "25px", sm: "25px", xs: "22px" },
  lineHeight: { xl: "50px", lg: "50px", md: "45px", sm: "40px", xs: "35px" },
  letterSpacing: "1px",
  textTransform: "uppercase",
  color: "rgba(0, 0, 0, 0.4)",
  textAlign: "center",
};

const ChildTextStyle = {
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "25px",
  color: "#808186",
  textAlign: "center",
};
