import React, { useState } from "react";
import { Paper, Grid, Typography, Box } from "@mui/material";
import AnimateFromDown from "../../components/AnimateFromDown";
import "./AaloaGalary.css";
import ImageViewer from "../../components/ImageViewer";
import img1 from "../../assets/Images/AllohaHills/g/m (1).jpg";
import img2 from "../../assets/Images/AllohaHills/g/m (2).jpg";
import img3 from "../../assets/Images/AllohaHills/g/m (3).jpg";
import img4 from "../../assets/Images/AllohaHills/g/m (4).jpg";
import img5 from "../../assets/Images/AllohaHills/g/m_5.jpg";
import img6 from "../../assets/Images/AllohaHills/g/m (6).jpg";
import img7 from "../../assets/Images/AllohaHills/g/m (7).jpg";
import img8 from "../../assets/Images/AllohaHills/g/m (8).jpg";
import img9 from "../../assets/Images/AllohaHills/g/m (9).jpg";
import img10 from "../../assets/Images/AllohaHills/AllohaHills2.png";
import img11 from "../../assets/Images/AllohaHills/AllohaHills3.png";
import img12 from "../../assets/Images/AllohaHills/AllohaHills4.png";

const AllohaHillsGalary = () => {
  const [imageViewerOpen, setImageViewerOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);
  const openViewer = (index) => {
    setSelectedImage(index);
    setImageViewerOpen(true);
  };
  const images = [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
    img12,
  ];
  return (
    <Paper
      elevation={0}
      sx={{
        maxHeight: "100%",
        height: "100%",
        mt: { xl: "20px", lg: "20px", md: "20px", sm: "40px", xs: "50px" },
      }}
    >
      <ImageViewer
        imageList={images}
        isOpen={imageViewerOpen}
        selectedImage={selectedImage}
        setIsOpen={setImageViewerOpen}
      />
      <Grid container>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Typography sx={ViewTextStyle}> VIEW </Typography>
        </Grid>
      </Grid>
      <Paper
        elevation={0}
        sx={{
          height: "100%",
          minHeight: "900px",
          width: "97.5%",
          mx: "auto",
        }}
      >
        <Grid container mt={"10px"}>
          <Grid
            item
            xl={4}
            lg={4}
            md={4}
            sm={6}
            xs={12}
            className="gal-img"
            mt={"5px"}
          >
            <div
              onClick={(e) => {
                openViewer(0);
              }}
              className="AlOneImg  img-zoom"
            ></div>
          </Grid>
          <Grid
            item
            xl={4}
            lg={4}
            md={4}
            sm={6}
            xs={12}
            className="gal-img"
            mt={"10px"}
          >
            <div
              onClick={(e) => {
                openViewer(1);
              }}
              className="AlTwoImg  img-zoom"
            ></div>
          </Grid>
          <Grid
            item
            xl={4}
            lg={4}
            md={4}
            sm={6}
            xs={12}
            className="gal-img"
            mt={"10px"}
          >
            <div
              onClick={(e) => {
                openViewer(2);
              }}
              className="AlThreeImg  img-zoom"
            ></div>
          </Grid>
          <Grid
            item
            xl={4}
            lg={4}
            md={4}
            sm={6}
            xs={12}
            className="gal-img"
            mt={"10px"}
          >
            <div
              onClick={(e) => {
                openViewer(3);
              }}
              className="AlFourImg  img-zoom"
            ></div>
          </Grid>
          <Grid
            item
            xl={4}
            lg={4}
            md={4}
            sm={6}
            xs={12}
            className="gal-img"
            mt={"10px"}
          >
            <div
              onClick={(e) => {
                openViewer(4);
              }}
              className="AlFiveImg  img-zoom"
            ></div>
          </Grid>
          <Grid
            item
            xl={4}
            lg={4}
            md={4}
            sm={6}
            xs={12}
            className="gal-img"
            mt={"10px"}
          >
            <div
              onClick={(e) => {
                openViewer(5);
              }}
              className="AlSixImg  img-zoom"
            ></div>
          </Grid>
          <Grid
            item
            xl={4}
            lg={4}
            md={4}
            sm={6}
            xs={12}
            className="gal-img"
            mt={"10px"}
          >
            <div
              onClick={(e) => {
                openViewer(6);
              }}
              className="AlSevenImg  img-zoom"
            ></div>
          </Grid>{" "}
          <Grid
            item
            xl={4}
            lg={4}
            md={4}
            sm={6}
            xs={12}
            className="gal-img"
            mt={"10px"}
          >
            <div
              onClick={(e) => {
                openViewer(7);
              }}
              className="AlEightImg  img-zoom"
            ></div>
          </Grid>{" "}
          <Grid
            item
            xl={4}
            lg={4}
            md={4}
            sm={6}
            xs={12}
            className="gal-img"
            mt={"10px"}
          >
            <div
              onClick={(e) => {
                openViewer(8);
              }}
              className="AlNineImg  img-zoom"
            ></div>
          </Grid>
        </Grid>
      </Paper>
    </Paper>
  );
};

const ViewTextStyle = {
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "36px",
  lineHeight: "43px",
  textAlign: "center",
  textTransform: "uppercase",
  color: "#333333",
};

export default AllohaHillsGalary;
