import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Paper, Button, Grid } from "@mui/material";
import { IoMdClose } from "react-icons/io";
import InvernessPdf from "../../assets/Pdf/Inverness.pdf";
import downloadFromUrl from "../../utils/downloadFromUrl";
import { SendEvent } from "../../utils/sendEvent";

const DownloadB = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [from, setFrom] = useState("Medhaansh");

  const scrollWin = () => {
    window.scrollTo(0, 9000);
  };

  async function formSubmit() {
    let form = new FormData();

    form.append("name", name);
    form.append("email", email);
    form.append("phone", phone);
    form.append("message", message);
    form.append("from", from);
    if (name === "" || email === "" || phone === "" || message === "") {
      alert("Please fill all the fields");
      handleClose();
    } else {
      fetch("https://formbold.com/s/9XL59", { method: "POST", body: form });
      SendEvent("Inverness broucher download");
      alert("Thank you. your form was submited");
      downloadFromUrl(InvernessPdf, "Inverness_alloa_hills");
      // form.reset();
      handleClose();
    }
  }
  return (
    <Paper
      sx={{
        height: "100%",
        minHeight: "200px",
        maxHeight: "200px",
        width: "100%",
        mt: "30px",
      }}
      elevation={0}
    >
      <Button sx={ButtonStyle} onClick={handleOpen}>
        Download Brochure
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Button
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              color: "#000",
              "&:hover": {
                bgcolor: "transparent",
              },
            }}
          >
            <IoMdClose size={30} />
          </Button>
          <Grid container>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography sx={MainTitleStyleTwo}>
                <span style={{ color: "rgba(34, 34, 34, 0.5)" }}>
                  Fill Your
                </span>{" "}
                DETAILS
              </Typography>
              <Typography sx={ParaStyle}>
                Request you to kindly fill all the required details.
              </Typography>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Box sx={{ width: "100%", mt: 0, mx: "auto" }}>
                <form id="form" onSubmit={(e) => e.preventDefault()}>
                  <Grid container>
                    <Grid
                      item
                      xl={11}
                      lg={11}
                      md={11}
                      sm={11}
                      xs={11}
                      sx={{ textAlign: "left", mt: 2 }}
                    >
                      <Typography sx={LableTextSTyle}>Name</Typography>
                      <input
                        type="text"
                        name="name"
                        onChange={(e) => setName(e.target.value)}
                        style={InputBoxstyle}
                      />
                    </Grid>
                    <Grid
                      item
                      xl={11}
                      lg={11}
                      md={11}
                      sm={11}
                      xs={11}
                      sx={{ textAlign: "right", mt: 2 }}
                    >
                      <Typography sx={LableTextSTyle}>Mobile</Typography>

                      <input
                        type="text"
                        name="phone"
                        onChange={(e) => setPhone(e.target.value)}
                        style={InputBoxstyle}
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xl={11} lg={11} md={11} sm={11} xs={11}>
                      <Typography sx={LableTextSTyle}>Email Address</Typography>

                      <input
                        type="text"
                        name="email"
                        onChange={(e) => setEmail(e.target.value)}
                        style={InputBoxstyle}
                      />
                    </Grid>
                    <Grid item xl={11} lg={11} md={11} sm={11} xs={11}>
                      <Typography sx={LableTextSTyle}>Message</Typography>

                      <textarea
                        type="text"
                        name="message"
                        onChange={(e) => setMessage(e.target.value)}
                        style={InputMessBoxStyle}
                      />
                    </Grid>
                    <Grid
                      item
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      sx={{ textAlign: "center" }}
                    >
                      <Button
                        type="submit"
                        sx={{
                          mt: "17px",
                          width: "180px",
                          mx: "auto",
                          height: "50px",
                          background: "#F4F4F4",
                          borderRadius: "0px",
                          fontSize: "18px",
                          textAlign: "center",
                          letterSpacing: "0.05em",
                          textTransform: "uppercase",
                          color: "#000",
                          "&:hover": {
                            backgroundColor: "#000",
                            color: "#fff",
                          },
                        }}
                        onClick={() => formSubmit()}
                      >
                        Send
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Paper>
  );
};

export default DownloadB;

const ButtonStyle = {
  width: { xl: "344px", lg: "344px", md: "300px", sm: "300px", xs: "250px" },
  height: "75px",
  color: "#BABABA",
  "&:hover": {
    background: "#000",
    color: "#fff",
  },
  border: "1px solid #C7C7C7",
  fontFamily: "Playfair Display",
};

const ModalStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "50px",
  gap: "60px",

  position: "absolute",
  width: "820px",
  height: "910px",
  left: "346px",
  top: "160.9px",
  background: "#FFFFFF",
  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.25)",
};

const MainTitleStyleTwo = {
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "25px",
  mb: 2,
  lineHeight: "45px",
  color: "#333333",
  textAlign: "center",
  letterSpacing: "0.7px",
  textTransform: "uppercase",
};

const LableTextSTyle = {
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  mt: "15px",
  fontWeight: 600,
  fontSize: "14px",
  lineHeight: "26px",
  color: "#808186",
  textAlign: "left",
  letterSpacing: "0.05em",
  textTransform: "uppercase",
  mb: "7px",
};

const TextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: { xl: "18px", lg: "16px", md: "14px", sm: "14px", xs: "14px" },
  lineHeight: "22px",
  color: "#868686",
  mt: 1,
  textAlign: "left",
};

const ParaStyle = {
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "20px",
  color: "#868686",
  mt: 0,
  textAlign: "center",
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xl: "500px", lg: "500px", md: "500px", sm: "300px", xs: "300px" },
  maxWidth: "620px",
  height: "100%",
  maxHeight: "600px",
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  p: 2,
};

const InputBoxstyle = {
  width: "100%",
  mx: "auto",
  height: "40px",
  border: "1px solid #F4F4F4",
  borderRadius: " 0px",
  paddingLeft: "20px",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "25px",
  textAlign: "left",
  letterSpacing: "0.05em",
  background: "#F4F4F4",
  color: "#868686",
  fontFamily: "Playfair Display",
};

const InputMessBoxStyle = {
  width: "100%",
  mx: "auto",
  height: "60px",
  border: "1px solid #F4F4F4",
  borderRadius: " 0px",
  paddingLeft: "20px",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "25px",
  textAlign: "left",
  letterSpacing: "0.05em",
  background: "#F4F4F4",
  resize: "none",
  color: "#868686",
  fontFamily: "Playfair Display",
};
