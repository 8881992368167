import { motion, useScroll } from "framer-motion/dist/framer-motion";
import React from "react";
export default function AnimateFromSide({ children }) {
  
  return (
    <motion.div
      initial={{ x: 50, opacity: 0 }}
      transition={{ delay: 0.2, duration: 0.4 }}
      viewport={{ once: true }}
      exit={{
        x: 30,
        opacity: 0,
      }}
      whileInView={{ x: 0, opacity: 1 }}
    >
      {children}
    </motion.div>
  );
}
