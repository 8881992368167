import React, { useRef } from "react";
import { Paper, Grid } from "@mui/material";
import HomeAboutUs from "../../assets/Images/HomeImages/HomeAboutUs.png";
import { Box, Stack, Typography } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { motion, useScroll } from "framer-motion/dist/framer-motion";
import AnimateFromDown from "../../components/AnimateFromDown";
import "./Style.css";
import { Container } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import bgImage from "../../assets/Images/HomeImages/Asset.svg"
// const AbousUs = () => {
//   const { scrollYProgress } = useScroll();
//   const playerRef = useRef();
//   return (
//     <div
//       className="AboutSectionOfHome"
//       sx={{
//         height: "auto",
//         width: "100%",
//       }}
//       elevation={0}
//     >
//       <Grid
//         container
//         sx={{ width: "90%", mx: "auto", mt: "20px", mb: "100px" }}
//       >
//         <Grid
//           item
//           xl={6.5}
//           lg={6.5}
//           md={6.5}
//           sm={12}
//           xs={12}
//           sx={{ position: "relative" }}
//         >
//           <Box sx={{ marginTop: "30%" }}>
//             <div className="AboutUs-Background"></div>
//           </Box>
//           <Box
//             sx={{
//               position: "absolute",
//               width: "90%",
//               top: "50%",
//               left: "50%",
//               transform: "translate(-50%,-50%)",
//               py: "20px",
//             }}
//           >
//             <Box>
//               <Typography sx={MainTextStyle}>
//                 About{" "}
//                 <span
//                   style={{
//                     fontWeight: 600,
//                     color: "black",
//                     fontFamily: "Playfair Display",
//                   }}
//                 >
//                   Us
//                 </span>
//               </Typography>
//             </Box>
//             <Box
//               sx={{
//                 width: {
//                   xl: "90%",
//                   lg: "90%",
//                   md: "95%",
//                   sm: "95%",
//                   xs: "95%",
//                 },
//                 ml: { xl: "0", lg: "0", md: "0", sm: "10px", xs: "10px" },
//                 mr: "auto",
//               }}
//             >
//               <h1 className="AboutHomeText sequence fadeInBottom">
//                 The Unispace group has been blessed by our founder Late Shri
//                 Bharatbhai Bagadia, a very charismatic personality who initiated
//                 the founding stones of Land development with the group. Since
//                 the early success it has been over 40 years that the firm has
//                 been serving the purpose with current leadership in the hands of
//                 Mr. Siddharth Bagadia, carrying & strongly pursuing a very
//                 unique interest towards an exemplary lifestyle based
//                 developments.The group has always had the methodology to curate
//                 sustainable yet creative spaces and to add to the virtue of our
//                 developments, Inverness @Chekhla, our very first project in the
//                 renowned Sanand region created a benchmark in the field of
//                 Luxurious developments.
//               </h1>
//               <h1 className="AboutHomeText sequence fadeInBottom" mt={4}>
//                 For a very long time our focus of development has been on our
//                 upcoming project ‘The Inverness @Aalloa hills’,which is the 5th
//                 one in the company's lineup of development. Being famous for its
//                 topography of rolling hills and brilliant ravinous landscape of
//                 supreme greenery, advancing as the highly esteemed free living
//                 retreat which is less than an hour from the ever evolving city
//                 of Ahmedabad.
//               </h1>
//             </Box>
//           </Box>
//         </Grid>
//         <Grid
//           item
//           xl={5.5}
//           lg={5.5}
//           md={5.5}
//           sm={12}
//           xs={12}
//           sx={{ display: "Grid", placeItems: "center" }}
//         >
//           <Box sx={{ width: "90%", maxWidth: "700px" }}>
//             <LazyLoadImage
//               delayTime={2000}
//               effect="opacity"
//               src={HomeAboutUs}
//               alt="AboutImg"
//               width="100%"
//             />
//           </Box>
//         </Grid>
//       </Grid>
//     </div>
//   );
// };

const AboutUs2 = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        alignItems:'center',
        minHeight:"100vh",
        mx: "auto",
        width: "90%",
        display: "flex",
        gap: "40px",
        flexDirection: {
          xl: "row",
          lg: "row",
          md: "row",
          sm: "column",
          xs: "column",
        },
      }}
    >
      <Box
        sx={{
          position: "relative",
        }}
      >
        <div
          style={{
            top: "50px",
            position: "absolute",
          }}
          className="AboutUs-Background"
        ></div>
        {/* <img  style={{
          position:'absolute',
          width:"100%",
        left:0,
        
        }} 
        alt=""
        src={bgImage}/> */}

        <Box>
          <Typography sx={MainTextStyle}>
            About{" "}
            <span
              style={{
                fontWeight: 600,
                color: "black",
                fontFamily: "Playfair Display",
              }}
            >
              Us
            </span>
          </Typography>
        </Box>
        <h1 className="AboutHomeText sequence fadeInBottom">
          {/* The Unispace group has been blessed by our founder Late Shri
          Bharatbhai Bagadia, a very charismatic personality who initiated the
          founding stones of Land development with the group. Since the early
          success it has been over 40 years that the firm has been serving the
          purpose with current leadership in the hands of Mr. Siddharth Bagadia,
          carrying & strongly pursuing a very unique interest towards an
          exemplary lifestyle based developments.The group has always had the
          methodology to curate sustainable yet creative spaces and to add to
          the virtue of our developments, Inverness @Chekhla, our very first
          project in the renowned Sanand region created a benchmark in the field
          of Luxurious developments. */}
          The Unispace group has been blessed by our founder Late Shri Bharatbhai Bagadia, a very charismatic personality who initiated the founding stones of Land development with the group. Since the early success it has been over 40 years that the firm has been serving the purpose with current leadership in the hands of Mr. Siddharth Bagadia, carrying & strongly pursuing a very unique interest towards an exemplary lifestyle based developments.
        </h1>
        <h1 className="AboutHomeText sequence fadeInBottom" mt={4}>
          {/* For a very long time our focus of development has been on our upcoming
          project ‘The Inverness @Aalloa hills’,which is the 5th one in the
          company's lineup of development. Being famous for its topography of
          rolling hills and brilliant ravinous landscape of supreme greenery,
          advancing as the highly esteemed free living retreat which is less
          than an hour from the ever evolving city of Ahmedabad. */}
          The group has always had the methodology to curate sustainable yet creative spaces and to add to the virtue of our developments, Inverness @Chekhla, our very first project in the renowned Sanand region created a benchmark in the field of Luxurious developments.From the time  the idea came into existence, our focus of developement has been our upcoming project 'The Inverness @aaloa hills which is the 5th project from the inverness lineup of our developements.''
          Being famous for its topography of rolling hills and brilliant ravinous landscape of supreme greenery, advancing as the highly esteemed free living retreat which is less than an hour from the ever evolving city of Ahmedabad.
        </h1>
        {/* 
    
         */}
      </Box>
     
        <img
          style={{
            width: matches ? downSm? "100vw":"70vw" : "40%",
            margin: "auto",
          }}
          delayTime={2000}
          effect="opacity"
          src={HomeAboutUs}
          alt="AboutImg"
        />
    </Box>
  );
};

export default AboutUs2;

const MainTextStyle = {
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: { xl: "42px", lg: "42px", md: "40px", sm: "32px", xs: "28px" },
  lineHeight: { xl: "55px", lg: "55px", md: "50px", sm: "45px", xs: "42px" },
  letterSpacing: "1px",
  textTransform: "uppercase",
  color: "rgba(0, 0, 0, 0.4)",
  textAlign: "left",
  opacity: 1,
  ml: { xl: "0", lg: "0", md: "0", sm: "10px", xs: "10px" },
  // mt: { xl: "0", lg: "0", md: "0", sm: "190px", xs: "350px" },
};

const TextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: { xl: "16px", lg: "16px", md: "16px", sm: "14px", xs: "14px" },
  lineHeight: "24px",
  color: "#808186",
  textAlign: "Justify",
  animation: "slide-up 2s ease-in-out",
  "@keyframes slide-up": {
    "0%": {
      opacity: 0,
      lineHeight: "30px",
    },
    "100%": {
      opacity: 1,
      lineHeight: "24px",
    },
  },
};
