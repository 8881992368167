import React from "react";
import { Paper, Grid, Box, Typography } from "@mui/material";
import HeroSectionImage from "../../assets/Images/ContactUsImages/Rectangle-1.png";
import "./conversion.css";
const Herosection = () => {
  return (
    <Paper
      elevation={0}
      sx={{
        maxHeight: "100vh",
        overflow: "hidden",
        border: "none",
        borderRadius: "0px",
      }}
    >
      <Grid container>
        <Box sx={boxStyle} className="img-zoom-ani">
          <Box sx={TextBox}>
            <Typography sx={titleStyle}>Contact Us</Typography>
          </Box>
        </Box>
      </Grid>
    </Paper>
  );
};

export default Herosection;

const boxStyle = {
  width: "100%",
  height: "400px",
  backgroundImage: `url(${HeroSectionImage})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  position: "relative",
};

const titleStyle = {
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: { xl: "60px", lg: "50px", md: "40px", sm: "35px", xs: "30px" },
  lineHeight: { xl: "86px", lg: "75px", md: "60px", sm: "50px", xs: "40px" },
  textTransform: "uppercase",
  color: "#FFFFFF",
};

const TextBox = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  marginLeft: "auto",
  marginRight: "auto",
};
