import React, { useState } from "react";
import { Paper, Grid, Typography, Box } from "@mui/material";
import Rect1 from "../../assets/Images/Medhaansh/Rect1.jpg";
import Rect2 from "../../assets/Images/Medhaansh/Rect2.jpg";
import Rect3 from "../../assets/Images/Medhaansh/Rect3.png";
import Rect4 from "../../assets/Images/Medhaansh/Rect4.jpg";
import Rect5 from "../../assets/Images/Medhaansh/Rect5.jpg";
import Rect6 from "../../assets/Images/Medhaansh/Rect6.jpg";
import Rect7 from "../../assets/Images/Medhaansh/Rect7.jpg";
import Ract8 from "../../assets/Images/Medhaansh/Rect8.jpg";
import Rect9 from "../../assets/Images/Medhaansh/Rect9.png";
import AnimateFromDown from "../../components/AnimateFromDown";
import "./Style.css";
import "./Galary.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
// import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import ImageViewer from "../../components/ImageViewer";

const images = [Rect1, Rect2, Rect3, Rect4, Rect5, Rect6, Rect7, Ract8, Rect9];
const MedhaanshGalary = () => {
  const [imageViewerOpen, setImageViewerOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);
  const openViewer = (index) => {
    setSelectedImage(index);
    setImageViewerOpen(true);
  };

  return (
    <Paper elevation={0}>
      <AnimateFromDown>
        <ImageViewer
          imageList={images}
          isOpen={imageViewerOpen}
          selectedImage={selectedImage}
          setIsOpen={setImageViewerOpen}
        />
        <Grid container>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Typography sx={ViewTextStyle}>VIEW</Typography>
          </Grid>
        </Grid>
      </AnimateFromDown>
      <Paper
        elevation={0}
        sx={{
          height: "100%",
          minHeight: "900px",
          width: "100%",
          mt: 5,
        }}
      >
        <AnimateFromDown>
          <Grid container>
            <Grid item xl={4} lg={4} md={4} sm={6} xs={12} mt={"10px"}>
              <div
                onClick={(e) => {
                  openViewer(0);
                }}
                className="Rect1  img-zoom"
              ></div>
            </Grid>
            <Grid
              item
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
              className="gal-img"
              mt={"10px"}
            >
              <div
                onClick={(e) => {
                  openViewer(5);
                }}
                className="Rect2  img-zoom"
              ></div>
            </Grid>
            <Grid
              item
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
              className="gal-img"
              mt={"4px"}
            >
              <div
                onClick={(e) => {
                  openViewer(2);
                }}
                className="Rect3  img-zoom "
              ></div>
            </Grid>

            <Grid
              item
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
              className="gal-img"
              mt={"5px"}
            >
              <div
                onClick={(e) => {
                  openViewer(3);
                }}
                className="Rect4  img-zoom"
              ></div>
            </Grid>
            <Grid
              item
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
              className="gal-img"
              mt={"10px"}
            >
              <div
                onClick={(e) => {
                  openViewer(4);
                }}
                className="Rect5  img-zoom"
              ></div>
            </Grid>
            <Grid
              item
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
              className="gal-img"
              mt={"10px"}
            >
              <div
                onClick={(e) => {
                  openViewer(5);
                }}
                className="Rect6  img-zoom"
              ></div>
            </Grid>

            <Grid
              item
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
              className="gal-img"
              mt={"10px"}
            >
              <div
                onClick={(e) => {
                  openViewer(6);
                }}
                className="Rect7  img-zoom"
              ></div>
            </Grid>
            <Grid
              item
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
              className="gal-img"
              mt={"10px"}
            >
              <div
                onClick={(e) => {
                  openViewer(7);
                }}
                className="Rect8  img-zoom"
              ></div>
            </Grid>
            <Grid
              item
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
              className="gal-img"
              mt={"10px"}
            >
              <div
                onClick={(e) => {
                  openViewer(8);
                }}
                className="Rect9  img-zoom"
              ></div>
            </Grid>
          </Grid>
        </AnimateFromDown>

        {/* <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
          <Masonry>
            {images.map((image, i) => (
              <img
                key={i}
                src={image}
                style={{ width: "100%", display: "block", margin: "10px 10px" }}
                alt=""
              />
            ))}
          </Masonry>
        </ResponsiveMasonry> */}
      </Paper>
    </Paper>
  );
};

export default MedhaanshGalary;

const ViewTextStyle = {
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "36px",
  lineHeight: "43px",
  textAlign: "center",
  textTransform: "uppercase",
  color: "#333333",
};
