import React, { useEffect } from "react";
import { Paper, Grid, Typography, Box } from "@mui/material";
import GrayBorder from "../../components/GrayBorder";

// import { createRoot } from "react-dom/client";
import { useInView } from "react-intersection-observer";
import { SendEvent } from "../../utils/sendEvent";

const Exprience = () => {
  const counterAnim = (qSelector, start = 0, end, duration = 1000) => {
    const target = document.querySelector(qSelector);
    let startTimestamp = null;
    const step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      target.innerText = Math.floor(progress * (end - start) + start);
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);
  };

  const { ref, inView } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    SendEvent("Total Projects counter");
    if (inView) {
      setTimeout(() => {
        counterAnim("#count1", 0, 30, 2000);
        counterAnim("#count2", 0, 200, 2000);
        counterAnim("#count3", 0, 500000, 2000);
      }, 500);
    }
  }, [inView]);

  return (
    <Paper
      sx={{
        height: "100%",
        paddingTop: "100px",
        paddingBottom: "100px",
      }}
      elevation={0}
    >
      <Grid container sx={PageStyle} inView={inView}>
        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <Box sx={{ ml: 0, mr: "auto", mt: "30px" }}>
            <Typography sx={ExprienceTextStyle}>
              Well-established and
              <br /> experienced in many fields.
            </Typography>
          </Box>
        </Grid>
        <Grid item xl={8} lg={8} md={8} sm={12} xs={12} mt={2}>
          <Grid container>
            <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
              <Box
                sx={{
                  width: {
                    xl: "80%",
                    lg: "80%",
                    md: "80%",
                    sm: "100%",
                    xs: "100%",
                  },
                  ml: { xl: "auto", lg: "auto", md: "auto", sm: 0, xs: 0 },
                  mr: {
                    xl: "auto",
                    lg: "auto",
                    md: "auto",
                    sm: "auto",
                    xs: "auto",
                  },
                  maxWidth: "300px",
                }}
              >
                <Typography sx={ExMainText}>
                  <span id="count1" class="display-4"></span>+
                </Typography>
                <Typography
                  variant="h4"
                  sx={{
                    mt: {
                      xl: "15px",
                      lg: "15px",
                      md: "15px",
                      sm: "2px",
                      xs: "2px",
                    },
                    mb: "15px",
                  }}
                >
                  <GrayBorder />
                </Typography>
                <Typography sx={FooterTextSTyle}>
                  Years of promoter’s experience
                </Typography>
              </Box>
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={12} xs={12} ref={ref}>
              <Box
                sx={{
                  width: {
                    xl: "80%",
                    lg: "80%",
                    md: "80%",
                    sm: "100%",
                    xs: "100%",
                  },
                  ml: { xl: "auto", lg: "auto", md: "auto", sm: 0, xs: 0 },
                  mr: {
                    xl: "auto",
                    lg: "auto",
                    md: "auto",
                    sm: "auto",
                    xs: "auto",
                  },
                  mt: {
                    xl: "0px",
                    lg: "0px",
                    md: "0px",
                    sm: "5px",
                    xs: "5px",
                  },
                  maxWidth: "300px",
                }}
              >
                <Typography sx={ExMainText2}>
                  <span id="count2" class="display-4"></span>+
                </Typography>
                <Typography
                  variant="h4"
                  sx={{
                    mt: {
                      xl: "15px",
                      lg: "15px",
                      md: "15px",
                      sm: "2px",
                      xs: "2px",
                    },
                    mb: "15px",
                  }}
                >
                  <GrayBorder />
                </Typography>
                <Typography sx={FooterTextSTyle}>
                  satisfied customers
                </Typography>
              </Box>
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
              <Box
                sx={{
                  width: {
                    xl: "80%",
                    lg: "90%",
                    md: "90%",
                    sm: "100%",
                    xs: "100%",
                  },
                  mt: {
                    xl: "0px",
                    lg: "0px",
                    md: "0px",
                    sm: "5px",
                    xs: "5px",
                  },
                  ml: { xl: "auto", lg: "auto", md: "auto", sm: 0, xs: 0 },
                  mr: {
                    xl: "auto",
                    lg: "auto",
                    md: "auto",
                    sm: "auto",
                    xs: "auto",
                  },
                  maxWidth: "300px",
                }}
              >
                <Typography sx={ExMainText3}>
                  <span id="count3" class="display-4"></span>{" "}
                  <span
                    style={{
                      fontSize: "13px",
                      marginLeft: "5px",
                      color: "#8b8c90",
                    }}
                  >
                    sq feet
                  </span>
                </Typography>
                <Typography
                  sx={{
                    mt: {
                      xl: "15px",
                      lg: "15px",
                      md: "15px",
                      sm: "2px",
                      xs: "2px",
                    },
                    mb: "15px",
                  }}
                >
                  <GrayBorder />
                </Typography>
                <Typography sx={FooterTextSTyle}>land developed</Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Exprience;

const ExprienceTextStyle = {
  // fontFamily: "Futura Medium BT",
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: { xl: "30px", lg: "28px", md: "28px", sm: "26px", xs: "25px" },
  lineHeight: { xl: "42px", lg: "42px", md: "42px", sm: "40px", xs: "40px" },
  letterSpacing: "1px",
  color: "#000000",
  mx: "auto",
  textAlign: "left",
};

const PageStyle = {
  width: "90%",
  mx: "auto",
};

const ExMainText = {
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: { xl: "40px", lg: "38px", md: "34px", sm: "30px", xs: "30px" },
  lineHeight: "60px",
  color: "#000000",
  display: "flex",
  textAlign: "left",
};
const ExMainText2 = {
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: { xl: "40px", lg: "38px", md: "34px", sm: "30px", xs: "30px" },
  lineHeight: "60px",
  color: "#000000",
  display: "flex",
  textAlign: "left",
  marginTop: { xl: "0px", lg: "0px", md: "0px", sm: "20px", xs: "20px" },
};

const ExMainText3 = {
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: { xl: "40px", lg: "38px", md: "34px", sm: "30px", xs: "30px" },
  lineHeight: "60px",
  color: "#000000",
  display: "flex",
  textAlign: "left",
  marginTop: { xl: "0px", lg: "0px", md: "0px", sm: "20px", xs: "20px" },
};

const FooterTextSTyle = {
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: { xl: "18px", lg: "18px", md: "18px", sm: "16px", xs: "16px" },
  lineHeight: "25px",
  textTransform: "capitalize",
  color: "#8b8c90",
  textAlign: "left",
};
