import React, { useState, useEffect } from "react";
import { Paper, Grid, Stack, Typography, Button } from "@mui/material";
// import ProjectCarousel from "../../Pages/Projects/ProjectCarousel";
// import HomeCarousel from "../../components/carousel/HomeCarousel";
import HeroSection from "../../Pages/Projects/HeroSection";
// import Slider from "react-slick";
import carosostyles from "./Caroso.css";
import Header from "../../components/Header/HomeHeader";

import Caraousel1 from "./Caraousel1";
import Caraousel2 from "./Caraousel2";
// import Caraousel3 from "./Caraousel3";
import Caraousel4 from "./Caraousel4";
import Caraousel5 from "./Caraousel5";
import { SendEvent } from "../../utils/sendEvent";

const images = [
  {
    photo:
      "https://imagens.nxplay.com.br/video_thumb/9e196b28-98ce-47d1-8e80-1f163ac8f5fb.jpg",
    label: "image1",
  },
  {
    photo:
      "https://imagens.nxplay.com.br/video_thumb/8a356d44-aaec-4ab9-b654-208e5ceeb6ff.jpg",
    label: "image2",
  },
  {
    photo:
      "https://imagens.nxplay.com.br/video_thumb/9e196b28-98ce-47d1-8e80-1f163ac8f5fb.jpg",
    label: "image1",
  },
  {
    photo:
      "https://imagens.nxplay.com.br/video_thumb/8a356d44-aaec-4ab9-b654-208e5ceeb6ff.jpg",
    label: "image2",
  },
];

const Projects = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    SendEvent("Projects page visit");
  }, []);
  const [sliderPosition, setSliderPosition] = useState(0);
  const [selected1, setSelected1] = useState(true);
  const [selected2, setSelected2] = useState(false);
  const [selected3, setSelected3] = useState(false);
  const [selected4, setSelected4] = useState(false);
  return (
    <Paper elevation={0}>
      <Header />
      <Grid container sx={{ position: "relative", minHeight: "80vh" }}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <HeroSection />
        </Grid>
        <Grid
          container
          sx={{
            minHeight: "100%",
            marginTop: "50px",
            marginBottom: "50px",
            width: { xl: "90%", lg: "90%", md: "95%", sm: "98%", xs: "98%" },
            mx: "auto",
          }}
        >
          <Grid
            item
            xl={3.5}
            lg={3.5}
            md={12}
            sm={12}
            xs={12}
            sx={{ mx: "auto" }}
          >
            <Stack
              sx={{
                width: "300px",
                textAlign: "right",
                marginRight: "40px",
                marginBottom: {
                  xl: "0px",
                  lg: "0px",
                  md: "0px",
                  sm: "40px",
                  xs: "50px",
                },
              }}
            >
              <Typography
                sx={{ textTransform: "uppercase", textAlign: "flex-start" }}
              >
                <Button
                  sx={{
                    fontFamily: "Playfair Display",
                    fontStyle: "normal",
                    fontWeight: "300",
                    fontSize: "24px",
                    lineHeight: "34px",
                    textAlign: "center",
                    textTransform: "uppercase",
                    color: "rgba(0, 0, 0, 0.4)",
                    "&:hover": {
                      bgcolor: "transparent",
                    },
                  }}
                  onClick={() => {
                    setSliderPosition(0);
                    setSelected1(true);
                    setSelected2(false);
                    setSelected3(false);
                    setSelected4(false);
                  }}
                >
                  All &nbsp;
                  <span
                    style={{
                      fontFamily: "Playfair Display",
                      fontStyle: "normal",
                      fontWeight: "900",
                      fontSize: "24px",
                      lineHeight: "34px",
                      textAlign: "center",
                      textTransform: "uppercase",
                      color: selected1 ? "black" : "rgba(0, 0, 0, 0.4)",
                    }}
                  >
                    Projects
                  </span>
                </Button>
              </Typography>
              <Typography sx={{ textTransform: "uppercase" }}>
                <Button
                  sx={{
                    fontFamily: "Playfair Display",
                    fontStyle: "normal",
                    fontWeight: "300",
                    fontSize: "24px",
                    lineHeight: "34px",
                    textAlign: "center",
                    textTransform: "uppercase",
                    color: "rgba(0, 0, 0, 0.4)",
                    "&:hover": {
                      bgcolor: "transparent",
                    },
                  }}
                  onClick={() => {
                    setSliderPosition(1);
                    setSelected1(false);
                    setSelected2(true);
                    setSelected3(false);
                    setSelected4(false);
                  }}
                >
                  Ongoing &nbsp;
                  <span
                    className={carosostyles.span}
                    style={{
                      fontFamily: "Playfair Display",
                      fontStyle: "normal",
                      fontWeight: "900",
                      fontSize: "24px",
                      lineHeight: "34px",
                      textAlign: "center",
                      textTransform: "uppercase",
                      color: selected2 ? "black" : "rgba(0, 0, 0, 0.4)",
                    }}
                  >
                    Projects
                  </span>
                </Button>
              </Typography>
              <Typography sx={{ textTransform: "uppercase" }}>
                <Button
                  sx={{
                    fontFamily: "Playfair Display",
                    fontStyle: "normal",
                    fontWeight: "300",
                    fontSize: "24px",
                    lineHeight: "34px",
                    textAlign: "center",
                    textTransform: "uppercase",
                    color: "rgba(0, 0, 0, 0.4)",
                    "&:hover": {
                      bgcolor: "transparent",
                    },
                  }}
                  onClick={() => {
                    setSliderPosition(2);
                    setSelected1(false);
                    setSelected2(false);
                    setSelected3(true);
                    setSelected4(false);
                  }}
                >
                  Upcoming &nbsp;
                  <span
                    style={{
                      fontFamily: "Playfair Display",
                      fontStyle: "normal",
                      fontWeight: "900",
                      fontSize: "24px",
                      lineHeight: "34px",
                      textAlign: "center",
                      textTransform: "uppercase",
                      color: selected3 ? "black" : "rgba(0, 0, 0, 0.4)",
                    }}
                  >
                    Projects
                  </span>
                </Button>
              </Typography>
              <Typography sx={{ textTransform: "uppercase" }}>
                <Button
                  sx={{
                    fontFamily: "Playfair Display",
                    fontStyle: "normal",
                    fontWeight: "300",
                    fontSize: "24px",
                    lineHeight: "34px",
                    textAlign: "center",
                    textTransform: "uppercase",
                    color: "rgba(0, 0, 0, 0.4)",
                    "&:hover": {
                      bgcolor: "transparent",
                    },
                  }}
                  onClick={() => {
                    setSliderPosition(3);
                    setSelected1(false);
                    setSelected2(false);
                    setSelected3(false);
                    setSelected4(true);
                  }}
                >
                  Complete &nbsp;
                  <span
                    style={{
                      fontFamily: "Playfair Display",
                      fontWeight: "900",
                      fontSize: "24px",
                      lineHeight: "34px",
                      textAlign: "center",
                      textTransform: "uppercase",
                      color: selected4 ? "black" : "rgba(0, 0, 0, 0.4)",
                    }}
                  >
                    Projects
                  </span>
                </Button>
              </Typography>
            </Stack>
          </Grid>
          <Grid
            item
            xl={8.5}
            lg={8.5}
            md={12}
            sm={12}
            xs={12}
            sx={{
              mx: "auto",
              marginTop: {
                xl: "0px",
                lg: "0px",
                md: "60px",
                sm: "60px",
                xs: "auto",
              },
            }}
          >
            {sliderPosition === 0 ? <Caraousel1 /> : null}
            {sliderPosition === 1 ? <Caraousel2 /> : null}
            {sliderPosition === 2 ? <Caraousel5 /> : null}
            {sliderPosition === 3 ? <Caraousel4 /> : null}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Projects;
