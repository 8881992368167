import React from "react";
import { Paper, Grid, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import purewater from "../../assets/Images/AllohaHills/purewater.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import AnimateFromDown from "../../components/AnimateFromDown";
const AirQuality = () => {
  return (
    <Paper elevation={0}>
      <Paper elevation={0} sx={{ mt: 5, mb: 7 }}>
        <AnimateFromDown>
          <Grid container spacing={3}>
            <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
              <Typography sx={TextStyle}>
                Better lifestyle with improved living
              </Typography>
              <Typography sx={TextStyleTwo}>
                Better air. better water.
              </Typography>
            </Grid>
            <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
              <Box sx={{ width: "85%", maxWidth: "1100px", mx: "auto", mt: 3 }}>
                <LazyLoadImage
                  delayTime={2000}
                  src={purewater}
                  width="80%"
                  alt="Air"
                />
              </Box>
            </Grid>
          </Grid>
        </AnimateFromDown>
      </Paper>
    </Paper>
  );
};

export default AirQuality;

const TextStyle = {
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: { xl: "38px", lg: "36px", md: "34px", sm: "30px", xs: "25px" },
  lineHeight: { xl: "50px", lg: "40px", mg: "35px", sm: "30px", xs: "30px" },
  textAlign: "center",
  letterSpacing: "1px",
  textTransform: "uppercase",
  color: "rgba(0, 0, 0, 0.4)",
};

const TextStyleTwo = {
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: { xl: "38px", lg: "36px", md: "34px", sm: "30px", xs: "25px" },
  lineHeight: { xl: "50px", lg: "40px", mg: "35px", sm: "30px", xs: "30px" },
  textAlign: "center",
  letterSpacing: "1px",
  textTransform: "uppercase",
  color: "#000",
};
