import React from "react";
import { useState, useEffect } from "react";
import { Paper, Grid, Box, Button, Typography } from "@mui/material";
import { useSwipeable } from "react-swipeable";

// import InvernessImg from "../../assets/Images/HomeCarouselImages/Inverness.png";
// import MedhaanshImg from "../../assets/Images/HomeCarouselImages/Medhaansh.png";
// import Aalloa from "../../assets/Images/HomeCarouselImages/AllohaHeroSection.png";

// import LineImg from "../../assets/Images/CommonImg/Line.png";
// import { HiArrowSmRight } from "react-icons/hi";
// import { AiOutlineLine } from "react-icons/ai";
// import { Link } from "react-router-dom";
import { MdArrowForwardIos, MdArrowBackIos } from "react-icons/md";

import "./Style.css";

const CarouselItem = ({ children, width }) => {
  return (
    <div
      className="carousel-item"
      style={{ width: width, position: "relative" }}
    >
      {children}
    </div>
  );
};

const HomeCarousel = ({ children }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [paused, setPaused] = useState(false);

  const updateIndex = (newIndex) => {
    if (newIndex < 0) {
      newIndex = React.Children.count(children) - 1;
    } else if (newIndex >= React.Children.count(children)) {
      newIndex = 0;
    }

    setActiveIndex(newIndex);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!paused) {
        updateIndex(activeIndex + 1);
      }
    }, 10000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  });

  const handlers = useSwipeable({
    rotationAngle: 0,
    onSwipedLeft: () => updateIndex(activeIndex + 1),
    onSwipedRight: () => updateIndex(activeIndex - 1),
  });

  return (
    <div
      {...handlers}
      rotationAngle={360}
      className="HomeCarousel"
      style={{
        position: "relative",
        overflow: "hidden",
        transitionDelay: "1s",
        transitionDuration: "1s",
      }}
      onMouseEnter={() => setPaused(true)}
      onMouseLeave={() => setPaused(false)}
    >
      <div
        className="inner"
        style={{ transform: `translateX(-${activeIndex * 100}%)` }}
      >
        {React.Children.map(children, (child, index) => {
          return React.cloneElement(child, { width: "100%" });
        })}
      </div>
      <div className="indicators" style={{ height: "25px", bgcolor: "red" }}>
        <div className="HeroSidebarButtonWrapOne">
          <button
            className="HeroSidebarButtonOne"
            onClick={() => {
              updateIndex(activeIndex - 1);
            }}
          >
            <MdArrowBackIos style={{ color: "gray" }} size={50} />
          </button>
        </div>
        {React.Children.map(children, (child, index) => {
          return (
            <button
              style={{
                position: "absolute",
                top: "88%",
                left: "50%",
                transform: `translate(-50%,-50%)`,
                right: "auto",
                background: "transparent",
                width: "90%",
                maxWidth: "1200px",
                border: "none",
                opacity: 0.4,
              }}
            >
              {activeIndex === 0 ? (
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    mx: "auto",
                    maxHeight: "200px",
                    onFocus: {
                      outline: "none",
                      bgcolor: "transparent",
                    },
                  }}
                >
                  <Grid container>
                    <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                      <Button
                        sx={{
                          height: "100%",
                          width: "75%",
                          mx: "auto",
                          borderTop: "3px solid #FFFFFF",
                          pt: 1,
                          px: "auto",
                          textAlign: "center",
                          custom: "Pointer",
                          borderRadius: "0px",
                          "&:hover": {
                            backgroundColor: "transparent",
                          },
                          "&:focus": {
                            backgroundColor: "transparent",
                          },
                        }}
                        onClick={() => {
                          updateIndex(0);
                        }}
                      >
                        <Typography sx={CarouselActiveTextTwo}>
                          Aalloa Hills
                        </Typography>
                      </Button>
                    </Grid>
                    <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                      <Button
                        sx={{
                          height: "100%",
                          width: "75%",
                          mx: "auto",
                          borderTop: "3px solid rgba(255, 255, 255, 0.3)",
                          pt: 1,
                          custom: "Pointer",
                          borderRadius: "0px",
                          "&:hover": {
                            backgroundColor: "transparent",
                          },
                          "&:focus": {
                            backgroundColor: "transparent",
                          },
                        }}
                        onClick={() => {
                          updateIndex(1);
                        }}
                      >
                        <Typography sx={CarouselUnactiveTextTwo}>
                          Medhaansh
                        </Typography>
                      </Button>
                    </Grid>

                    <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                      <Button
                        sx={{
                          height: "100%",
                          width: "75%",
                          mx: "auto",
                          borderTop: "3px solid rgba(255, 255, 255, 0.3)",
                          pt: 1,
                          textAlign: "center",
                          borderRadius: "0px",
                          custom: "Pointer",
                          "&:hover": {
                            backgroundColor: "transparent",
                          },
                          "&:focus": {
                            backgroundColor: "transparent",
                          },
                        }}
                        onClick={() => {
                          updateIndex(2);
                        }}
                      >
                        <Typography sx={CarouselUnactiveTextTwo}>
                          Inverness A & B
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              ) : null}
              {activeIndex === 1 ? (
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    maxHeight: "200px",
                  }}
                >
                  <Grid container>
                    <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                      <Button
                        sx={{
                          height: "100%",
                          width: "75%",
                          mx: "auto",
                          borderTop: "3px solid rgba(255, 255, 255, 0.3)",
                          pt: 1,
                          px: "auto",
                          custom: "Pointer",
                          borderRadius: "0px",
                          "&:hover": {
                            backgroundColor: "transparent",
                          },
                          "&:focus": {
                            backgroundColor: "transparent",
                          },
                        }}
                        onClick={() => {
                          updateIndex(0);
                        }}
                      >
                        <Typography sx={CarouselUnactiveTextTwo}>
                          Aalloa Hills
                        </Typography>
                      </Button>
                    </Grid>
                    <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                      <Button
                        sx={{
                          height: "100%",
                          width: "75%",
                          mx: "auto",
                          borderTop: "3px solid #ffffff",
                          pt: 1,
                          borderRadius: "0px",
                          textAlign: "center",
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: "transparent",
                          },
                          "&:focus": {
                            backgroundColor: "transparent",
                          },
                        }}
                        onClick={() => {
                          updateIndex(1);
                        }}
                      >
                        {/* <Typography sx={CarouselActiveTextOne}>02</Typography> */}
                        <Typography sx={CarouselActiveTextTwo}>
                          Medhaansh
                        </Typography>
                      </Button>
                    </Grid>

                    <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                      <Button
                        sx={{
                          height: "100%",
                          width: "75%",
                          mx: "auto",
                          borderRadius: "0px",
                          borderTop: "3px solid rgba(255, 255, 255, 0.3)",
                          pt: 1,
                          textAlign: "center",

                          cusrsom: "Pointer",
                          "&:hover": {
                            backgroundColor: "transparent",
                          },
                          "&:focus": {
                            backgroundColor: "transparent",
                          },
                        }}
                        onClick={() => {
                          updateIndex(2);
                        }}
                      >
                        <Typography sx={CarouselUnactiveTextTwo}>
                          Inverness A & B
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              ) : null}
              {activeIndex === 2 ? (
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    maxHeight: "200px",
                  }}
                >
                  <Grid container>
                    <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                      <Button
                        sx={{
                          height: "100%",
                          width: "75%",
                          mx: "auto",
                          borderRadius: "0px",
                          borderTop: "3px solid rgba(255, 255, 255, 0.3)",
                          pt: 1,
                          px: "auto",
                          custom: "Pointer",
                          "&:hover": {
                            backgroundColor: "transparent",
                          },
                          "&:focus": {
                            backgroundColor: "transparent",
                          },
                        }}
                        onClick={() => {
                          updateIndex(0);
                        }}
                      >
                        {/* <Typography sx={CarouselUnactiveTextOne}>01</Typography> */}
                        <Typography sx={CarouselUnactiveTextTwo}>
                          Aalloa Hills
                        </Typography>
                      </Button>
                    </Grid>
                    <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                      <Button
                        sx={{
                          height: "100%",
                          width: "75%",
                          mx: "auto",
                          borderTop: "3px solid rgba(255, 255, 255, 0.3)",
                          borderRadius: "0px",
                          pt: 1,
                          textAlign: "center",
                          pl: 1,
                          custom: "Pointer",
                          "&:hover": {
                            backgroundColor: "transparent",
                          },
                          "&:focus": {
                            backgroundColor: "transparent",
                          },
                        }}
                        onClick={() => {
                          updateIndex(1);
                        }}
                      >
                        <Typography sx={CarouselUnactiveTextTwo}>
                          Medhaansh
                        </Typography>
                      </Button>
                    </Grid>

                    <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
                      <Button
                        sx={{
                          height: "100%",
                          width: "75%",
                          mx: "auto",
                          borderTop: "3px solid #FFFFFF",
                          borderRadius: "0px",
                          pt: 1,
                          textAlign: "center",
                          custom: "Pointer",
                          "&:hover": {
                            backgroundColor: "transparent",
                          },
                          "&:focus": {
                            backgroundColor: "transparent",
                          },
                        }}
                        onClick={() => {
                          updateIndex(2);
                        }}
                      >
                        <Typography sx={CarouselActiveTextTwo}>
                          Inverness A & B
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              ) : null}
            </button>
          );
        })}
        <div className="HeroSidebarButtonWrapTwo">
          <button
            className="HeroSidebarButtonTwo"
            onClick={() => {
              updateIndex(activeIndex + 1);
            }}
          >
            <MdArrowForwardIos style={{ color: "gray" }} size={50} />
          </button>
        </div>
      </div>
    </div>
  );
};

const HeroSection = () => {
  return (
    <Paper
      elevation={0}
      sx={{ overFlowX: "hidden", width: "100vw", maxWidth: "100vw" }}
    >
      <Grid
        container
        sx={{ overFlowX: "hidden", width: "100vw", maxWidth: "100vw" }}
      >
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{ overFlowX: "hidden", width: "100vw", maxWidth: "100vw" }}
        >
          <HomeCarousel rotationAngle={360}>
            <CarouselItem style={{ position: "relative" }}>
              <div className="CaroDivThree"></div>
              <Box sx={InformationBoxThree}>
                <Typography sx={TextTwoStyle}>Inverness</Typography>
                <Typography sx={TextTwoStyleChild}>@Aalloa Hills</Typography>
                <Grid
                  container
                  sx={{ width: "250px", ml: "auto", mr: 0, mt: 2.5 }}
                >
                  <Grid item xl={6}>
                    <a href="/allohaHills" style={{ textDecoration: "none" }}>
                      <Typography sx={TextOneStyle}>= View Project</Typography>
                    </a>
                  </Grid>
                  <Grid item xl={6}>
                    <a href="/contactus" style={{ textDecoration: "none" }}>
                      <Typography sx={TextOneStyle}>= Call Now</Typography>
                    </a>
                  </Grid>
                </Grid>
              </Box>
            </CarouselItem>
            <CarouselItem>
              <div className="CaroDivTwo"></div>
              <Box sx={InformationTwo}>
                {/* <Typography sx={TextOneStyle}>SURF</Typography> */}

                <Typography sx={TextTwoStyle}>Medhaansh</Typography>
                <Typography sx={TextThreeStyle}>@Shela Extension</Typography>
                <Grid
                  container
                  sx={{ width: "250px", ml: "auto", mr: 0, mt: 2.5 }}
                >
                  <Grid item xl={6}>
                    <a href="/medhaansh" style={{ textDecoration: "none" }}>
                      <Typography sx={TextOneStyle}>= View Project</Typography>
                    </a>
                  </Grid>
                  <Grid item xl={6}>
                    <a href="/contactus" style={{ textDecoration: "none" }}>
                      <Typography sx={TextOneStyle}>= Call Now</Typography>
                    </a>
                  </Grid>
                </Grid>

                {/* <Link to="/medhaansh">
                  <Typography sx={ArrowRightIcons}>
                    <HiArrowSmRight />
                  </Typography>
                </Link> */}
              </Box>
            </CarouselItem>

            <CarouselItem>
              <div className="CaroDivOne"></div>
              <Box sx={InformationBoxOne}>
                {/* <Typography sx={TextOneStyle}>SURF</Typography> */}
                <Typography sx={TextTwoStyle}>inverness</Typography>
                <Typography sx={TextThreeStyle}>@Chekhla</Typography>
                {/* <Typography sx={TextThreeStyle}>Living With Nature</Typography> */}
                <Grid
                  container
                  sx={{ width: "250px", ml: "auto", mr: 0, mt: 2.5 }}
                >
                  <Grid item xl={6}>
                    <a href="/inverness" style={{ textDecoration: "none" }}>
                      <Typography sx={TextOneStyle}>= View Project</Typography>
                    </a>
                  </Grid>
                  <Grid item xl={6}>
                    <a href="/contactus" style={{ textDecoration: "none" }}>
                      <Typography sx={TextOneStyle}>= Call Now</Typography>
                    </a>
                  </Grid>
                </Grid>

                {/* <Link to="/allohaHills">
                  <Typography sx={ArrowRightIcons}>
                    <HiArrowSmRight />
                  </Typography>
                </Link> */}
              </Box>
            </CarouselItem>
          </HomeCarousel>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default HeroSection;

const InformationBoxOne = {
  position: "absolute",
  top: "110%",
  left: "auto",
  right: "2%",
  transform: "translate(-0%,-50%)",
  width: "100%",
  maxWidth: "400px",
  textAlign: "right",
  height: "100%",
  bgcolor: "transparent",
  opacity: "1",
  zIndex: 5,
};

const InformationTwo = {
  position: "absolute",
  top: "110%",
  left: "auto",
  right: "2%",
  transform: "translate(-0%,-50%)",
  width: "100%",
  maxWidth: "400px",
  textAlign: "right",
  height: "100%",
  bgcolor: "transparent",
  opacity: "1",
  zIndex: 10,
};

const InformationBoxThree = {
  position: "absolute",
  top: "110%",
  left: "auto",
  right: "2%",
  transform: "translate(-0%,-50%)",
  width: "100%",
  maxWidth: "400px",
  textAlign: "right",
  height: "100%",
  textAlign: "center",
  opacity: "1",
  zIndex: 10,
};

const TextOneStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "15px",
  textAlign: "right",
  textTransform: "uppercase",
  color: "#FFFFFF",
  opacity: 0.5,
  color: "rgba(255, 255, 255, 0.7)",
  custom: "Pointer",
  "&:hover": {
    borderTop: "0px",
    color: "#ffffff",
    opacity: "1",
    bgcolor: "transparent",
  },
};

const TextTwoStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "35px",
  lineHeight: "20px",
  textAlign: "right",
  letterSpacing: "2px",
  textTransform: "uppercase",
  color: "#FFFFFF",
  mt: "30px",
};

const TextTwoStyleChild = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "35px",
  textAlign: "right",
  letterSpacing: "2px",
  color: "#FFFFFF",
  mt: "30px",
  opacity: 0.5,
};

const TextThreeStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "12px",
  textAlign: "right",
  letterSpacing: "2px",
  color: "#FFFFFF",
  opacity: 0.5,
  mt: "30px",
};

const CarouselUnactiveTextTwo = {
  fontSamily: "Fira Sans",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "19px",
  color: "rgba(255, 255, 255, 0.3)",
  justifyContent: "bottom",
  mt: 1,
  textAlign: "center",
};

const CarouselActiveTextTwo = {
  fontSamily: "Fira Sans",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "0px",
  color: "#fff",
  justifyContent: "bottom",
  textAlign: "center",
  mt: 1,
};
