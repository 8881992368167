import React, { useEffect } from "react";
import { Stack } from "@mui/system";
import { Paper } from "@mui/material";
import HeroSection from "../../Pages/ContactUsPages/Herosection";
import ContactInfo from "../../Pages/ContactUsPages/ContactInfo";
import StartConversation from "../../Pages/ContactUsPages/StartConversation";
import { useLocation } from "react-router-dom";
import Header from "../../components/Header/HomeHeader";
import { SendEvent } from "../../utils/sendEvent";

const ContactUs = () => {
  let location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (window.location.hash === "#mapId") {
      window.scrollTo(1100, 1100);
      SendEvent("Unispace map view");
    }
  }, []);
  return (
    <Paper elevation={0}>
      <Header />
      <HeroSection />
      <Stack sx={{ width: "100%", maxWidth: "2400px", mx: "auto" }}>
        <ContactInfo />
        <StartConversation />
      </Stack>
    </Paper>
  );
};

export default ContactUs;
