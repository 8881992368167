import React from "react";
import { useState, useEffect, useRef } from "react";
import { Paper, Grid, Box, Button, Typography } from "@mui/material";
import { useSwipeable } from "react-swipeable";
import "./MobileHeroSection.css";
// import InvernessImg from "../../assets/Images/HomeImages/18_Garden_Sectoe_A_new.jpg";
// import MedhaanshImg from "../../assets/Images/HomeCarouselImages/Medhaansh.png";
// import Aalloa from "../../assets/Images/HomeCarouselImages/Inverness.png";
// import LineImg from "../../assets/Images/CommonImg/Line.png";
// import { HiArrowSmRight } from "react-icons/hi";
// import { AiOutlineLine } from "react-icons/ai";
// import { LazyLoadImage } from "react-lazy-load-image-component";
// import audio from "../../assets/Music/newaudio1.mp3";
// import Tooltip from "@mui/material/Tooltip";
// import Explore from "../../components/CarouselNew/explore.png";
// import Musicclip from "../../assets/Music/music.wav";
// import { BrowserNotSupported } from "@mui/icons-material";
// import invernesslogonew from "../../assets/Images/Inverness/invernesslogonew.png";
import invernesslogo from "../../assets/Images/HomeCarouselImages/AlloaWhiteNewLogo.png";
import NextArr from "./next_arr.png";
import PreviousArr from "./previous_arr.png";
import Slider from "react-slick";
import { Link,  useNavigate } from "react-router-dom";
// import Medhanshlogo from "../../assets/Images/Medhaansh/medhaanshNewLogo_1.png";
import Medhanshlogo from "../../assets/Images/Medhaansh/medhanshablogo.png";

// import invernesslogo from "../../assets/Images/AllohaHills/aalloaVisibleLogoPhone.png";
import invernesslogonew from "../../assets/Images/Inverness/invernesslogonew.png";

// import BirdAudio from "../../assets/Music/birdvoice.wav"
// import newaudio from "../../assets/Music/newaudio1.mp3";
import { useInView } from "react-intersection-observer";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className="HeroSidebarButtonWrapTwo">
      <button className="HeroSidebarButtonTwo" onClick={onClick}>
        <img src={PreviousArr} style={{ width: "100%", height: "auto" }} />
      </button>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;

  return (
    <div className="HeroSidebarButtonWrapOne" style={{ zIndex: 5 }}>
      <button
        className="HeroSidebarButtonOne"
        onClick={onClick}
        style={{ textAlign: "center" }}
      >
        <img
          src={NextArr}
          style={{
            width: "100%",
            height: "auto",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        />
      </button>
    </div>
  );
}

// const CarouselItem = ({ children, width }) => {
//   return (
//     <div className="carousel-item" style={{ width: width }}>
//       {children}
//     </div>
//   );
// };

// const HomeCarousel = ({ children }) => {
//   const [activeIndex, setActiveIndex] = useState(0);
//   const [paused, setPaused] = useState(false);

//   const updateIndex = (newIndex) => {
//     if (newIndex < 0) {
//       newIndex = React.Children.count(children) - 1;
//     } else if (newIndex >= React.Children.count(children)) {
//       newIndex = 0;
//     }

//     setActiveIndex(newIndex);
//   };

//   useEffect(() => {
//     const interval = setInterval(() => {
//       if (!paused) {
//         updateIndex(activeIndex + 1);
//       }
//     }, 3000);

//     return () => {
//       if (interval) {
//         clearInterval(interval);
//       }
//     };
//   });

//   const handlers = useSwipeable({
//     onSwipedLeft: () => updateIndex(activeIndex + 1),
//     onSwipedRight: () => updateIndex(activeIndex - 1),
//   });

//   return (
//     <div
//       {...handlers}
//       className="HomeCarousel"
//       style={{ maxWidth: "100%", overflow: "hidden" }}
//       onMouseEnter={() => setPaused(true)}
//       onMouseLeave={() => setPaused(false)}
//     >
//       <div
//         className="inner"
//         style={{ transform: `translateX(-${activeIndex * 100}%)` }}
//       >
//         {React.Children.map(children, (child, index) => {
//           return React.cloneElement(child, { width: "100%" });
//         })}
//       </div>
//       <div className="indicators" style={{ height: "25px" }}>
//         {React.Children.map(children, (child, index) => {
//           return <button style={IndexButtonStyle}></button>;
//         })}
//       </div>
//     </div>
//   );
// };
const pages = ["INVERNESS", "UPCOMING SHELA EXTENSION", "INVERNESS"];
const extPages = ["@Aalloa Hills", "@Shela Extension", "@Chekhla"];
const images = [
  {
    bgImage: "CaroDivThreePhone",
    textone: "Inverness",
    textTwo: "@Aalloa Hills",
    textThree: "living with nature",
    Link: "/allohaHills",
    logo: invernesslogo,
    logoClass: "MobileheroSectionAlloaLogo",
  },
  {
    bgImage: "CaroDivTwoPhone",
    textone: "UPCOMING ",
    textTwo: "@Shela Extension",
    textThree: "Commune living right here",
    Link: "/medhaansh",
    logo: Medhanshlogo,
    logoClass: "MobileheroSectionMedhanshLogo",
  },
  {
    bgImage: "CaroDivOnePhone",
    textone: "Inverness",
    textTwo: "@Chekhla",
    textThree: "Limitless Luxury",
    Link: "/inverness",
    logo: invernesslogonew,
    logoClass: "MobileheroSectionInverLogo",
  },
];

const HeroSection = () => {
  const scrollWin = () => {
    window.scrollTo(0, 20000);
  };

  const [ref, inView] = useInView({
    threshold: 0,
  });
  // const playerRef = useRef();

  // const [trues, setTrues] = useState(false);

  // useEffect(() => {
  //   // if(!ref.current || !playerRef.current)return

  //   console.log(inView);
  //   try {
  //     if (inView) {
  //       playerRef.current.play();
  //     } else {
  //       playerRef.current.pause();
  //     }
  //   } catch (error) {}
  // }, [inView, playerRef.current]);

  const Navigate = useNavigate();
  return (
    <Paper elevation={0} ref={ref}>
      <Grid container>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Slider
            infinite={true}
            autoplay={true}
            slidesToShow={1}
            speed={500}
            swipe={true}
            draggable={false}
            autoplaySpeed={5000}
            swipeToSlide={true}
            nextArrow={<SampleNextArrow />}
            prevArrow={<SamplePrevArrow />}
          >
            {images.map((step, index) => (
              <>
                <Box sx={CrouselBoxStyleOne}>
                  <Link to={step.Link}>
                    <div className={step.bgImage}>
                      <img
                        src={step.logo}
                        alt={step.textTwo}
                        className={step.logoClass}
                      />
                    </div>
                  </Link>

                  {/* <Grid
                    container
                    sx={{
                      // width: "260px",
                      // mt: 2.5,

                      marginTop: "-50px",
                    }}
                  >
                    <Grid item>
                      <Typography sx={TextOneStyle1}>
                        <img
                          src={Explore}
                          alt="exploreImg"
                          style={{
                            height: "20px",
                            width: "20px",
                            // marginRight: "auto",
                            marginLeft: "auto",
                            opacity: "0.5",
                          }}
                        />
                        {"    "}
                        Explore Project
                      </Typography>
                    </Grid>
                  </Grid> */}

                  <Box
                    onClick={() => {
                      Navigate(step.Link);
                    }}
                    sx={InformationBoxOne}
                  >
                    <Typography sx={TextTwoStyle}>
                      {step.textone} <br />
                      <span
                        style={{
                          textTransform: "uppercase",
                          color: "gray",
                          fontSize: "16px",
                          marginTop: "-5px",
                        }}
                      >
                        <Typography sx={{ fontFamily: "Playfair Display" }}>
                          {step.textTwo}
                        </Typography>
                      </span>
                      <Typography sx={{ fontFamily: "Playfair Display" }}>
                        {step.textThree}
                      </Typography>
                    </Typography>
                    {/* <audio
                      ref={playerRef}
                      loop={true}
                      src={newaudio}
                      onload={newaudio}
                        id="audio"
                        controls="controls"
                        preload="auto"
                        autoplay="autoplay" playsInline
                      // controls
                    ></audio> */}
                  </Box>
                </Box>
              </>
            ))}
          </Slider>
        </Grid>
      </Grid>
      {/* <Grid container>
        <Tooltip title="Play/Pause Music" placement="top-start">
          <audio
            src={audio}
            controls
            loop
            style={{ opacity: 0.5, marginLeft: "10px", marginRight: "auto" }}
          ></audio>
        </Tooltip>
      </Grid> */}
    </Paper>
  );
};

const CustomPagination = (props) => {
  return (
    <button style={{ width: "100%" }} onClick={props.onClick}>
      <span style={{ fontSize: "18px", fontFamily: "cursive" }}>
        {pages[props.data]}
      </span>
      <br />
      <span style={{ fontSize: "12px" }}>{extPages[props.data]}</span>
    </button>
  );
};

export default HeroSection;

const CrouselBoxStyleOne = {
  width: "100%",
  height: "100%",
};

const InformationBoxOne = {
  width: "100%",
  textAlign: "right",
  marginTop: "50px",
  height: "100px",
  bgcolor: "transparent",
  opacity: "1",
  // marginTop:'10px',
};

const TextTwoStyle = {
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: { xl: "80px", lg: "60px", md: "30px", sm: "20px", xs: "20px" },
  lineHeight: "40px",
  textAlign: "center",
  letterSpacing: {
    xl: "7.33333px",
    lg: "7.33333px",
    md: "7.33333px",
    sm: "3px",
    xs: "3px",
  },
  textTransform: "uppercase",
  color: "#000",
  mt: "5px",
};
const TextOneStyle2 = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  zIndex: 10,
  fontWeight: 400,
  fontSize: "15px",
  textAlign: "right",
  textTransform: "uppercase",
  color: "#FFFFFF",
  opacity: 0.8,
  color: "rgba(255, 255, 255, 0.7)",
  custom: "Pointer",
  "&:hover": {
    borderTop: "0px",
    color: "#ffffff",
    bgcolor: "transparent",
  },
};
const TextOneStyle1 = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  zIndex: 10,
  fontWeight: 600,
  fontSize: "12px",
  textAlign: "right",
  textTransform: "uppercase",
  color: "#FFFFFF",
  opacity: 0.8,
  color: "rgba(255, 255, 255, 0.7)",
  custom: "Pointer",
  textAlign: "center",
  display: "flex",
  width: "8.75%",
  marginLeft: "auto",
  marginRight: "auto",
  position: "absolute",
  bottom: "24%",
  // zIndex: 1000,
  "&:hover": {
    borderTop: "0px",
    color: "#ffffff",
    bgcolor: "transparent",
  },
};

const TextThreeStyle = {
  fontFamily: "cursive",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: { xl: "22px", lg: "20px", md: "18px", sm: "16px", xs: "14px" },
  lineHeight: {
    xl: "12px",
    lg: "12px",
    md: "10px",
    sm: "3px",
    xs: "2px",
  },
  textAlign: "center",
  letterSpacing: {
    xl: "7.33px",
    lg: "7.33px",
    md: "5px",
    sm: "3px",
    xs: "0px",
  },
  textTransform: "uppercase",
  color: "#000",
  opacity: 0.5,
  mt: "5px",
};

const TextFourStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  textAlign: "Justify",
  color: "#000",
  opacity: 0.8,
  mx: "auto",
  width: "90%",
  wordWrap: "break-word",

  display: "flex",
  whiteSpace: "pre-wrap",
  mt: "30px",
  animation: "slide-up 1.5s ease-in-out",
  "@keyframes slide-up": {
    "0%": {
      opacity: 0,
      lineHeight: "40px",
    },
    "100%": {
      opacity: 0.8,
      lineHeight: "30px",
    },
  },
};

const ArrowRightIcons = {
  fontSize: "40px",
  color: "#FFFFFF",
  fontWeight: "bold",
  mt: "10px",
};

const IndexButtonStyle = {
  width: "100%",
  maxWidth: "800px",
  position: "absolute",
  bottom: "0%",
  transform: "translateY(-50%)",
  right: "2%",
  background: "transparent",
  height: "auto",
  border: "none",
};

const CarouselUnactiveTextOne = {
  fontSamily: "Fira Sans",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "24px",
  lineHeight: "29px",
  textTransform: "uppercase",
  color: "rgba(255, 255, 255, 0.3)",
};

const CarouselActiveTextOne = {
  fontSamily: "Fira Sans",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "24px",
  lineHeight: "29px",
  textTransform: "uppercase",
  color: "#fff",
};

const CarouselUnactiveTextTwo = {
  fontSamily: "Fira Sans",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "19px",
  color: "rgba(255, 255, 255, 0.3)",
  justifyContent: "bottom",
  mt: 1,
};

const CarouselActiveTextTwo = {
  fontSamily: "Fira Sans",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "19px",
  color: "#fff",
  justifyContent: "bottom",
  mt: 1,
};
