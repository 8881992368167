import React, { useEffect } from "react";
import { Paper, Grid, Typography, Box } from "@mui/material";
import AnimateFromDown from "../../components/AnimateFromDown";
import Carousel, { CarouselItem } from "../../components/carousel/Carousel";
import { ImQuotesRight } from "react-icons/im";
import { SendEvent } from "../../utils/sendEvent";
const WhatOurClientSays = () => {
  useEffect(() => {
    SendEvent("What our  clients says");
  }, []);
  return (
    <Paper elevation={0}>
      <Grid container>
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{ bgcolor: "#ffffff", mx: "auto", width: "100%" }}
        >
          <Box sx={{ height: "200px", mt: 10, fontFamily: "Playfair Display" }}>
            <Typography sx={OurText}>WHAT OUR</Typography>
            <AnimateFromDown>
              <Typography sx={ProjectTextStyle}>CLIENTS SAY</Typography>
              {/* <Typography sx={ClientTextTitle}>Quality Speaks</Typography> */}
            </AnimateFromDown>
          </Box>

          {/* <Box> */}
          <Carousel>
            <CItem
              text={
                "Choosing a property is a lifelong decision. While people say what matters is “location location location”, I wish to add “builder builder builder”. It’s been a delight dealing with the Bagadia family when we invested in Inverness, and thereafter always. It’s been a great investment. We respect the ethical and customer friendly approach. Wishing Siddharthbhai all the very best always."
              }
              name="Prashant Sharma"
              pos={" Chief Technical Officer , Zydus Lifesciences Ltd"}
            />
            <CItem
              text={
                "At  Sidharth’s Unispace  his transparent and hands-on approach is personified into Trust  . We were looking  to buy Few plots in Medhansh but what we additionally got  from him  was a complete Peace  of Mind ."
              }
              name="Amodbhai Joshi"
            />
            <CItem
              text={
                "My experience of Unispace and Inverness-Siddharth bhai is a very committed and most sincere person. He works hard for his projects, plan excellently and then execute   nicely. I am very Happy with Inverness,  a very well planned and executed project."
              }
              name=" Mukesh m shah CA"
            />
          </Carousel>
          {/* </Box> */}
        </Grid>
      </Grid>
    </Paper>
  );
};

const CItem = ({ text, name, pos }) => {
  return (
    <CarouselItem>
      <Box
        sx={{
          height: "100%",
          minHeight: "200px",
          width: {
            xl: "90%",
            lg: "90%",
            md: "90%",
            sm: "90%",
            xs: "90%",
          },
          mx: "auto",
        }}
      >
        <h1 className="CLientSayText sequence fadeInBottom">
          {/* <img
            srcsrc={next}
            alt={next}
            size={30}
            color={"gray"}
            style={{ marginRight: "10px" }}
          /> */}
          {/* <img src={PreviousArr} style={{ width: "100%", height: "auto" ,backgroundColor:'red'}} /> */}
          {text}
          <ImQuotesRight
            size={30}
            color={"lightgray"}
            style={{ marginRight: "10px", color: "red" }}
          />
        </h1>
        <Typography sx={ClientTextBottomText}>{name}</Typography>
        {pos && <Typography sx={ClientTextBottomText}>{pos}</Typography>}
      </Box>
      {/* </Box> */}
    </CarouselItem>
  );
};

export default WhatOurClientSays;

const OurText = {
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: { xl: "42px", lg: "42px", md: "40px", sm: "35px", xs: "35px" },
  lineHeight: "54px",
  textTransform: "capitalize",
  color: "rgba(0, 0, 0, 0.4)",
};

const ProjectTextStyle = {
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 900,
  fontSize: { xl: "42px", lg: "42px", md: "40px", sm: "35px", xs: "35px" },
  lineHeight: "54px",
  textTransform: "capitalize",
  color: "#000000",
};

// const ClientTextTitle = {
//   fontFamily: "Futura Medium BT",
//   fontStyle: "normal",
//   fontWeight: 400,
//   fontSize: { xl: "42px", lg: "42px", md: "40px", sm: "24px", xs: "24px" },
//   lineHeight: { xl: "50px", lg: "50px", md: "50px", sm: "50px", xs: "50px" },
//   textAlign: "center",
//   color: "#222222",
//   mt: 5,
//   mb: 2,
// };

// const ClientText = {
//   fontFamily: "Futura Medium BT",
//   fontStyle: "normal",
//   fontWeight: 400,
//   fontSize: { xl: "17px", lg: "17px", md: "16px", sm: "16px", xs: "14px" },
//   lineHeight: "22px",
//   textAlign: "center",
//   color: "#808186",
//   mt: 2,
//   mb: 2,
//   whiteSpace: "pre-wrap",
//   wordWrap: "break-word",
// };

const ClientTextBottomText = {
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "26px",
  textAlign: "center",
  textTransform: "uppercase",
  color: "#808186",
};
