import React from "react";
import imginvernes4 from "../assets/Images/HomeImages/Inverness4.png";
import Header from "../components/Header/HomeHeader";
import WhiteLogo from "../components/Header/logowhite.png";

const Project2 = () => {
  return (
    <div>
      <Header />
      <div className="PertcularPPageTwo" style={{ position: "relative" }}>
        <img
          src={imginvernes4}
          alt="backGroundImg"
          style={{
            height: "100%",
            width: "100%",
            position: "relative",
          }}
        />
        <img
          src={WhiteLogo}
          style={{
            width: "200px",
            left: "82%",
            bottom: "10%",
            position: "absolute",
            objectFit: "cover",
          }}
        />
      </div>
    </div>
  );
};

export default Project2;
