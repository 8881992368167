import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Home from "./views/Home/Home";
import AboutUs from "./views/AboutUs/AboutUs";
import ContactUs from "./views/ContactUs/ContactUs";
import Footer from "./components/Footer/Footer";
import Inverness from "./views/InvernesssA&B/Inverness";
import Medhaansh from "./views/Medhaansh/Medhaansh";
import AllohaHills from "./views/AllohaHills/AllohaHills";
import { useEffect } from "react";
import Projects from "./views/Projects/Projects";
import Project1 from "./views/Project1";
import Project2 from "./views/Project2";
import Project3 from "./views/Project3";

import { useState } from "react";
import WhatsappImage from "./assets/Images/CommonImg/Whatsapp.png";
import Logo from "./assets/Images/finallogo.jpg";
import { useLocation } from "react-router-dom";
import Bowser from "bowser";
import { SendEvent } from "./utils/sendEvent";

function App() {
  const [isOpen, setIsOpen] = useState(false);
  const [WhatsappView, setWhatsappView] = useState(false);
  const location = useLocation();

  window.addEventListener("scroll", () => {
    if (window.scrollY > 100) {
      setWhatsappView(true);
    } else setWhatsappView(false);
  });

  useEffect(() => {
    setIsOpen(false);
    setTimeout(() => {
      setIsOpen(true);
    }, 2000);
  }, [location.pathname]);

  const [MainBox, setMainBox] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setMainBox(false);
    }, 7000);
  }, []);

  // this is form bold code
  // https://formbold.com/s/9XL59
  // https://formbold.com/s/9XL59

  return (
    <div className="App">
      {isOpen ? (
        <React.Fragment>
          <Routes>
            <Route path="/" element={<Home MainBox={MainBox} />} />
          </Routes>
          <Routes>
            <Route path="/aboutus" element={<AboutUs />} />
            <Route path="/contactus" element={<ContactUs />} />
            <Route path="/inverness" element={<Inverness />} />
            <Route path="/medhaansh" element={<Medhaansh />} />
            <Route path="/allohaHills" element={<AllohaHills />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/inverness3" element={<Project1 />} />
            <Route path="/inverness4" element={<Project2 />} />
            <Route path="/project3" element={<Project3 />} />
          </Routes>
          <Footer />

          <div
            className="whatsappicon"
            style={{
              marginLeft: WhatsappView ? "0px" : "-100px",
              transition: "all 1s",
            }}
          >
            <a
              href="https://wa.me/+919898048844"
              target="_blank"
              onClick={() => {
                SendEvent("Whatsapp url visit");
              }}
            >
              <img src={WhatsappImage} alt="Whatsapp" width="100%" />
            </a>
          </div>
        </React.Fragment>
      ) : (
        <div
          style={{
            height: "100vh",
            width: "100%",
            display: "grid",
            placeItems: "center",
          }}
        >
          <img
            src={Logo}
            className="loaderImg"
            alt="Logoimg"
            width="450px"
            height="auto"
          />
        </div>
      )}
    </div>
  );
}

export default App;
