import React from "react";
import { Paper, Grid, Box, Typography } from "@mui/material";
import HeroSectionImage from "../../assets/Images/Projects/Herosection.png";

const HeroSection = () => {
  return (
    <Paper
      elevation={0}
      sx={{
        width: "100vw",
        maxWidth: "100vw",
        overflow: "hidden",
        borderRadius: "0px",
      }}
    >
      <Grid container>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Box sx={boxStyle} className="img-zoom-ani">
            <Box sx={TextBox}>
              <Typography sx={titleStyle}>Projects</Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default HeroSection;

const boxStyle = {
  width: "100%",
  height: "530px",
  backgroundImage: `url(${HeroSectionImage})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  position: "relative",
};

const titleStyle = {
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: { xl: "60px", lg: "50px", md: "40px", sm: "40px", xs: "40px" },
  lineHeight: { xl: "86px", lg: "75px", md: "60px", sm: "50px", xs: "40px" },
  textTransform: "uppercase",
  color: "#FFFFFF",
};

const TextBox = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translateY(-50%) translateX(-50%)",
};
