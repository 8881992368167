import React from "react";
import { Paper, Grid, Box, Typography } from "@mui/material";
import HeroSectionImage from "../../assets/Images/AboutUsImages/thecompany.png";
import "./Css/aboutstyle.css";

const HeroSection = () => {
  return (
    <Paper
      elevation={0}
      sx={{ maxHeight: "100vh", overflow: "hidden", borderRadius: "0px" }}
    >
      <Grid container>
        <Box sx={boxStyle} className="img-zoom-ani">
          <Box sx={TextBox}>
            <Typography sx={titleStyle}>The Company Story</Typography>
          </Box>
        </Box>
      </Grid>
    </Paper>
  );
};

export default HeroSection;

const boxStyle = {
  width: "100%",
  height: "400px",
  backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(${HeroSectionImage})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  position: "relative",
};

const titleStyle = {
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: { xl: "60px", lg: "50px", md: "40px", sm: "35px", xs: "30px" },
  lineHeight: { xl: "86px", lg: "75px", md: "60px", sm: "50px", xs: "40px" },
  textTransform: "uppercase",
  color: "#FFFFFF",
};

const TextBox = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  marginLeft: "auto",
  marginRight: "auto",
};
