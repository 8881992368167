import React, { useState } from "react";
import { Paper, Grid, Typography, Box, Stack } from "@mui/material";
import AnimateFromDown from "../../components/AnimateFromDown";
import { ImQuotesLeft, ImQuotesRight } from "react-icons/im";
import "../AllohaHillsPages/GalaxyGridView/Style.css"
const Ourimagination = () => {
  const [ViewOne, setViewOne] = useState(true);
  const [ViewTwo, setViewTwo] = useState(false);
  const [ViewThree, setViewThree] = useState(false);
  const [ViewFour, setViewFour] = useState(false);
  const [ViewFive, setViewFive] = useState(false);

  const ViewOneArch = () => {
    setViewOne(true);
    setViewTwo(false);
    setViewThree(false);
    setViewFour(false);
    setViewFive(false);
  };

  const ViewTwoArch = () => {
    setViewOne(false);
    setViewTwo(true);
    setViewThree(false);
    setViewFour(false);
    setViewFive(false);
  };

  const ViewThreeArch = () => {
    setViewOne(false);
    setViewTwo(false);
    setViewThree(true);
    setViewFour(false);
    setViewFive(false);
  };

  const ViewFourArch = () => {
    setViewOne(false);
    setViewTwo(false);
    setViewThree(false);
    setViewFour(true);
    setViewFive(false);
  };
  const ViewFiveArch = () => {
    setViewOne(false);
    setViewTwo(false);
    setViewThree(false);
    setViewFour(false);
    setViewFive(true);
  };
  return (
    <div>
      <Paper
        sx={{
          bgcolor: "transparent",
          height: "100%",
          paddingTop: "50px",
          paddingBottom: "50px",
        }}
        elevation={0}
      >
        <Grid container mt={10}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Typography sx={MainTitleStyle}>
              {/* <span style={{ color: "rgba(0, 0, 0, 0.4)", fontWeight: 500 }}>
                Our{" "}
              </span>{" "} */}
              Craftsman's View
            </Typography>
          </Grid>
          {/* <Grid
          item
          xl={5}
          lg={5}
          md={5}
          sm={11}
          xs={11}
          sx={{ marginTop: "60px", mx: "auto" }}
        >
          <Grid container>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <Box sx={{ width: "100px", height: "100%", mx: "auto" }}>
                <img src={SavvyLogo} alt="SavvyLogoimg" width="100%" />
              </Box>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <Box sx={{ width: "220px", height: "100%", mx: "auto" }}>
                <img src={MLogo} alt="SavvyLogoimg" width="100%" />
              </Box>
            </Grid>
          </Grid>
        </Grid> */}
        </Grid>
        <Grid
          container
          sx={{
            height: "100%",
            minHeight: "350px",
            mt: {
              xl: "100px",
              lg: "100px",
              md: "100px",
              sm: "75px",
              xs: "75px",
            },
          }}
        >
          <Grid
            item
            xl={3}
            lg={3}
            md={3}
            sm={12}
            xs={12}
            sx={
              {
                // textAlign: { xl:"left" , lg : "left" , md:"left" , xs:"center",sm:"center" },
                // placeItems: { xl:"start" , lg : "start" , md:"start" , xs:"center",sm:"center" },
              }
            }
          >
            <Stack
              sx={{
                height: "100%",
                minHeight: "350px",
                display: "grid",

                borderRight: {
                  xl: "1px solid lightgray",
                  lg: "1px solid lightgray",
                  md: "1px solid lightgray",
                  sm: "none",
                  xs: "none",
                },
                alignItems: {
                  xl: "left",
                  lg: "left",
                  md: "left",
                  xs: "center",
                  sm: "center",
                },
                textAlign: {
                  xl: "left",
                  lg: "left",
                  md: "left",
                  xs: "center",
                  sm: "center",
                },
                fontFamily: "Playfair Display",
              }}
            >
              {ViewOne ? (
                <button
                  className="ActiveButton"
                  onClick={ViewOneArch}
                  style={{ fontFamily: "Playfair Display" }}
                >
                  Aniket Bhagwat
                  <br />{" "}
                  <span style={{ fontSize: "14px", color: "#4F4F4F" }}>
                    Landscape India
                  </span>
                </button>
              ) : (
                <button
                  className="UnActiveText"
                  onClick={ViewOneArch}
                  style={{ fontFamily: "Playfair Display" }}
                >
                  Aniket Bhagwat
                  <br />{" "}
                  <span style={{ fontSize: "14px", color: "#4F4F4F" }}>
                    Landscape India
                  </span>
                </button>
              )}
              {ViewTwo ? (
                <button
                  className="ActiveButton"
                  onClick={ViewTwoArch}
                  style={{ fontFamily: "Playfair Display" }}
                >
                  Hiren Patel
                  <br />{" "}
                  <span style={{ fontSize: "14px", color: "#4F4F4F" }}>
                    HPA
                  </span>
                </button>
              ) : (
                <button
                  className="UnActiveText"
                  onClick={ViewTwoArch}
                  style={{ fontFamily: "Playfair Display" }}
                >
                  Hiren Patel
                  <br />{" "}
                  <span style={{ fontSize: "14px", color: "#4F4F4F" }}>
                    HPA
                  </span>
                </button>
              )}

              {ViewThree ? (
                <button
                  className="ActiveButton"
                  onClick={ViewThreeArch}
                  style={{ fontFamily: "Playfair Display" }}
                >
                  Sonke Hoof
                  <br />{" "}
                  <span style={{ fontSize: "14px", color: "#4F4F4F" }}>
                    Studio Sangath
                  </span>
                </button>
              ) : (
                <button
                  className="UnActiveText"
                  onClick={ViewThreeArch}
                  style={{ fontFamily: "Playfair Display" }}
                >
                  Sonke Hoof
                  <br />{" "}
                  <span style={{ fontSize: "14px", color: "#4F4F4F" }}>
                    Studio Sangath
                  </span>
                </button>
              )}

              {ViewFour ? (
                <button
                  className="ActiveButton"
                  onClick={ViewFourArch}
                  style={{ fontFamily: "Playfair Display" }}
                >
                  Dean D' cruz
                  <br />{" "}
                  <span style={{ fontSize: "14px", color: "#4F4F4F" }}>
                    Mozaic
                  </span>
                </button>
              ) : (
                <button
                  className="UnActiveText"
                  onClick={ViewFourArch}
                  style={{ fontFamily: "Playfair Display" }}
                >
                  Dean D' cruz
                  <br />{" "}
                  <span style={{ fontSize: "14px", color: "#4F4F4F" }}>
                    Mozaic
                  </span>
                </button>
              )}

              {ViewFive ? (
                <button
                  className="ActiveButton"
                  onClick={ViewFiveArch}
                  style={{ fontFamily: "Playfair Display" }}
                >
                  Umesh Shrupali
                  <br />{" "}
                  <span style={{ fontSize: "14px", color: "#4F4F4F" }}>
                    Place Design Studio
                  </span>
                </button>
              ) : (
                <button
                  className="UnActiveText"
                  onClick={ViewFiveArch}
                  style={{ fontFamily: "Playfair Display" }}
                >
                  Umesh Shrupali
                  <br />{" "}
                  <span style={{ fontSize: "14px", color: "#4F4F4F" }}>
                    Place Design Studio
                  </span>
                </button>
              )}
            </Stack>
          </Grid>
          {/* <Grid
          item
          xl={1}
          lg={1}
          md={1}
          sm={0}
          xs={0}
          sx={{ display: "grid", placeItems: "center" }}
        >
          <Box sx={{ height: "300px", width: "2px", bgcolor: "#f5f5f5" }}></Box>
        </Grid> */}
          <Grid item xl={9} lg={9} md={9} sm={12} xs={12}>
            {ViewOne ? (
              <Box
                sx={{
                  width: "80%",
                  mx: "auto",
                  height: "100%",
                  minHeight: "350px",
                }}
              >
                <h1 className="QuotedText">
                  {/* <ImQuotesLeft /> Mr. Shah has been into land compliance since
                last 23 year with his company “Kushal compliance solution pvt
                ltd and has a major client base of diamond showrooms and other
                corporates. Also he runs his own diamond showroom under the
                brand name “Ziara”. He holds a post graduate diploma in business
                management from IIPM. <ImQuotesRight /> */}
                  <ImQuotesLeft /> What is particularly fascinating about
                  Inverness @ Aalloa Hills is its topography. It's a landscape
                  of rolling hills, wilderness, a landscape where birds chirp.
                  It's particularly the best suited weekend retreat development
                  in Gujarat. <ImQuotesRight />
                </h1>
                {/* <p className="QuotedFooter">- Ankur Shah</p> */}
              </Box>
            ) : null}
            {ViewTwo ? (
              <Box
                sx={{
                  width: "80%",
                  mx: "auto",
                  height: "100%",
                  minHeight: "350px",
                }}
              >
                <h1 className="QuotedText">
                  <ImQuotesLeft /> Inverness at aalloa his housing back to slow
                  living a much needed in today’s fast life . It’s a place to be
                  with nature and oneness.{" "}
                  {/* Mr. Khurana is an alumni of Mayo college,
                  Ajmer and also holds a diploma in building sciences from
                  CEPT.He started his construction business with his own firm
                  MSK Engg in 1989, then he also embarked upon pursuing his
                  hospitality business with projects like fortune
                  landmark,fortune SR RD and gift city club, gandhinagar.He also
                  owns & operated following leading dealerships including Volvo
                  construction equipments, potain cranes, polaris atv’s and
                  Indian motorcycles.  */}
                  <ImQuotesRight />
                </h1>
                {/* <p className="QuotedFooter">- Soanke Hoof</p> */}
              </Box>
            ) : null}

            {ViewThree ? (
              <Box
                sx={{
                  width: "80%",
                  mx: "auto",
                  height: "100%",
                  minHeight: "350px",
                }}
              >
                <h1 className="QuotedText">
                  <ImQuotesLeft /> Imagine nature untouched by development,
                  changing its beauty through the seasons… That is inverness and
                  we are very excited to be part of this special project.{" "}
                  {/* Aliqua id fugiat nostrud irure ex duis ea
                  quis id quis ad et. Sunt qui esse pariatur duis deserunt
                  mollit dolore cillum minim tempor enim. Elit aute irure tempor
                  cupidatat incididunt sint deserunt ut voluptate aute id
                  deserunt nisi. */}
                  <ImQuotesRight />
                </h1>
                {/* <p className="QuotedFooter">-  Dean D’ Cruz</p> */}
              </Box>
            ) : null}

            {ViewFour ? (
              <Box
                sx={{
                  width: "80%",
                  mx: "auto",
                  height: "100%",
                  minHeight: "350px",
                }}
              >
                <h1 className="QuotedText">
                  <ImQuotesLeft /> The new Club House is a unique architectural
                  introduction inspired by the articulate landscape at Inverness
                  and a pre-climate change scenario using materials that are
                  both, natural and hi tech in a sensible manner.{" "}
                  {/* Aliqua id fugiat nostrud irure ex duis ea
                  quis id quis ad et. Sunt qui esse pariatur duis deserunt
                  mollit dolore cillum minim tempor enim. Elit aute irure tempor
                  cupidatat incididunt sint deserunt ut voluptate aute id
                  deserunt nisi. */}
                  <ImQuotesRight />
                </h1>
                {/* <p className="QuotedFooter">- Place Design Studio</p> */}
              </Box>
            ) : null}
            {ViewFive ? (
              <Box
                sx={{
                  width: "80%",
                  mx: "auto",
                  height: "100%",
                  minHeight: "350px",
                }}
              >
                <h1 className="QuotedText">
                  <ImQuotesLeft /> The Inverness at Aalloa sets itself apart
                  mainly because of the idyllic undulating topography it enjoys
                  unlike any other routine real estate in Ahmedabad. Involvement
                  of creative professionals established as well as aspiring;
                  working passionately together adds to the list of
                  differentiators.{" "}
                  {/* Mr. Khurana is an alumni of Mayo college,
                  Ajmer and also holds a diploma in building sciences from
                  CEPT.He started his construction business with his own firm
                  MSK Engg in 1989, then he also embarked upon pursuing his
                  hospitality business with projects like fortune
                  landmark,fortune SR RD and gift city club, gandhinagar.He also
                  owns & operated following leading dealerships including Volvo
                  construction equipments, potain cranes, polaris atv’s and
                  Indian motorcycles. nisi. */}
                  <ImQuotesRight />
                </h1>
                {/* <p className="QuotedFooter">-  Dean D’ Cruz</p> */}
              </Box>
            ) : null}
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default Ourimagination;

const MainTitleStyle = {
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: { xl: "36px", lg: "36px", md: "30px", sm: "26px", xs: "26px" },
  lineHeight: "43px",
  color: "#333333",
  textAlign: "center",
  textTransform: "uppercase",
};
