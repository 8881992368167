import React from "react";
import { Paper, Grid, Typography, Box } from "@mui/material";
import AnimateFromDown from "../../components/AnimateFromDown";
// import Carousel, { CarouselItem } from "../../components/carousel/Carousel";
import { ImQuotesLeft, ImQuotesRight } from "react-icons/im";
import { GrNext } from "react-icons/gr";
import { GrPrevious } from "react-icons/gr";

import Slider from "react-slick";
import "./Caroso.css";
// import { Link } from "react-router-dom";

// import inverse1 from "../../assets/Images/Inverness/inverse1.png";
// import inverse3 from "../../assets/Images/Inverness/inverse3.png";

// import Medhaansh from "../../assets/Images/Medhaansh/Medhaansh.png";
// import AllohaIntro from "../../assets/Images/AllohaHills/AllohaIntro.png";
// import NextArr from "./next_arr.png";
// import PreviousArr from "./previous_arr.png";

const images = [
  {
    text: "Choosing a property is a lifelong decision. While people say what matters is “location location location”, I wish to add “builder builder builder”. It’s been a delight dealing with the Bagadia family when we invested in Inverness, and thereafter always. It’s been a great investment. We respect the ethical and customer friendly approach. Wishing Siddharthbhai all the very best always.",
    name: "Prashant Sharma",
    pos: "Chief Technical Officer , Zydus Lifesciences Ltd",
  },
  {
    text: `At Siddharth's Unispace,his transparent and hands-on approach is personified into trust. We were looking to buy a few plots in Medhaansh but what we additionally what we got from him was complete peace of mind.`,
    name: "Amodbhai joshi",
  },
  {
    text: `My experience of Unispace and Inverness-Siddharth bhai is a very committed and most sincere person. He works hard for his projects,plans excellently,and then executes nicely.I am very happy with Inverness,avery well planned and executed project.`,
    name: " Mukesh M Shah CA",
  },
];

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className="HeroSidebarButtonWrapTwo">
      <button className="HeroSidebarButtonTwo" onClick={onClick}>
        <GrNext size={27} onClick={onClick} />
      </button>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div className="HeroSidebarButtonWrapOne" style={{ zIndex: 5 }}>
      <button
        className="HeroSidebarButtonOne"
        onClick={onClick}
        style={{ textAlign: "center" }}
      >
        <GrPrevious size={27} onClick={onClick} />
      </button>
    </div>
  );
}

const CaroClient = () => {
  const settings = {
    dots: true,
    dotsClass: "slick-dotsCaro custom-indicatornewcaro",
    infinite: true,
    speed: 1000,
    slidesToScroll: 1,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <>
      <Paper elevation={0} sx={{ marginTop: "0px" }}>
        <Grid container>
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            sx={{ bgcolor: "#f9f9f9", mx: "auto", width: "100%" }}
          >
            <Box sx={{ height: "200px", mt: 10 }}>
              <Typography sx={OurText}>WHAT OUR</Typography>
              <AnimateFromDown>
                <Typography sx={ProjectTextStyle}>CLIENTS SAY</Typography>
              </AnimateFromDown>
            </Box>

            <div
              style={{
                width: "100%",
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: "80px",
              }}
            >
              <Slider
                dots={true}
                infinite={true}
                speed={500}
                swipe={true}
                autoplaySpeed={5000}
                customPaging={(i) => {
                  return <CustomPagination data={i} />;
                }}
                dotsClass="slick-dotnew custom-indicatornewcaro"
                autoplay={true}
                slidesToShow={1}
                draggable={false}
                nextArrow={<SampleNextArrow />}
                prevArrow={<SamplePrevArrow />}
              >
                {images.map((step) => (
                  <Box
                    sx={{
                      height: "100%",
                      minHeight: "250px",
                      width: {
                        xl: "90%",
                        lg: "90%",
                        md: "90%",
                        sm: "80%",
                        xs: "80%",
                      },
                      maxWidth: {
                        xl: "1400px",
                        lg: "1000px",
                        md: "800px",
                        sm: "450px",
                        xs: "320px",
                      },
                      mx: "auto",
                    }}
                  >
                    <h1
                      style={{
                        lineHeight: "35px",
                      }}
                      className="CLientSayText sequence fadeInBottom"
                    >
                      <ImQuotesLeft
                        size={30}
                        color={"lightgray"}
                        style={{ marginRight: "10px" }}
                      />
                      {step.text}
                      <ImQuotesRight
                        size={30}
                        color={"lightgray"}
                        style={{ marginRight: "10px" }}
                      />
                    </h1>
                    <Typography sx={ClientTextBottomText}>
                      {step.name}
                    </Typography>
                    {step.pos && (
                      <Typography sx={ClientTextBottomText}>
                        {step.pos}
                      </Typography>
                    )}
                  </Box>
                ))}
              </Slider>
            </div>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default CaroClient;

const CustomPagination = (props) => {
  return (
    <button
      style={{ width: "100%", color: "#f4f4f4", border: "none" }}
      onClick={props.onClick}
    >
      <span style={{ display: "none" }}>{props.data}</span>
    </button>
  );
};

const OurText = {
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: { xl: "42px", lg: "42px", md: "40px", sm: "35px", xs: "35px" },
  lineHeight: "54px",
  textTransform: "capitalize",
  color: "rgba(0, 0, 0, 0.4)",
};

const ProjectTextStyle = {
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 900,
  fontSize: { xl: "42px", lg: "42px", md: "40px", sm: "35px", xs: "35px" },
  lineHeight: "54px",
  textTransform: "capitalize",
  color: "#000000",
};

const ClientTextBottomText = {
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "26px",
  textAlign: "center",
  color: "#808186",
};
