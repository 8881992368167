import React from "react";
import { Paper, Grid, Typography, Box } from "@mui/material";

// import MozacImg from "../../assets/Images/AllohaHills/OurArchitecs/Mozac.png";
// import StudioArchitec from "../../assets/Images/AllohaHills/OurArchitecs/StudioSangath.png";
// import PrabhakarImg from "../../assets/Images/AllohaHills/OurArchitecs/Prabhakar.png";
// import HirenPatel from "../../assets/Images/AllohaHills/OurArchitecs/HirenPatel.png";
// import PDSLogo from "../../assets/Images/AllohaHills/OurArchitecs/PDS_logo_20220809 (1).jpg";
// import { LazyLoadImage } from "react-lazy-load-image-component";
// import { Link } from "react-router-dom";
import SavvyLogo from "../../assets/Images/Medhaansh/savvylogo.jpg";
import MLogo from "../../assets/Images/FooterImg/Logo.png";
import "./Style.css";
import AnimateFromDown from "../../components/AnimateFromDown";
const Ourimagination = () => {
  return (
    <Paper sx={{ height: "100%" }} elevation={0}>
      <AnimateFromDown>
        <Grid container mt={10}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Typography sx={MainTitleStyle}>
              <span style={{ color: "rgba(0, 0, 0, 0.4)", fontWeight: 500 }}>
                Our
              </span>{" "}
              Team{" "}
            </Typography>
          </Grid>
          {/* <Grid container>
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={{ mx: "auto", mt: "30px" }}
            >
              <Box
                sx={{
                  width: "85%",
                  maxWidth: "500px",
                  textAlign: "center",
                  mx: "auto",
                }}
              >
                <LazyLoadImage
                  delayTime={2000}
                  src={PrabhakarImg}
                  width="100px"
                  alt="PrabhakarImg"
                />
                <Typography sx={ArchitecNameStyle}>Landscape India</Typography>
                <Typography sx={NameText}>Prabhakar bhagwat</Typography>
                <h1 className="ourArchitecText sequence fadeInBottom">
                  Prabhakar Bhagwat is a landscape architect with M/s. Prabhakar B
                  Bhagwat, a third-generation design studio founded in 1973.
                </h1>
                <Typography sx={BottomText}>www.hirnePatel.com</Typography>
              </Box>
            </Grid>
          </Grid> */}

          <Grid container mt={"10px"}>
            <Grid
              item
              xl={5}
              lg={5}
              md={5}
              sm={11}
              xs={11}
              sx={{ marginTop: "60px", mx: "auto" }}
            >
              <Grid container>
                <Grid
                  item
                  xl={6}
                  lg={6}
                  md={6}
                  sm={6}
                  xs={12}
                  sx={{
                    mb: {
                      xl: "0px",
                      lg: "0px",
                      md: "0px",
                      sm: "25px",
                      xs: "30px",
                    },
                  }}
                >
                  <Box sx={{ width: "100px", height: "100%", mx: "auto" }}>
                    <img src={SavvyLogo} alt="SavvyLogoimg" width="100%" />
                  </Box>
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                  <Box sx={{ width: "220px", height: "100%", mx: "auto" }}>
                    <img src={MLogo} alt="SavvyLogoimg" width="100%" />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              width: "100%",
              mx: "auto",
              mt: "30px ",
            }}
          >
            {/* <Grid
            item
            xl={2.4}
            lg={2.4}
            md={2.4}
            sm={6}
            xs={12}
            sx={{ mx: "auto", mt: 3 }}
          >
            <Box
              sx={{
                width: "85%",
                maxWidth: "600px",
                textAlign: "center",
                mx: "auto",
              }}
            >
              <LazyLoadImage
                delayTime={2000}
                src={PrabhakarImg}
                width="100px"
                alt="PrabhakarImg"
              />
              <Typography sx={ArchitecNameStyle}>Landscape India</Typography>
              <Typography sx={NameText}>Prabhakar bhagwat</Typography>
              <h1 className="ourArchitecText sequence fadeInBottom">
                Prabhakar Bhagwat is a landscape architect with M/s. Prabhakar B
                Bhagwat, a third-generation design studio founded in 1973.
              </h1>
              <Typography sx={BottomText}>www.hirnePatel.com</Typography>
            </Box>
          </Grid> */}
            <Grid
              item
              xl={2.4}
              lg={2.4}
              md={2.4}
              sm={6}
              xs={12}
              sx={{ mx: "auto", mt: 3 }}
            >
              <Box
                sx={{
                  width: "85%",
                  maxWidth: "600px",
                  height: {
                    xl: "350px",
                    lg: "350px",
                    md: "325px",
                    sm: "270px",
                    xs: "270px",
                  },
                  // display: "flex",
                  // flexDirection: "column",
                  // justifyContent: "space-between",
                  textAlign: "center",
                  mx: "auto",
                  // background: "red",
                }}
              >
                {/* <LazyLoadImage
                  delayTime={2000}
                  src={HirenPatel}
                  width="160px"
                  alt="PrabhakarImg"
                /> */}
                <Typography sx={NameText}>JAXAY SHAH</Typography>
                <Typography sx={ArchitecNameStyle}>SAVVY GROUP</Typography>
                <Box
                  sx={{
                    height: {
                      xl: "150px",
                      lg: "150px",
                      md: "240px",
                      sm: "125px",
                      xs: "125px",
                    },
                  }}
                >
                  <h1 className="ourArchitecText sequence fadeInBottom">
                    Jaxay Shah is the visionary director of the Savvy Group,
                    adamant about furnishing the ultimate real estate
                    development and experience.
                  </h1>
                </Box>
                {/* <Typography sx={BottomText}>www.hirnePatel.com</Typography> */}
                {/* <a
                  target="_blank"
                  href="https://www.savvygroup.in/"
                  className="LinkStyleText"
                  style={{ textDecoration: "none", marginTop: "auto" }}
                >
                  www.savvygroup.in
                </a> */}
              </Box>
            </Grid>
            <Grid
              item
              xl={2.4}
              lg={2.4}
              md={2.4}
              sm={6}
              xs={12}
              sx={{ mx: "auto", mt: 3 }}
            >
              <Box
                sx={{
                  width: "85%",
                  maxWidth: "600px",
                  textAlign: "center",
                  mx: "auto",
                  height: {
                    xl: "350px",
                    lg: "350px",
                    md: "325px",
                    sm: "270px",
                    xs: "270px",
                  },
                  marginTop: "auto",
                }}
              >
                {/* <LazyLoadImage
                  delayTime={2000}
                  src={StudioArchitec}
                  width="160px"
                  alt="PrabhakarImg"
                /> */}
                <Typography sx={NameText}>SAMEER SINHA</Typography>
                <Typography sx={ArchitecNameStyle}>SAVVY GROUP</Typography>

                <Box
                  sx={{
                    height: {
                      xl: "150px",
                      lg: "150px",
                      md: "230px",
                      sm: "125px",
                      xs: "125px",
                    },
                  }}
                >
                  <h1 className="ourArchitecText sequence fadeInBottom">
                    Sameer Sinha is the foresighted person of the Savvy Group,
                    always endeavouring towards success with growth.
                  </h1>
                </Box>
                {/* <Typography sx={BottomText}>www.hirnePatel.com</Typography> */}
                {/* <a
                  target="_blank"
                  href="https://www.savvygroup.in/"
                  style={{ textDecoration: "none" }}
                >
                  <h1 className="LinkStyleText">www.savvygroup.in</h1>
                </a> */}
              </Box>
            </Grid>
            <Grid
              item
              xl={2.4}
              lg={2.4}
              md={2.4}
              sm={6}
              xs={12}
              sx={{ mx: "auto", mt: 3 }}
            >
              <Box
                sx={{
                  width: "85%",
                  maxWidth: "600px",
                  textAlign: "center",
                  mx: "auto",
                  height: {
                    xl: "350px",
                    lg: "350px",
                    md: "350px",
                    sm: "270px",
                    xs: "270px",
                  },
                  marginTop: "auto",
                  // backgroundColor: "green",
                }}
              >
                {/* <LazyLoadImage
                  delayTime={2000}
                  src={MozacImg}
                  width="160px"
                  alt="PrabhakarImg"
                /> */}
                <Typography sx={NameText}>JIGISH SHAH</Typography>
                <Typography sx={ArchitecNameStyle}>SAVVY GROUP</Typography>

                <Box
                  sx={{
                    height: {
                      xl: "150px",
                      lg: "150px",
                      md: "230px",
                      sm: "125px",
                      xs: "125px",
                    },
                  }}
                >
                  <h1 className="ourArchitecText sequence fadeInBottom">
                    Jigish Shah is the vivacious director of the Savvy Group,
                    constantly taking a favourable stance when exploring real
                    estate development.
                  </h1>
                </Box>
                {/* <Typography sx={BottomText}>www.hirnePatel.com</Typography> */}
                {/* <a
                  target="_blank"
                  href="https://www.savvygroup.in/"
                  style={{ textDecoration: "none" }}
                >
                  <h1 className="LinkStyleText">www.savvygroup.in</h1>
                </a> */}
              </Box>
            </Grid>
            <Grid
              item
              xl={2.4}
              lg={2.4}
              md={2.4}
              sm={6}
              xs={12}
              sx={{ mx: "auto", mt: 3 }}
            >
              <Box
                sx={{
                  width: "85%",
                  maxWidth: "600px",
                  textAlign: "center",
                  mx: "auto",
                  height: "300px",

                  // backgroundColor: "red",
                }}
              >
                {/* <LazyLoadImage
                  delayTime={2000}
                  src={PDSLogo}
                  width="98px"
                  alt="PrabhakarImg"
                /> */}
                <Typography sx={NameTextNew}>SIDDHARTH BAGADIA</Typography>
                <Typography sx={ArchitecNameStyle}>UNISPACE</Typography>

                <Box
                  sx={{
                    height: {
                      xl: "150px",
                      lg: "150px",
                      md: "200px",
                      sm: "120px",
                      xs: "120px",
                    },
                  }}
                >
                  <h1 className="ourArchitecText sequence fadeInBottom">
                    Siddharth Bagadia is the Chairman of the Unispace Group,
                    whose sole focus is to curate spaces that spread happiness.
                  </h1>
                </Box>
                {/* <Typography sx={BottomText}>www.hirnePatel.com</Typography> */}
                {/* <a
                  href="https://www.unispace.in/"
                  target="_blank"
                  style={{ textDecoration: "none", marginTop: "auto" }}
                >
                  <h1 className="LinkStyleText">www.unispace.in</h1>
                </a> */}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </AnimateFromDown>
    </Paper>
  );
};

export default Ourimagination;

const TitleStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: { xl: "28px", lg: "28px", md: "26px", sm: "24px", xs: "22px" },
  lineHeight: "34px",
  textAlign: "center",
  letterSpacing: "0.04em",
  textTransform: "uppercase",
  color: "#333333",
};

const TitleBottomStyle = {
  mt: "10px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "14px",
  lineHeight: "21px",
  textAlign: "center",
  letterSpacing: "0.04em",
  textTransform: "uppercase",
  color: "#4F4F4F",
  animation: "slide-up 1.1s ease-in-out",
  "@keyframes slide-up": {
    "0%": {
      opacity: 0.2,
      lineHeight: "26px",
    },
    "100%": {
      opacity: 1,
      lineHeight: "21px",
    },
  },
};

const BottomText = {
  mt: 1,
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: { xs: "14px", sm: "14px", md: "13px", lg: "13px", xl: "13px" },
  lineHeight: "24px",
  textAlign: "center",
  color: "#808186",
  animation: "slide-up 1.1s ease-in-out",
  "@keyframes slide-up": {
    "0%": {
      opacity: 0.2,
      lineHeight: "28px",
    },
    "100%": {
      opacity: 1,
      lineHeight: "24px",
    },
  },
};

const LinkTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: { xl: "14px", lg: "14px", md: "12px", sm: "12px", xs: "12px" },
  lineHeight: "31px",
  textAlign: "center",
  letterSpacing: "0.04em",
  color: "#5392BF",
  mt: 1,
};

const MainTitleStyle = {
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: { xl: "36px", lg: "36px", md: "34px", sm: "32px", xs: "30px" },
  lineHeight: "43px",
  color: "#333333",
  textAlign: "center",
  textTransform: "uppercase",
};

const MainTitleStyleTwo = {
  mt: "40px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: { xl: "40px", lg: "38px", md: "34px", sm: "32px", xs: "30px" },
  lineHeight: "43px",
  color: "#333333",
  textAlign: "center",
  textTransform: "uppercase",
};
const ArchitecNameStyle = {
  fontSamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "12px",
  lineHeight: "21px",
  textAlign: "center",
  color: "#4F4F4F",
  mt: "30px",
};
const NameText = {
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: { xl: "18px", lg: "18px", md: "16px", sm: "16px", xs: "16px" },
  lineHeight: "29px",
  color: "#333333",
  textAlign: "center",
  textTransform: "uppercase",
  mt: "20px",
  mb: "20px",
};
const NameTextNew = {
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: { xl: "18px", lg: "18px", md: "16px", sm: "16px", xs: "16px" },
  lineHeight: "29px",
  color: "#333333",
  textAlign: "center",
  textTransform: "uppercase",
  mt: "20px",
  mb: "20px",
};
