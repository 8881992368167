import React, { useState } from "react";
import ImageViewerSimple from "react-simple-image-viewer";
export default function ImageViewer({
  imageList,
  isOpen,
  setIsOpen,
  selectedImage,
}) {
  return (
    isOpen && (
      <ImageViewerSimple
        src={imageList}
        currentIndex={selectedImage}
        disableScroll={false}
        closeOnClickOutside={true}
        onClose={() => {
          setIsOpen(false);
        }}
      />
    )
  );
}
