import React, { useEffect } from "react";
import Herosection from "../../Pages/AboutUsPages/HeroSection";
import OurStory from "../../Pages/AboutUsPages/OurStory";
import OurHistory from "../../Pages/AboutUsPages/OurHistory";
import VisionMission from "../../Pages/AboutUsPages/VisionMission";
import ChairManMsg from "../../Pages/AboutUsPages/ChairManMsg";
import { Stack } from "@mui/system";
import Header from "../../components/Header/HomeHeader";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Stack>
      <Header />
      <Herosection />
      <Stack sx={{ width: "90%", mx: "auto", maxWidth: "2400px" }}>
        <OurHistory />
      </Stack>
      {/* <OurStory /> */}
      <VisionMission />
      <ChairManMsg />
    </Stack>
  );
};

export default AboutUs;
