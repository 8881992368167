import React, { useState } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";

import inverse1 from "../../assets/Images/Inverness/inverse1.png";
import inverse3 from "../../assets/Images/Inverness/inverse3.png";

import Medhaansh from "../../assets/Images/Medhaansh/Medhaansh.png";
import AllohaIntro from "../../assets/Images/AllohaHills/AllohaIntro.png";
import "./Caroso.css";
const images = [
  {
    photo: inverse1,
    label: "image1",
    type: "",
    placeName: "Inverness",
    extension: "shela extension",
    specs: "2BHK luxurious living, Ongoing",
    pageLink: "/inverness",
  },
  {
    photo: Medhaansh,
    label: "image2",
    type: "",
    placeName: "MEdhansh",
    extension: "laila extension",
    specs: "2BHK luxurious living, Ongoing",
    pageLink: "/medhaansh",
  },
  {
    photo: AllohaIntro,
    label: "image1",
    type: "Commercial",
    placeName: "Alloha",
    extension: "laila extension",
    specs: "2BHK luxurious living, Ongoing",
    pageLink: "/allohaHills",
  },
  {
    photo: Medhaansh,
    label: "image2",
    type: "",
    placeName: "MEdhansh",
    extension: "laila extension",
    specs: "2BHK luxurious living, Ongoing",
    pageLink: "/medhaansh",
  },
];

const Cara1 = () => {
  const settings = {
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 3000,
    slidesToScroll: 1,
    autoplay: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1124,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div style={{ width: "90%", marginLeft: "auto", marginRight: "auto" }}>
        <Slider {...settings}>
          {images.map((step) => (
            <div key={step.label}>
              <div className="hoverImg">
                <img
                  src={step.photo}
                  alt={step.label}
                  style={{
                    height: "350px",
                    display: "block",
                    overflow: "hidden",
                    width: "98%",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                />
                <Link
                  style={{ textDecoration: "none", color: "white" }}
                  to={step.pageLink}
                >
                  <div class="layer">
                    <h5 style={{ fontFamily: "Playfair Display" }}>
                      {step.type}
                    </h5>
                    <h3 style={{ fontFamily: "Playfair Display" }}>
                      {step.placeName},{" "}
                      <span className="span">{step.extension}</span>{" "}
                    </h3>
                    <h6 style={{ fontFamily: "Playfair Display" }}>
                      {step.specs}
                    </h6>
                  </div>
                </Link>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
};

export default Cara1;
