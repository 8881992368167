import React, { useState,useEffect } from "react";
import { Paper, Grid, Typography, Box } from "@mui/material";
import LifeStyleImg from "../../assets/Images/HomeImages/LifeStyleImg.svg";
import sereneLandImg from "../../assets/Images/HomeImages/sereneLand.svg";
import "./Style.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import AnimateFromDown from "../../components/AnimateFromDown";
import { Link } from "react-router-dom";
import invernesslogonew from "../../assets/Images/Inverness/logoInvernessChekhlanew.png";
import Mlogonew from "../../assets/Images/Medhaansh/Mlogonew.png";
import invernesslogo1 from "../../assets/Images/AllohaHills/aalloaVisibleLogoPhone.png";
import Logo from "../../assets/Images/FooterImg/Logo.png";
import { SendEvent } from "../../utils/sendEvent";

const OurVerticals = () => {
  useEffect(() => {
    SendEvent("Home Our Projects");
  }, []);
  const [oneFlip, setOneFlip] = useState(true);
  const [twoFlip, setTwoFlip] = useState(true);

  const ViewOneFlip = () => {
    setOneFlip(true);
    setTwoFlip(true);
  };
  const ViewTwoFlip = () => {
    setOneFlip(true);
    setTwoFlip(true);
  };
  return (
    <>
      <Paper
        sx={{
          height: "100%",
          width: "100%",
          minHeight: "550px",
          paddingBottom: "25px",
          bgcolor: "#f9f9f9",
          // display: {
          //   xl: "inline",
          //   lg: "inline",
          //   md: "inline",
          //   sm: "inline",
          //   xs: "inline",
          // },
        }}
        elevation={0}
      >
        <AnimateFromDown>
          <Grid container sx={{ marginTop: "50px" }}>
            <Grid item xl={8} lg={8} md={8} sm={10} xs={10} sx={{ mx: "auto" }}>
              <Box>
                <Typography sx={OurText}>
                  OUR <span style={{ color: "#000000" }}>STYLE</span>
                </Typography>
              </Box>
            </Grid>
            <Grid container sx={PageStyle}>
              <Grid
                item
                xl={6}
                lg={6}
                md={6}
                sm={12}
                xs={12}
                sx={{ textAlign: "center" }}
              >
                <Grid container mt={1}>
                  <Grid
                    item
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    sx={{ textAlign: "center" }}
                  >
                    <Box sx={{ mx: "auto" }}>
                      <Box sx={{ width: "80%", mx: "auto" }}>
                        <div class="card-container">
                          <div class="card">
                            <div class="side">
                              <Box
                                sx={{
                                  width: {
                                    xl: "140px",
                                    lg: "140px",
                                    md: "140px",
                                    sm: "140px",
                                    xs: "140px",
                                  },
                                  mx: "auto",
                                  paddingTop: "30px",
                                }}
                              >
                                <LazyLoadImage
                                  delayTime={2000}
                                  effect="opacity"
                                  src={sereneLandImg}
                                  alt="LifeSTyleImg"
                                  width="100%"
                                />
                              </Box>
                              <Typography sx={TitleStyle}>
                                Serene landscapes
                              </Typography>
                              <AnimateFromDown>
                                <h1 className="ourProjectText sequence fadeInBottom">
                                  Naturally crafted contours & wide view of the
                                  valley's are a soothing beauty to the eyes
                                </h1>
                              </AnimateFromDown>
                            </div>

                            <div class="side back">
                              {/* <h3>Back</h3> */}
                              <Link
                                to="/allohaHills"
                                style={{ textDecoration: "none" }}
                              >
                                <img
                                  src={invernesslogo1}
                                  alt="logo"
                                  style={{
                                    height: "auto",
                                    width: "50%",
                                    marginLeft: "auto",
                                    marginRight: "0px",
                                    marginTop: "30%",
                                  }}
                                />
                              </Link>
                            </div>
                          </div>
                        </div>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xl={6}
                lg={6}
                md={6}
                sm={12}
                xs={12}
                sx={{ textAlign: "center" }}
              >
                <Grid container mt={1}>
                  <Grid
                    item
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    sx={{ textAlign: "center" }}
                  >
                    <Box sx={{ mx: "auto" }}>
                      <Box sx={{ width: "80%", mx: "auto" }}>
                        <div class="card-container">
                          <div class="card">
                            <div class="side">
                              <Box
                                sx={{
                                  width: {
                                    xl: "140px",
                                    lg: "140px",
                                    md: "140px",
                                    sm: "140px",
                                    xs: "140px",
                                  },
                                  mx: "auto",
                                  paddingTop: "30px",
                                }}
                              >
                                <LazyLoadImage
                                  delayTime={2000}
                                  effect="opacity"
                                  src={LifeStyleImg}
                                  alt="LifeSTyleImg"
                                  width="100%"
                                />
                              </Box>
                              <Typography sx={TitleStyle}>
                                Finest living
                              </Typography>
                              <AnimateFromDown>
                                <h1 className="ourProjectText sequence fadeInBottom">
                                  In the lap of nature with all the amenities &
                                  needs, the experience awaits you.
                                </h1>
                              </AnimateFromDown>
                            </div>

                            <div class="side back">
                              <Link
                                to="/medhaansh"
                                style={{ textDecoration: "none" }}
                              >
                                <img
                                  src={Mlogonew}
                                  alt="logo"
                                  style={{
                                    height: "auto",
                                    width: "50%",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    marginTop: "100px",
                                  }}
                                />
                              </Link>
                              <br />
                              <Link
                                to="/inverness "
                                style={{ textDecoration: "none" }}
                              >
                                <img
                                  src={invernesslogonew}
                                  alt="logo"
                                  style={{
                                    height: "auto",
                                    width: "70%",
                                    marginRight: "auto",
                                    marginLeft: "auto",
                                    marginTop: "60px",
                                  }}
                                />
                              </Link>
                            </div>
                          </div>
                        </div>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </AnimateFromDown>
      </Paper>
    </>
  );
};

export default OurVerticals;

const PageStyle = {
  width: { xl: "60%", lg: "60%", md: "75%", sm: "100%", xs: "100%" },
  height: "100%",
  minHeight: "300px",
  mx: "auto",
  mt: "20px",
  mb: "20px",
};

const TitleStyle = {
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "25px",
  lineHeight: "32px",
  color: "#222222",
  marginTop: "20px",
  marginBottom: "20px",
  textAlign: "center",
};

const OurText = {
  // fontFamily: "Futura Medium BT",
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: { xl: "42px", lg: "42px", md: "40px", sm: "35px", xs: "35px" },
  lineHeight: "54px",
  textTransform: "capitalize",
  color: "rgba(0, 0, 0, 0.4)",
};
