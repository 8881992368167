import React from "react";
import { Paper, Grid, Box, Button, Typography } from "@mui/material";
import BroadImg from "../../assets/Images/AllohaHills/BroadImg.png";
import AnimateFromDown from "../../components/AnimateFromDown";
import "./Style.css";

const ImageSection = () => {
  return (
    <Paper elevation={0} sx={{ padding: 0, position: "relative" }}>
      <Grid
        container
        textAlign="center"
        sx={{
          transform: "translate(-50%, -50%)",
          position: "absolute",
          left: "50%",
          top: "50%",
          zIndex: 9,
        }}
      >
        {/* "rgba(0, 0, 0, 0.4)" */}
        <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
          <Typography sx={TitleStyle}>
            Created by &nbsp;
            <span
              style={{ color: "#fff", fontFamily: "Snell Roundhand, cursive" }}
            >
              Nature{" "}
            </span>
            <br />
            Curated For{" "}
            <span
              style={{ color: "#fff", fontFamily: "Snell Roundhand, cursive " }}
            >
              You
            </span>
          </Typography>
          {/* <Typography sx={TitleStyle} mt={1.5}>
            ENCHANT YOUR &nbsp;
            <span style={{ color: "#fff", fontFamily: "Playfair Display " }}>
              SENSES{" "}
            </span>
          </Typography> */}
        </Grid>
      </Grid>
      <div class="parallax-section">
        <div class="parallax-child-section">
          <section class="fw-main-row" id="frontlashID"></section>
        </div>
      </div>
    </Paper>
  );
};

export default ImageSection;

const TitleStyle = {
  fontFamily: "Snell Roundhand, cursive  ",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: { xl: "100px", lg: "100px", md: "70px", sm: "50px", xs: "50px" },
  lineHeight: 1.2,
  // color: "#333333",
  color: "#fff",
  // lineSpacing: "35px",
  textAlign: "center",
  // textTransform: "uppercase",
  // marginTop: "100px",
  zIndex: "100000",
};
