import React from "react";
import img2 from "../assets/Images/HomeCarouselImages/cam_03_FFF-min.jpg";
import Header from "../components/Header/HomeHeader";
import WhiteLogo from "../components/Header/logowhite.png";

const Project3 = () => {
  return (
    <div>
      <Header />
      <div className="PertcularPPageThree" style={{ position: "relative" }}>
        <img
          src={img2}
          alt="backGroundImg"
          style={{
            width: "100%",
            position: "relative",
          }}
        />
        <img
          src={WhiteLogo}
          style={{
            width: "200px",
            left: "82%",
            bottom: "10%",
            position: "absolute",
            objectFit: "cover",
          }}
        />
      </div>
    </div>
  );
};

export default Project3;
