import React from "react";
import { Paper, Grid, Typography, Box } from "@mui/material";
// import Medhanshlogo from "../../assets/Images/Medhaansh/medhaanshNewLogocut.png";
import medhanshablogo from "../../assets/Images/Medhaansh/medhanshablogopcaro.png";
import invernesslogo from "../../assets/Images/HomeCarouselImages/Alogo.png";
import invernesslogonew from "../../assets/Images/Inverness/invernesslogonew.png";
import "./caraouselNew.css";
import NextArr from "./next_arr.png";
import PreviousArr from "./previous_arr.png";
import Explore from "./explore.png";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { Howl } from "howler";
import audio from "../../assets/Music/newaudio1.mp3";
import { useEffect } from "react";
import { SendEvent } from "../../utils/sendEvent";

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div className="HeroSidebarButtonWrapTwo">
      <button className="HeroSidebarButtonTwo" onClick={onClick}>
        <img src={PreviousArr} style={{ width: "100%", height: "auto" }} />
      </button>
    </div>
  );
}
const scrollWin = () => {
  window.scrollTo(0, 16000);
};

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div className="HeroSidebarButtonWrapOne" style={{ zIndex: 5 }}>
      <button
        className="HeroSidebarButtonOne"
        onClick={onClick}
        style={{ textAlign: "center" }}
      >
        <img
          src={NextArr}
          style={{
            width: "100%",
            height: "auto",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        />
      </button>
    </div>
  );
}

const images = [
  {
    bgImage: "CaroDivThree",
    textTwo: "@Aalloa Hills",
    actionTextOne: "= View Project",
    actionTextTwo: "= Call Now",
    logo: invernesslogo,
    link: "/allohaHills",
  },
  {
    bgImage: "CaroDivTwo",
    textTwo: "@Shela Extension",
    actionTextOne: "= View Project",
    actionTextTwo: "= Call Now",
    link: "/medhaansh",
    logo: medhanshablogo,
  },
  {
    bgImage: "CaroDivOne",
    textTwo: "@Chekhla",
    actionTextOne: "= View Project",
    actionTextTwo: "= Call Now",
    link: "/inverness",
    logo: invernesslogonew,
  },
];

const pages = ["INVERNESS", "UPCOMING", "INVERNESS"];
const extPages = ["@Aalloa Hills", "@Shela Extension", "@Chekhla"];

const CaraouselNew = () => {
  const [ref, inView] = useInView({
    threshold: 0,
  });

  const sound = new Howl({
    src: [audio],
    html5: true,
  });

  return (
    <>
      <Paper
        elevation={0}
        sx={{ overFlow: "hidden", width: "100vw", maxWidth: "100vw" }}
        ref={ref}
      >
        <Grid
          container
          sx={{ overFlow: "hidden", width: "100vw", maxWidth: "100vw" }}
        >
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Slider
              dots={true}
              infinite={true}
              speed={1500}
              customPaging={(i) => {
                return <CustomPagination data={i} />;
              }}
              dotsClass="slick-dotnew custom-indicatornew"
              autoplay={false}
              slidesToShow={1}
              draggable={false}
              nextArrow={<SampleNextArrow />}
              prevArrow={<SamplePrevArrow />}
            >
              {images.map((step, index) => (
                <>
                  <Box
                    key={index}
                    sx={{
                      height: "100%",
                      minHeight: "105vh",
                      width: "100%",
                      maxWidth: "100vw",
                      overflow: "hidden",
                    }}
                  >
                    <div
                      style={{
                        height: "100%",
                        minHeight: "105vh",
                        width: "100%",
                        maxWidth: "100%",
                        overFlow: "hidden",
                      }}
                    >
                      <Link to={step.link}>
                        <div
                          className={step.bgImage}
                          style={{ marginTop: "-45px" }}
                        ></div>
                      </Link>
                    </div>

                    <Box sx={InformationBoxThree}>
                      <Typography sx={TextTwoStyleChild1}>
                        {step.textone}
                      </Typography>
                      {step.logo ? (
                        <>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "5px",
                            }}
                          >
                            <div>
                              <img
                                src={step.logo}
                                alt="medhansh logo"
                                style={{
                                  objectFit: "contain",
                                  width: "90%",
                                  height: "90%",
                                  marginBottom: "5px",
                                  marginLeft: "50px",
                                }}
                              />
                            </div>
                            <Link to={step.link}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Typography
                                  textAlign="center"
                                  sx={TextOneStyle1}
                                >
                                  <img
                                    src={Explore}
                                    alt="exploreImg"
                                    style={{
                                      display: "inline",
                                      height: "20px",
                                      width: "20px",
                                      marginLeft: "auto",
                                      opacity: "0.5",
                                    }}
                                  />
                                  {"    "}
                                  Explore Project
                                </Typography>
                              </div>
                            </Link>
                          </div>
                        </>
                      ) : (
                        <Typography sx={TextTwoStyleChild}>
                          {step.textTwo}
                        </Typography>
                      )}
                    </Box>
                    <Grid container>
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Box
                          sx={{
                            width: "200px",
                            mx: "auto",
                            position: "relative",
                            marginTop: "210px",
                          }}
                        >
                          <Link
                            to={step.link}
                            style={{
                              textDecoration: "none",
                            }}
                          ></Link>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </>
              ))}
            </Slider>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default CaraouselNew;

const CustomPagination = (props) => {
  useEffect(() => {
    SendEvent("Home Carousel");
  }, []);
  return (
    <button style={{ width: "100%" }} onClick={props.onClick}>
      <span
        style={{
          fontSize: "18px",
          fontFamily: "Playfair Display",
          color: "white",
        }}
      >
        {pages[props.data]}
      </span>
      <br />
      <span
        style={{
          fontSize: "12px",
          fontFamily: "Playfair Display",
          color: "white",
        }}
      >
        {extPages[props.data]}
      </span>
    </button>
  );
};

const InformationBoxThree = {
  marginTop: {
    xl: "-450px",
    lg: "-450px",
    md: "-450px",
    sm: "-390px",
    xs: "-390px",
  },
  marginLeft: "auto",
  position: "relative",
  marginRight: "50px",
  width: "100%",
  maxWidth: "400px",
  textAlign: "right",
  height: "100%",
  opacity: 1,
  textAlign: "center",
  zIndex: 10,
};

const TextOneStyle1 = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  zIndex: 10,
  fontWeight: 400,
  fontSize: "15px",
  textAlign: "right",
  textTransform: "uppercase",
  color: "#FFFFFF",
  opacity: 1,
  color: "rgba(255, 255, 255, 0.7)",
  custom: "Pointer",
  textAlign: "center",
  width: "100%",
  left: "auto",
  right: "auto",
  position: "absolute",
  bottom: "4%",
  zIndex: 1000,
  "&:hover": {
    borderTop: "0px",
    color: "#ffffff",
    bgcolor: "transparent",
  },
};

const TextTwoStyleChild = {
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "35px",
  textAlign: "right",
  letterSpacing: "2px",
  textTransform: "none",
  color: "#FFFFFF",
  mt: "30px",
  opacity: 1,
};
const TextTwoStyleChild1 = {
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "35px",
  lineHeight: "35px",
  textAlign: "right",
  letterSpacing: "2px",
  textTransform: "uppercase",
  color: "white",
  mt: "30px",
  opacity: 1,
};
