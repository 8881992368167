import React from "react";
import { Paper, Grid, Box } from "@mui/material";
import OurHistoryImage from "../../assets/Images/AboutUsImages/OurHistoryImage.png";
import "./Css/aboutstyle.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import AnimateFromSide from "../../components/AnimateFromSide";
import AnimateFromLeftSide from "../../components/AnimateFromLeftSide";
const OurHistory = () => {
  return (
    <Paper elevation={0}>
      <Grid container sx={PageStyle}>
        <Grid
          item
          xl={4.5}
          lg={4.5}
          md={12}
          sm={12}
          xs={12}
          sx={{ height: "inherit" }}
        >
          <AnimateFromLeftSide>
            <Box sx={ImageBoxStyle}>
              <img
                className="imgSize"
                delayTime={2000}
                effect="opacity"
                src={OurHistoryImage}
                width="95%"
                height="100%"
                alt="OurhistoryImage"
              />
            </Box>
          </AnimateFromLeftSide>
        </Grid>

        <Grid
          item
          xl={7.5}
          lg={7.5}
          md={12}
          sm={12}
          xs={12}
          sx={{ height: "inherit" }}
        >
          <AnimateFromSide>
            <Paper sx={paperStyle} elevation={0}>
              <div
                class="page"
                style={{
                  width: "90%",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <h3 className="MainTextStyl">
                  Our <span className="HistorySpan">History</span>
                </h3>
                <h1 className="OurHistoryTextStyle sequence fadeInBottom">
                  Unispace Ventures has an experience of over 4 decades, with
                  initial experience in land trading specifically in the Sanand
                  region of Ahmedabad. Originally, founded by Mr. Bharat Bagadia
                  who came to Ahmedabad and pursued business as a bearing trader
                  and finally entered into land buying, selling, and land
                  development for real estate.
                </h1>

                <h1 className="OurHistoryTextStyle sequence fadeInBottom">
                  Unispace Ventures has always been rooted in its long-standing
                  culture & beliefs. Hence, land development was always carried
                  out with the environment staple methods and therefore,
                  sustainable development has always been at the heart of our
                  development.
                </h1>

                <h1 className="OurHistoryTextStyle sequence fadeInBottom">
                  We made our mark with our first project “Inverness” at Chekhla
                  in the Sanand region of Ahmedabad. Unispace subsequently
                  advanced to other prime areas of Botad, Ahmedabad, Palitana,
                  Karjan, Changodar & Sanand.
                </h1>

                <h1 className="OurHistoryTextStyle sequence fadeInBottom">
                  Advancing from tailwinds, the group has been able to establish
                  itself as a prime & trustworthy land developer in the region
                  of Ahmedabad. The group has completed & closed all of its land
                  development projects always within said convictions.
                </h1>
              </div>
            </Paper>
          </AnimateFromSide>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default OurHistory;

const ImageBoxStyle = {
  width: "95%",
  maxWidth: "auto",
  mx: "auto",
  marginBottom: {
    xl: "0px",
    lg: "20px",
    md: "50px",
    sm: "0px",
    sx: "0px",
  },
};



const PageStyle = {
  width: "95%",
  my: "50px",
  mx: "auto",
};

const paperStyle = {
  height: "100%",
  padding: "20px",
  minHeight: "520px",
  border: "0px solid black",
  bgcolor: "#fff",
  marginTop: { xl: "0px", lg: "0px", md: "0px", sm: "50px", xs: "50px" },
  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
};
