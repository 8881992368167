import React, { useState } from "react";
import AmentiseImg from "../../assets/Images/Inverness/Amentise.png";
import { Paper, Grid, Box, Button, Typography } from "@mui/material";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import AnimateFromDown from "../../components/AnimateFromDown";
import "./Style.css";
import event1 from "../../assets/Images/amenities/lawn.png";
import tracknewone from "../../assets/Images/amenities/tracknewone.png";
import birdhabitnew from "../../assets/Images/amenities/birdhabitnew.png";
import starnew from "../../assets/Images/amenities/starnew.png";
import cyclenewone from "../../assets/Images/amenities/cyclenewone.png";
import offRoadnew from "../../assets/Images/amenities/offRoadnew.png";
import campnew from "../../assets/Images/amenities/campnew.png";
import yoganew from "../../assets/Images/amenities/yoganew.png";
import swimmingnew from "../../assets/Images/amenities/swimmingnew.png";
import diningnew from "../../assets/Images/amenities/diningnew.png";
import golf from "../../assets/Images/amenities/golf-course.png";
import eventnew from "../../assets/Images/amenities/eventnew.png";
import homenew from "../../assets/Images/amenities/homenew.png";
import houseKeep from "../../assets/Images/amenities/houseKeepNew.png";
import water from "../../assets/Images/amenities/water.png";
import event from "../../assets/Images/amenities/Events.png";
import poolnew1 from "../../assets/Images/amenities/poolnew1.png";
import tableTennis from "../../assets/Images/amenities/table-tennis.png";
import carrom from "../../assets/Images/amenities/carrom.png";
import football from "../../assets/Images/amenities/football.png";
import chess from "../../assets/Images/amenities/chess-pawn.png";
import AmenitiesBox from "../../components/amenities";
import AmenitiesBox1 from "../../components/amenties1";

const Amentise = () => {
  return (
    <Paper
      sx={{
        height: "100%",
        minHeight: "500px",
        maxHeight: "auto",
        marginTop: "100px",
      }}
      elevation={0}
    >
      <Grid container mt={5}>
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{ textAlign: "center" }}
        >
          <Typography sx={TitleTextStyle}>AMENITIES</Typography>
        </Grid>

        <Grid
          item
          xl={11}
          lg={11}
          md={11}
          sm={11}
          xs={11}
          sx={{
            height: "100%",
            overflow: "scroll",
            mx: "auto",
            mt: "20px",
          }}
        >
          <Grid container>
            <Grid item xl={3} lg={3} md={3} sm={3} xs={6}>
              <AmenitiesBox imageUrl={tracknewone} text={"Trekking "} />
            </Grid>
            <Grid item xl={3} lg={3} md={3} sm={3} xs={6}>
              <AmenitiesBox imageUrl={cyclenewone} text={"Cycling"} />
            </Grid>
            <Grid item xl={3} lg={3} md={3} sm={3} xs={6}>
              <AmenitiesBox imageUrl={offRoadnew} text={"Off-Roading"} />
            </Grid>
            <Grid item xl={3} lg={3} md={3} sm={3} xs={6}>
              <AmenitiesBox imageUrl={birdhabitnew} text={"Bird Habitat"} />
            </Grid>
            <Grid item xl={3} lg={3} md={3} sm={3} xs={6}>
              <AmenitiesBox imageUrl={starnew} text={"Star Gazing"} />
            </Grid>
            {/* <Grid item xl={3} lg={3} md={3} sm={3} xs={6}>
              <AmenitiesBox imageUrl={houseKeep} text={"House Keeping"} />
            </Grid> */}
            <Grid item xl={3} lg={3} md={3} sm={3} xs={6}>
              <AmenitiesBox imageUrl={campnew} text={"Camping "} />
            </Grid>
            <Grid item xl={3} lg={3} md={3} sm={3} xs={6}>
              <AmenitiesBox imageUrl={yoganew} text={"Yoga Desk"} />
            </Grid>
            <Grid item xl={3} lg={3} md={3} sm={3} xs={6}>
              <AmenitiesBox imageUrl={swimmingnew} text={"Swimming Pool"} />
            </Grid>
            <Grid item xl={3} lg={3} md={3} sm={3} xs={6}>
              <AmenitiesBox imageUrl={diningnew} text={"Dining"} />
            </Grid>
            {/* <Grid item xl={3} lg={3} md={3} sm={3} xs={6}>
              <AmenitiesBox1 imageUrl={golf} text={"Adventure Golf Course"} />
            </Grid> */}
            <Grid item xl={3} lg={3} md={3} sm={3} xs={6}>
              <AmenitiesBox imageUrl={eventnew} text={"Events"} />
            </Grid>
            <Grid item xl={3} lg={3} md={3} sm={3} xs={6}>
              <AmenitiesBox imageUrl={homenew} text={"Club House"} />
            </Grid>
            <Grid item xl={3} lg={3} md={3} sm={3} xs={6}>
              <AmenitiesBox imageUrl={poolnew1} text={"Golf"} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Amentise;

const TitleTextStyle = {
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "32px",
  lineHeight: "38px",
  color: "#000000",
  textAlign: "center",
};
