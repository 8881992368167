import { Paper, Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
import "./Css/aboutstyle.css";
import AnimateFromDown from "../../components/AnimateFromDown";
import { ImQuotesLeft, ImQuotesRight } from "react-icons/im";
import { SendEvent } from "../../utils/sendEvent";

const ChairManMsg = () => {
  useEffect(() => {
    SendEvent("Chairman's message view");
  }, []);

  return (
    <Paper
      sx={{
        height: "100%",
        minHeight: "500px",
        py: 10,
        width: "100%",
        maxWidth: "2400px",
        mx: "auto",
      }}
      elevation={0}
    >
      <Box
        sx={{
          width: "80%",
          mx: "auto",
        }}
      >
        <Typography sx={TitleTextStyle} gutterBottom>
          chairman’s{" "}
          <span style={{ color: "rgba(51, 51, 51, 0.5)", fontWeight: 500 }}>
            message
          </span>
        </Typography>

        <AnimateFromDown>
          <h1
            className="QuotedText sequence fadeInBottom"
            style={{ textAlign: "center", fontSize: "25px" }}
          >
            <ImQuotesLeft
              style={{ color: "lightgray", fontFamily: "Playfair Display" }}
            />{" "}
            We derive our strength and conviction from our strong ethical
            values. By maintaining high standards of quality and developing
            cost-effective residential and commercial structures, we are
            delivering exceptional value. Our commitment of value to our clients
            is built on the bedrock of trust and transparency. With a
            professional management team that is a blend of youth and experience
            and with an entrenched focus on quality, reliability, speed, and
            transparency, we are scaling great heights of excellence. By adding
            new competencies, and implementing best practices and technologies,
            we aspire to grow into being one of the most trusted real estate
            developers in the nation. We want to help build our nation by
            energizing lives and spreading happiness through the spaces we
            create. <ImQuotesRight style={{ color: "lightgray" }} />
          </h1>
        </AnimateFromDown>
      </Box>
    </Paper>
  );
};

export default ChairManMsg;

const TitleTextStyle = {
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "36px",
  lineHeight: "43px",
  color: "#333333",
  textTransform: "uppercase",
  textAlign: "center",
  mb: 2,
};
