import React from "react";
import { useState, useEffect, useRef } from "react";
import { Paper, Grid, Box, Button, Typography } from "@mui/material";
import { useSwipeable } from "react-swipeable";
import { Link } from "react-router-dom";
import invernesslogonew from "../../assets/Images/Inverness/invernesslogonew.png";
import MedhaanshImg from "../../assets/Images/HomeCarouselImages/Medhaansh.png";
import { BsArrowRight } from "react-icons/bs";
import { AiOutlineLine } from "react-icons/ai";
import downloadFromUrl from "../../utils/downloadFromUrl";
import Tooltip from "@mui/material/Tooltip";
import HeroLogo from "../../assets/Images/Inverness/HeroLogo.png";
import Modal from "@mui/material/Modal";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import InvernessPdf from "../../assets/Pdf/Inverness.pdf";
import "./Style.css";
import { Howl } from "howler";
import Rain from "../../assets/Music/Rain.mp3";
import Musicclip from "../../assets/Music/music.wav";
import invernessMusic from "../../assets/Music/invernessMusic.mp3";
// import BirdAudio from "../../assets/Music/birdvoice.wav"
// import newaudio from "../../assets/Music/newaudio1.mp3";
import { useInView } from "react-intersection-observer";
import { SendEvent } from "../../utils/sendEvent";

import { IoMdClose } from "react-icons/io";

import { FaEquals } from "react-icons/fa";

const HeroSection = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [from, setFrom] = useState("Medhaansh");
  const [allfilled, setAllfilled] = useState(false);

  const scrollWin = () => {
    window.scrollTo(0, 20000);
  };
  useEffect(() => {
    SendEvent("Inverness page visit");
  }, []);

  async function formSubmit() {
    let form = new FormData();

    form.append("name", name);
    form.append("email", email);
    form.append("phone", phone);
    form.append("message", message);
    form.append("from", from);
    if (name === "" || email === "" || phone === "" || message === "") {
      setAllfilled(false);
      alert("Please fill all the fields");

      handleClose();
    } else {
      fetch("https://formbold.com/s/9XL59", { method: "POST", body: form });
      SendEvent("Inverness broucher download");
      alert("Thank you. your form was submited");
      downloadFromUrl(InvernessPdf, "Inverness_alloa_hills");
      handleClose();
    }
  }

  const [ref, inView] = useInView({
    threshold: 0,
  });
  const playerRef = useRef();

  const [trues, setTrues] = useState(false);

  const sound = new Howl({
    src: [Rain],
    html5: true,
  });

  return (
    <Paper elevation={0} ref={ref}>
      <Grid container>
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{
            width: "100vw",
            height: "100vh",
            maxHeight: "100vh",
            overflow: "hidden",
            position: "relative",
          }}
        >
          <div className="heroImgInver "></div>
          <Box sx={InformationBox}>
            <img src={invernesslogonew} alt="logo" className="invernessLogo" />
          </Box>
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "center",
              position: "absolute",
              top: "87%",
              width: { xl: "50%", lg: "50%", md: "60%", sm: "70%", xs: "95%" },
              left: { xl: "50%", lg: "50%", md: "50%", sm: "50%", xs: "50%" },
              transform: "translate(-50%,-50%)",
              maxWidth: "400px",
              mx: "auto",
              minWidth: "200px",
            }}
          >
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Button
                sx={{
                  height: "100%",
                  width: "70%",
                  maxWidth: "200px",
                  mx: "auto",
                  borderTop: "0px",

                  borderRadius: "0px",
                  pt: 1,
                  px: 0.5,
                  color: "rgba(255, 255, 255, 0.3)",
                  custom: "Pointer",
                  "&:hover": {
                    borderTop: "0px",
                    color: "#fff",
                    bgcolor: "transparent",
                  },
                }}
                onClick={handleOpen}
              >
                <Typography
                  sx={{
                    ...CarouselActiveTextOne,
                    alignContent: "center",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                    display: "flex",
                  }}
                >
                  <FaEquals /> Brochure
                </Typography>
              </Button>
            </Grid>
            {/* <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
              <Button
                onClick={() => {
                  scrollWin();
                }}
                sx={{
                  height: "100%",
                  width: "70%",
                  maxWidth: "200px",
                  mx: "auto",
                  borderTop: "0px",
                  borderRadius: "0px",
                  pt: 1,
                  px: 0.5,
                  color: "rgba(255, 255, 255, 0.3)",
                  custom: "Pointer",
                  "&:hover": {
                    borderTop: "0px",
                    color: "#fff",
                    bgcolor: "transparent",
                  },
                }}
              >
                <Typography sx={CarouselActiveTextOne}>
                  <FaEquals /> Call now
                </Typography>
              </Button>
            </Grid> */}
          </Grid>
          {/* </Box> */}

          {/* <audio
            ref={playerRef}
            loop={true}
            src={newaudio}
            onload={newaudio}
            id="audio"
            controls="controls"
            preload="auto"
            autoplay="autoplay" playsInline
            // controls
          ></audio> */}

          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Button
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  color: "#000",
                  "&:hover": {
                    bgcolor: "transparent",
                  },
                }}
              >
                <IoMdClose size={30} />
              </Button>
              <Grid container>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Typography sx={MainTitleStyleTwo}>
                    <span style={{ color: "rgba(34, 34, 34, 0.5)" }}>
                      Fill Your
                    </span>{" "}
                    DETAILS
                  </Typography>
                  <Typography sx={ParaStyle}>
                    Request you to kindly fill all the required details.
                  </Typography>
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Box sx={{ width: "100%", mt: 0, mx: "auto" }}>
                    <form
                      id="form"
                      onSubmit={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <Grid container>
                        <Grid
                          item
                          xl={11}
                          lg={11}
                          md={11}
                          sm={11}
                          xs={11}
                          sx={{ textAlign: "left", mt: 2 }}
                        >
                          <Typography sx={LableTextSTyle}>Name</Typography>
                          <input
                            type="text"
                            name="name"
                            onChange={(e) => setName(e.target.value)}
                            style={InputBoxstyle}
                          />
                        </Grid>
                        <Grid
                          item
                          xl={11}
                          lg={11}
                          md={11}
                          sm={11}
                          xs={11}
                          sx={{ textAlign: "right", mt: 2 }}
                        >
                          <Typography sx={LableTextSTyle}>Mobile</Typography>

                          <input
                            type="text"
                            name="phone"
                            onChange={(e) => setPhone(e.target.value)}
                            style={InputBoxstyle}
                          />
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xl={11} lg={11} md={11} sm={11} xs={11}>
                          <Typography sx={LableTextSTyle}>
                            Email Address
                          </Typography>

                          <input
                            type="text"
                            name="email"
                            onChange={(e) => setEmail(e.target.value)}
                            style={InputBoxstyle}
                          />
                        </Grid>
                        <Grid item xl={11} lg={11} md={11} sm={11} xs={11}>
                          <Typography sx={LableTextSTyle}>Message</Typography>

                          <textarea
                            type="text"
                            name="message"
                            onChange={(e) => setMessage(e.target.value)}
                            style={InputMessBoxStyle}
                          />
                        </Grid>
                        <Grid
                          item
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          sx={{ textAlign: "center" }}
                        >
                          <Button
                            type="submit"
                            sx={{
                              mt: "17px",
                              width: "180px",
                              mx: "auto",
                              height: "50px",
                              background: "#F4F4F4",
                              borderRadius: "0px",
                              fontSize: "18px",
                              textAlign: "center",
                              letterSpacing: "0.05em",
                              textTransform: "uppercase",
                              color: "#000",
                              "&:hover": {
                                backgroundColor: "#000",
                                color: "#fff",
                              },
                            }}
                            onClick={() => formSubmit()}
                          >
                            Send
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Modal>
        </Grid>
      </Grid>
      {/* <div className="audioSetting">
        <Grid container>
          <Tooltip title="Play/Pause Music" placement="top-start">
            <audio
              src={invernessMusic}
              controls
              loop
              style={{ opacity: 0.5, marginTop: "-60px", marginLeft: "10px" }}
            ></audio>
          </Tooltip>
        </Grid>
      </div> */}
    </Paper>
  );
};

export default HeroSection;

const CrouselBoxStyle = {
  width: "100%",
  height: "100%",
  minHeight: "800px",
  maxHeight: "100vh",
  position: "relative",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xl: "500px", lg: "500px", md: "500px", sm: "300px", xs: "300px" },
  maxWidth: "600px",
  height: "100%",
  maxHeight: "620px",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
};

const ParaStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "20px",
  color: "#868686",
  mt: 0,
  textAlign: "center",
};

const MainTitleStyleTwo = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "25px",
  mb: 2,
  lineHeight: "45px",
  color: "#333333",
  textAlign: "center",
  letterSpacing: "0.7px",
  textTransform: "uppercase",
};

const InputMessBoxStyle = {
  width: "100%",
  mx: "auto",
  height: "60px",
  border: "1px solid #F4F4F4",
  borderRadius: " 0px",
  paddingLeft: "20px",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "25px",
  textAlign: "left",
  letterSpacing: "0.05em",
  background: "#F4F4F4",
  color: "#868686",
  resize: "none",
  fontFamily: "Poppins",
};

const LableTextSTyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  mt: "15px",
  fontWeight: 600,
  fontSize: "14px",
  lineHeight: "26px",
  color: "#808186",
  textAlign: "left",
  letterSpacing: "0.05em",
  textTransform: "uppercase",
  mb: "7px",
};

const InputBoxstyle = {
  width: "100%",
  mx: "auto",
  height: "40px",
  border: "1px solid #F4F4F4",
  borderRadius: " 0px",
  paddingLeft: "20px",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "25px",
  textAlign: "left",
  letterSpacing: "0.05em",
  background: "#F4F4F4",
  color: "#868686",
};

const InformationBox = {
  position: "absolute",
  top: { xl: "80%", lg: "80%", md: "80%", sm: "80%", xs: "80%" },
  right: "2%",
  transform: "translate(-0%,-50%)",
  width: "100%",
  textAlign: "right",
  height: "100%",
  maxHeight: "500px",
  bgcolor: "transparent",
  opacity: "1",
};

const TextOneStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: { xl: "22px", lg: "20px", md: "18px", sm: "16px", xs: "14px" },
  textAlign: "right",
  letterSpacing: "7.33333px",
  textTransform: "uppercase",
  color: "#FFFFFF",
  opacity: 0.5,
};

const TextThreeStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: { xl: "16px", lg: "16px", md: "16px", sm: "12px", xs: "12px" },
  lineHeight: "32px",
  textAlign: "right",
  letterSpacing: "7.33333px",
  textTransform: "uppercase",
  color: "#FFFFFF",
  opacity: 0.5,
  mt: "30px",
};

const CarouselActiveTextOne = {
  // fontSamily: "Fira Sans",
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: { xl: "18px", lg: "18px", md: "18px", sm: "16px", xs: "14px" },
  lineHeight: "20px",
  textTransform: "uppercase",
  textAlign: "center",
  color: "white",
  opacity: "0.8",
  "&:hover": {
    opacity: "5",
  },
};

const CarouselActiveTextTwo = {
  fontSamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "19px",
  justifyContent: "bottom",
  mt: 1,
};

const TextTwoStyleChild = {
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: { xl: "18px", lg: "16px", md: "14px", sm: "14px", xs: "14px" },
  lineHeight: "35px",
  textAlign: "right",
  letterSpacing: "2px",
  textTransform: "none",
  color: "#FFFFFF",
  mt: "30px",
  opacity: 0.7,
};
const TextTwoStyle = {
  // fontFamily: "Allura,cursive",
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: { xl: "40px", lg: "35px", md: "35px", sm: "30px", xs: "25px" },
  lineHeight: { xl: "36px", lg: "30px", md: "30px", sm: "20px", xs: "20px" },
  textAlign: "right",
  letterSpacing: {
    xl: "7.33333px",
    lg: "7.33333px",
    md: "5px",
    sm: "3px",
    xs: "1.5px",
  },
  textTransform: "uppercase",
  color: "#FFFFFF",
  mt: { xl: "30px", lg: "25px", md: "20px", sm: "15px", xs: "10px" },
};
