import React, { useEffect } from "react";
import { Paper, Stack } from "@mui/material";
import Herosection from "../../Pages/Medhaansh/Herosection";
import MedhaanshIntro from "../../Pages/Medhaansh/MedhaanshIntro";
import Amentise from "../../Pages/Medhaansh/Amentise";
import OurArchitects from "../../Pages/Medhaansh/OurArchitects";
import ContactInformation from "../../Pages/Medhaansh/ContactInformation";
import MedhaanshGalary from "../../Pages/Medhaansh/MedhaanshGalary";
import Ourimagination from "../../Pages/Medhaansh/Ourimagination";
import Header from "../../components/Header/HomeHeader";

const Medhaansh = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Paper elevation={0}>
      <Header />
      <Herosection />
      <Stack sx={{ width: "90%", mx: "auto", maxWidth: "2400px" }}>
        <MedhaanshIntro />
        <MedhaanshGalary />
        <Amentise />
        {/* <DownloadB /> */}
      </Stack>
      <OurArchitects />
      <Ourimagination />
      <Stack sx={{ width: "90%", mx: "auto", maxWidth: "2400px" }}>
        <ContactInformation />
      </Stack>
    </Paper>
  );
};

export default Medhaansh;
