import React, { useState } from "react";
import { Paper, Grid, Typography, Box } from "@mui/material";

import Club_house_Secor_A_new from "../../assets/Images/Inverness/Inverness/1_Club_house_Secor_A_new.jpg";
import Garden_Sector_A_new from "../../assets/Images/Inverness/Inverness/3_Garden_Sector_A_new.jpg";
import Sample_House_new from "../../assets/Images/Inverness/Inverness/6_Sample_House_new.jpg";
import Sample_House from "../../assets/Images/Inverness/Inverness/10_Sample_House.jpg";
import Sample_House_new12 from "../../assets/Images/Inverness/Inverness/12_Sample_House_new.jpg";
import Garden_Sectoe_A_new from "../../assets/Images/Inverness/Inverness/18_Garden_Sectoe_A_new.jpg";
import Club_house_Secor_A_new19 from "../../assets/Images/Inverness/Inverness/19_Club_house_Secor_A_new.jpg";
import Club_house_Secor_A_new21 from "../../assets/Images/Inverness/Inverness/21_Club_house_Secor_A_new.jpg";
import Sample_house22 from "../../assets/Images/Inverness/Inverness/22_Sample_house.jpg";
import Wood_house_SectorB from "../../assets/Images/Inverness/Inverness/14_Wood_house_SectorB.jpg";
import WhatsApp11 from "../../assets/Images/Inverness/Inverness/WhatsApp11.jpeg";
import consupdate12 from "../../assets/Images/Inverness/Inverness/consupdate12.jpg";

import view1 from "../../assets/Images/Inverness/Inverness/view1.jpeg";
import view2 from "../../assets/Images/Inverness/Inverness/view2.jpeg";
import view3 from "../../assets/Images/Inverness/Inverness/view3.jpeg";
import view4 from "../../assets/Images/Inverness/Inverness/view4.jpeg";
import view5 from "../../assets/Images/Inverness/Inverness/view5.jpeg";
import view6 from "../../assets/Images/Inverness/Inverness/view6.jpeg";

import ImageViewer from "../../components/ImageViewer";
import AnimateFromDown from "../../components/AnimateFromDown";
import "./invernessGalaryNew.css";

const InvernessGalaryNew = () => {
  const [imageViewerOpen, setImageViewerOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);
  const openViewer = (index) => {
    setSelectedImage(index);
    setImageViewerOpen(true);
  };
  const images = [
    Club_house_Secor_A_new,
    Garden_Sector_A_new,
    Sample_House_new,
    Sample_House,
    Sample_House_new12,
    Garden_Sectoe_A_new,
    Club_house_Secor_A_new19,
    Club_house_Secor_A_new21,
    Sample_house22,
    Wood_house_SectorB,
    WhatsApp11,
    consupdate12,
    view1,
    view2,
    view3,
    view4,
    view5,
    view6,
  ];

  return (
    <Paper elevation={0}>
      <AnimateFromDown>
        <ImageViewer
          imageList={images}
          isOpen={imageViewerOpen}
          selectedImage={selectedImage}
          setIsOpen={setImageViewerOpen}
        />
        <Grid container>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Typography sx={ViewTextStyle}>VIEW</Typography>
          </Grid>
        </Grid>
      </AnimateFromDown>
      <Paper
        elevation={0}
        sx={{
          height: "100%",
          minHeight: "900px",
          width: "100%",
          mt: 5,
        }}
      >
        <AnimateFromDown>
          <Grid container>
            <Grid item xl={4} lg={4} md={4} sm={6} xs={12} mt={"10px"}>
              <div
                onClick={(e) => {
                  openViewer(0);
                }}
                className="InOneImg  img-zoom"
              ></div>
            </Grid>
            <Grid
              item
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
              className="gal-img"
              mt={"10px"}
            >
              <div
                onClick={(e) => {
                  openViewer(1);
                }}
                className="InTwoImg  img-zoom"
              ></div>
            </Grid>
            <Grid
              item
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
              className="gal-img"
              mt={"10px"}
            >
              <div
                onClick={(e) => {
                  openViewer(2);
                }}
                className="InThreeImg  img-zoom"
              ></div>
            </Grid>

            <Grid
              item
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
              className="gal-img"
              mt={"10px"}
            >
              <div
                onClick={(e) => {
                  openViewer(3);
                }}
                className="InFourImg  img-zoom"
              ></div>
            </Grid>
            <Grid
              item
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
              className="gal-img"
              mt={"10px"}
            >
              <div
                onClick={(e) => {
                  openViewer(4);
                }}
                className="InFiveImg  img-zoom"
              ></div>
            </Grid>
            <Grid
              item
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
              className="gal-img"
              mt={"10px"}
            >
              <div
                onClick={(e) => {
                  openViewer(5);
                }}
                className="InSixImg  img-zoom"
              ></div>
            </Grid>

            <Grid
              item
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
              className="gal-img"
              mt={"10px"}
            >
              <div
                onClick={(e) => {
                  openViewer(6);
                }}
                className="InSevenImg  img-zoom"
              ></div>
            </Grid>
            <Grid
              item
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
              className="gal-img"
              mt={"10px"}
            >
              <div
                onClick={(e) => {
                  openViewer(7);
                }}
                className="InEightImg  img-zoom"
              ></div>
            </Grid>
            <Grid
              item
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
              className="gal-img"
              mt={"10px"}
            >
              <div
                onClick={(e) => {
                  openViewer(8);
                }}
                className="InNineImg  img-zoom"
              ></div>
            </Grid>
            <Grid
              item
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
              className="gal-img"
              mt={"10px"}
            >
              <div
                onClick={(e) => {
                  openViewer(9);
                }}
                className="InTenImg  img-zoom"
              ></div>
            </Grid>
            <Grid
              item
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
              className="gal-img"
              mt={"10px"}
            >
              <div
                onClick={(e) => {
                  openViewer(10);
                }}
                className="InElevenImg  img-zoom"
              ></div>
            </Grid>
            <Grid
              item
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
              className="gal-img"
              mt={"10px"}
            >
              <div
                onClick={(e) => {
                  openViewer(11);
                }}
                className="InTweleveImg  img-zoom"
              ></div>
            </Grid>
            <Grid
              item
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
              className="gal-img"
              mt={"10px"}
            >
              <div
                onClick={(e) => {
                  openViewer(12);
                }}
                className="InThirteenImg  img-zoom"
              ></div>
            </Grid>
            <Grid
              item
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
              className="gal-img"
              mt={"10px"}
            >
              <div
                onClick={(e) => {
                  openViewer(13);
                }}
                className="InForteenImg  img-zoom"
              ></div>
            </Grid>
            <Grid
              item
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
              className="gal-img"
              mt={"10px"}
            >
              <div
                onClick={(e) => {
                  openViewer(14);
                }}
                className="InFifteenImg  img-zoom"
              ></div>
            </Grid>
            {/* <Grid
              item
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
              className="gal-img"
              mt={"10px"}
            >
              <div
                onClick={(e) => {
                  openViewer(15);
                }}
                className="InFifteenImg  img-zoom"
              ></div>
            </Grid> */}
            <Grid
              item
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
              className="gal-img"
              mt={"10px"}
            >
              <div
                onClick={(e) => {
                  openViewer(15);
                }}
                className="InSixteenImg  img-zoom"
              ></div>
            </Grid>
            <Grid
              item
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
              className="gal-img"
              mt={"10px"}
            >
              <div
                onClick={(e) => {
                  openViewer(16);
                }}
                className="InseventeenImg  img-zoom"
              ></div>
            </Grid>
            <Grid
              item
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={12}
              className="gal-img"
              mt={"10px"}
            >
              <div
                onClick={(e) => {
                  openViewer(17);
                }}
                className="InEightteenImg  img-zoom"
              ></div>
            </Grid>
          </Grid>
        </AnimateFromDown>
      </Paper>
    </Paper>
  );
};

export default InvernessGalaryNew;

const ViewTextStyle = {
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "36px",
  lineHeight: "43px",
  textAlign: "center",
  textTransform: "uppercase",
  color: "#333333",
};
