import React from "react";
import { Paper, Grid, Typography, Box } from "@mui/material";
import { Link } from "react-router-dom";
import AnimateFromDown from "../../components/AnimateFromDown";
import SavvyLogo from "../../assets/Images/Medhaansh/savvylogo.jpg";
import MLogo from "../../assets/Images/FooterImg/Logo.png";

import "./Style.css";

const OurPartners = () => {
  return (
    <Paper
      elevation={0}
      sx={{
        bgcolor: "#f9f9f9",
        width: "100%",
        mt: { xl: 0, lg: 0, md: 0, sm: 6, xs: 6 },
      }}
    >
      <AnimateFromDown>
        <Grid container pt={5}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Typography sx={MainTitleStyle}>
              <span style={{ color: "rgba(0, 0, 0, 0.4)", fontWeight: 500 }}>
                OUR
              </span>{" "}
              TEAM{" "}
            </Typography>
          </Grid>
        </Grid>
      </AnimateFromDown>
      {/* <Grid container mt={"10px"}>
        <Grid
          item
          xl={5}
          lg={5}
          md={5}
          sm={11}
          xs={11}
          sx={{ marginTop: "60px", mx: "auto" }}
        >
          <Grid container>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12} sx={{mb:{xl:'0px',lg:'0px',md:'0px',sm:'25px',xs:'30px'}}}>
              <Box sx={{ width: "100px", height: "100%", mx: "auto" }}>
                <img src={SavvyLogo} alt="SavvyLogoimg" width="100%" />
              </Box>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12} sx={{mb:{xl:'0px',lg:'0px',md:'0px',sm:'25px',xs:'30px'}}}>
              <Box sx={{ width: "220px", height: "100%", mx: "auto" }}>
                <img src={MLogo} alt="SavvyLogoimg" width="100%" />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid> */}
      <Paper
        elevation={0}
        sx={{
          height: "100%",
          minHeight: "400px",
          pt: 0,
          display: "grid",
          placeItems: "center",
          bgcolor: "transparent",
          margintTop: "5px",
        }}
      >
        <AnimateFromDown>
          <Grid
            container
            sx={{
              width: "100%",
              mx: "auto",
              mt: "20px",
            }}
          >
            {/* <Grid item xl={6} lg={6} md={6} sm={12} xs={12} sx={{ mx: "auto" }}>
              <Box
                sx={{
                  width: "85%",
                  maxWidth: "500px",
                  textAlign: "center",
                  mx: "auto",
                }}
              >
                <img
                  delayTime={2000}
                  src={HirenPatelArchitects}
                  width="200px"
                  alt="PrabhakarImg"
                />
                <Typography sx={TitleStyle}>Krishnakant Gandhi</Typography>
                <Typography sx={TitleBottomStyle}>--</Typography>
                <h1 className="ourArchitecText sequence fadeInBottom">
                  Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                  amet sint. Velit officia consequat duis
                </h1>
                <Link to="/" style={{ textDecoration: "none" }}>
                  <h1 className="LinkStyleText">https://www.hpa.co.in/</h1>
                </Link>
                <Link to="/" style={{ textDecoration: "none" }}>
                  <h1 className="LinkStyleText">www.link.com</h1>
                </Link>
              </Box>
            </Grid> */}
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12} sx={{ mx: "auto" }}>
              <Box
                sx={{
                  width: "85%",
                  maxWidth: "500px",
                  textAlign: "center",
                  mx: "auto",
                }}
              >
                {/* <img
                  delayTime={2000}
                  src={HirenPatelArchitects}
                  width="200px"
                  alt="PrabhakarImg"
                /> */}
                <Typography sx={TitleStyle}>Siddharth Bagadia</Typography>
                <Typography sx={TitleBottomStyle}>Unispace</Typography>
                <h1 className="ourArchitecText sequence fadeInBottom">
                  Siddharth Bagadia is the Chairman of the Unispace Group, whose
                  sole focus is to curate spaces that spread happiness.
                </h1>
                {/* <Link to="/" style={{ textDecoration: "none" }}>
                  <h1 className="LinkStyleText">https://www.hpa.co.in/</h1>
                </Link> */}
                {/* <a
                  href="https://www.unispace.in/"
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  <h1 className="LinkStyleText">www.unispace.in</h1>
                </a> */}
              </Box>
            </Grid>
            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              sx={{ mx: "auto", mt: { xl: 0, lg: 0, md: 0, sm: 5, xs: 5 } }}
            >
              <Box
                sx={{
                  width: "85%",
                  maxWidth: "500px",
                  textAlign: "center",
                  mx: "auto",
                }}
              >
                {/* <img
                  delayTime={2000}
                  src={HirenPatelArchitects}
                  width="200px"
                  alt="PrabhakarImg"
                /> */}
                <Typography sx={TitleStyle}>Chinmay Shah</Typography>
                <Typography sx={TitleBottomStyle}>
                  Divine Corporation
                </Typography>
                <h1 className="ourArchitecText sequence fadeInBottom">
                  Chinmay Shah is the Director and youth wing president GIHED,
                  Ahmedabad & Devine Corporation having 35+ years experience in
                  Commercial & Residential real estate.
                </h1>
                {/* CREDAI */}
                {/* <Link to="/" style={{ textDecoration: "none" }}>
                  <h1 className="LinkStyleText">https://www.hpa.co.in/</h1>
                </Link> */}
                {/* <a
                  href="https://www.unispace.in/"
                  target="_blank"
                  style={{ textDecoration: "none", marginBottom: "10px" }}
                >
                  <h1 className="LinkStyleText">www.unispace.in</h1>
                </a> */}
              </Box>
            </Grid>
          </Grid>
        </AnimateFromDown>
      </Paper>
    </Paper>
  );
};

export default OurPartners;

const TitleStyle = {
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: { xl: "28px", lg: "28px", md: "26px", sm: "24px", xs: "22px" },
  lineHeight: "34px",
  textAlign: "center",
  letterSpacing: "0.04em",
  textTransform: "uppercase",
  color: "#333333",
};

const TitleBottomStyle = {
  mt: "10px",
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "14px",
  lineHeight: "21px",
  textAlign: "center",
  letterSpacing: "0.04em",
  textTransform: "uppercase",
  color: "#4F4F4F",
  animation: "slide-up 1.1s ease-in-out",
  "@keyframes slide-up": {
    "0%": {
      opacity: 0.2,
      lineHeight: "26px",
    },
    "100%": {
      opacity: 1,
      lineHeight: "21px",
    },
  },
};

const BottomText = {
  mt: 1,
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: { xs: "14px", sm: "14px", md: "13px", lg: "13px", xl: "13px" },
  lineHeight: "24px",
  textAlign: "center",
  color: "#808186",
  animation: "slide-up 1.1s ease-in-out",
  "@keyframes slide-up": {
    "0%": {
      opacity: 0.2,
      lineHeight: "28px",
    },
    "100%": {
      opacity: 1,
      lineHeight: "24px",
    },
  },
};

const LinkTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: { xl: "14px", lg: "14px", md: "12px", sm: "12px", xs: "12px" },
  lineHeight: "31px",
  textAlign: "center",
  letterSpacing: "0.04em",
  color: "#5392BF",
  mt: 1,
};

const MainTitleStyle = {
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: { xl: "36px", lg: "36px", md: "34px", sm: "32px", xs: "30px" },
  lineHeight: "43px",
  color: "#333333",
  textAlign: "center",
  textTransform: "uppercase",
};

const MainTitleStyleTwo = {
  mt: "40px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: { xl: "40px", lg: "38px", md: "34px", sm: "32px", xs: "30px" },
  lineHeight: "43px",
  color: "#333333",
  textAlign: "center",
  textTransform: "uppercase",
};
