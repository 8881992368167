import React from "react";
import { Paper, Grid, Typography, Box } from "@mui/material";

import LinkedInImg from "../../assets/Images/FooterImg/linkedin.png";
import FacebookImg from "../../assets/Images/FooterImg/facebook.png";
import InstagramImg from "../../assets/Images/FooterImg/Instagram.png";
import Logo from "../../assets/Images/FooterImg/Logo.png";
import "./footerimg.css";
import { useLocation } from "react-router-dom";

const Footer = () => {
  let location = useLocation();

  return (
    <div className="footerbgcolor">
      <Box sx={{ width: "100%", maxWidth: "2400px", mx: "auto" }}>
        <Grid container sx={{ bgcolor: "#F9F9F9" }}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Grid
              container
              sx={{
                height: "100%",
                maxHeight: "auto",
                width: {
                  xl: "95%",
                  lg: "95%",
                  md: "85%",
                  sm: "95%",
                  xs: "95%",
                },
                mx: "auto",
                pt: "20px",
                pb: "20px",
                bgcolor: "#F9F9F9",
              }}
            >
              <Grid
                item
                xl={4}
                lg={4}
                md={3}
                sm={12}
                xs={12}
                sx={{ bgcolor: "#F9F9F9" }}
              >
                <Box sx={{ mt: "-5px" }}>
                  <Box
                    sx={{
                      bgcolor: "#F9F9F9",
                      width: "130px",
                      height: "100%",
                    }}
                  >
                    <img src={Logo} width={"100%"} alt="Logo Img" />
                  </Box>
                </Box>
              </Grid>
              <Grid item xl={3} lg={3} md={3} sm={6} xs={6}>
                <Box sx={BoxNumFour}>
                  <Box
                    sx={{
                      display: {
                        xl: "flex",
                        lg: "flex",
                        md: "flex",
                        sm: "grid",
                        xs: "grid",
                      },
                      justifyContent: "space-between",
                      alignItems: "start",
                      width: "60%",
                      ml: "auto",
                      mr: { xs: "auto", sm: "auto", md: "0", lg: "0", xl: "0" },
                      mb: "1px",
                    }}
                  >
                    <a
                      href="https://www.facebook.com/profile.php?id=100086278551263"
                      target="_blank"
                    >
                      <img
                        src={FacebookImg}
                        alt=""
                        width="30px"
                        height="30px"
                      />
                    </a>
                    <a
                      href="https://instagram.com/_unispace__?igshid=YmMyMTA2M2Y="
                      target="_blank"
                    >
                      <img
                        src={InstagramImg}
                        alt=""
                        width="35px"
                        height="35px"
                      />
                    </a>
                    <a
                      href="https://www.linkedin.com/company/81496129/"
                      target="_blank"
                    >
                      <img
                        src={LinkedInImg}
                        alt=""
                        width="32px"
                        height="32px"
                      />
                    </a>
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                xl={5}
                lg={5}
                md={6}
                xs={6}
                sm={6}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  marginTop: "5px",
                  pl: 3,
                  display: {
                    xl: "flex",
                    lg: "flex",
                    md: "flex",
                    sm: "grid",
                    xs: "grid",
                  },
                  bgcolor: "#F9F9F9",
                }}
              >
                <Typography sx={pagetText}>GET IN TOUCH</Typography>
                <Typography sx={subText1}>+91 9898048844</Typography>
                <Typography sx={subText2}>info@unispace.in</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container sx={{ maxHeight: "100px" }}>
          <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
            <Paper sx={Disclaimer} elevation={0}>
              <Typography
                sx={{
                  fontSize: {
                    xl: "14px",
                    lg: "14px",
                    md: "12px",
                    sm: "8px",
                    xs: "8px",
                  },
                  marginTop: "10px",
                }}
              >
                <span
                  style={{ color: "#808186", fontFamily: "Playfair Display" }}
                >
                  *All the images on the website are not the real images they
                  are only for your reference
                </span>
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Footer;

const Disclaimer = {
  height: "80%",
  width: "100%",
  pt: "0px",
  pb: "10px",
  textAlign: "center",
  backgroundColor: "#F9F9F9",
};

const pagetText = {
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: { xl: "16px", lg: "16px", md: "14px", sm: "13px", xs: "12px" },
};

const BoxNumFour = {
  width: "95%",
  height: "100%",
  bgcolor: "#F9F9F9",
  maxWidth: "300px",
  marginTop: { xl: 0, lg: 0, md: 0, sm: 2, xs: 2 },
  marginLeft: { xl: -2, lg: -2, md: "auto", sm: 4, xs: 4 },
};

const subText1 = {
  fontSamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: { xl: "16px", lg: "16px", md: "15px", sm: "11px", xs: "11px" },
  textAlign: "center",
  marginLeft: "auto",
  marginRight: "auto",
};
const subText2 = {
  fontSamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: { xl: "16px", lg: "16px", md: "15px", sm: "11px", xs: "11px" },
  textAlign: "center",
};
