import React from "react";
import { Paper, Grid, Typography, Box } from "@mui/material";
import MozacImg from "../../assets/Images/AllohaHills/OurArchitecs/Mozac.png";
import StudioArchitec from "../../assets/Images/AllohaHills/OurArchitecs/StudioSangath.png";
import PrabhakarImg from "../../assets/Images/AllohaHills/OurArchitecs/Prabhakar.png";
import HirenPatel from "../../assets/Images/AllohaHills/OurArchitecs/HirenPatel.png";
import PDSLogo from "../../assets/Images/AllohaHills/OurArchitecs/PDS_logo_20220809 (1).jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import AnimateFromDown from "../../components/AnimateFromDown";
const OurArchitects = () => {
  return (
    <Paper elevation={0}>
      <Grid container pt={20}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <AnimateFromDown>
            <Typography sx={MainTitleStyle}>
              <span style={{ color: "rgba(0, 0, 0, 0.4)", fontWeight: 500 }}>
                OUR{" "}
              </span>
              ARCHITECTS
            </Typography>
          </AnimateFromDown>
        </Grid>
      </Grid>
      <Paper
        elevation={0}
        sx={{
          height: "100%",
          minHeight: "500px",
          pt: 10,
          display: "grid",
          placeItems: "center",
        }}
      >
        <Grid container>
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            sx={{ mx: "auto", mt: 3 }}
          >
            <Box
              sx={{
                width: "85%",
                maxWidth: "600px",
                textAlign: "center",
                mx: "auto",
              }}
            >
              <LazyLoadImage
                delayTime={2000}
                src={PrabhakarImg}
                width="100px"
                alt="PrabhakarImg"
              />
              <Typography sx={NameText}>Aniket Bhagwat</Typography>
              <Typography sx={ArchitecNameStyle}>Landscape India</Typography>
              <h1 className="ourArchitecText sequence fadeInBottom">
                Aniket Bhagwat is a landscape architect with M/s. Prabhakar B
                Bhagwat, a third-generation design studio founded in 1973 by his
                father, Professor Prabhakarm B. Bhagwat. He also guides research
                and outreach documents undertaken by the studio’s research arm.
              </h1>
              {/* <Typography sx={BottomText}>www.hirnePatel.com</Typography> */}
              {/* <Typography sx={BottomText}>www.link.com</Typography> */}
              <a
                href="https://www.landscapeindia.net/"
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <h1 className="LinkStyleText">www.landscapeindia.net</h1>
              </a>
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            width: "100%",
            mx: "auto",
          }}
        >
          <Grid
            item
            xl={2.4}
            lg={2.4}
            md={2.4}
            sm={2.4}
            xs={12}
            sx={{ mx: "auto", mt: 3 }}
          >
            <Box
              sx={{
                width: "85%",
                maxWidth: "600px",
                textAlign: "center",
                mx: "auto",
              }}
            >
              <LazyLoadImage
                delayTime={2000}
                src={HirenPatel}
                width="160px"
                alt="PrabhakarImg"
              />
              <Typography sx={NameText}>Hiren Patel</Typography>
              <Typography sx={ArchitecNameStyle}>HPA</Typography>
              <h1 className="ourArchitecText sequence fadeInBottom">
                HPA was founded by Ar. Hiren Patel in 1989, the young architect
                took upon himself to create a better world through his unique
                art and designs. HPA has been passionate about creating spaces
                that inspire and transform the way the world perceives art.
              </h1>
              {/* <Typography sx={BottomText}>https://www.hpa.co.in/</Typography> */}
              <a
                href="https://www.hpa.co.in/"
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <h1 className="LinkStyleText">www.hpa.co.in</h1>
              </a>
            </Box>
          </Grid>
          <Grid
            item
            xl={2.4}
            lg={2.4}
            md={2.4}
            sm={2.4}
            xs={12}
            sx={{ mx: "auto", mt: 3 }}
          >
            <Box
              sx={{
                width: "85%",
                maxWidth: "600px",
                textAlign: "center",
                mx: "auto",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <LazyLoadImage
                delayTime={2000}
                src={StudioArchitec}
                width="160px"
                style={{ marginLeft: "auto", marginRight: "auto" }}
                alt="PrabhakarImg"
              />
              <Typography sx={NameText}>Sonke Hoof</Typography>
              <Typography sx={ArchitecNameStyle}>Studio Sangath</Typography>
              {/* <Box
                sx={{
                  height: "250px",
                }}
              > */}
              <h1 className="ourArchitecText sequence fadeInBottom">
                Sonke Hoof is the director and one of the leading architects of
                Studio Sangath. He is a dynamic German Architect and creator
                currently residing in Ahmedabad and associated with the roots
                and growth of Studio Sangath.
              </h1>
              {/* </Box> */}
              {/* <Typography sx={BottomText}>www.hirnePatel.com</Typography> */}
              {/* <Typography sx={BottomText}>www.link.com</Typography> */}
              <a
                href="https://www.sangath.org/sonke-hoof/"
                target="_blank"
                style={{
                  textDecoration: "none",
                  textAlign: "flex-end",
                }}
              >
                <h1 className="LinkStyleText">www.sangath.org</h1>
              </a>
            </Box>
          </Grid>
          <Grid
            item
            xl={2.4}
            lg={2.4}
            md={2.4}
            sm={2.4}
            xs={12}
            sx={{ mx: "auto", mt: 3 }}
          >
            <Box
              sx={{
                width: "85%",
                maxWidth: "600px",
                textAlign: "center",
                mx: "auto",
              }}
            >
              <LazyLoadImage
                delayTime={2000}
                src={MozacImg}
                width="160px"
                alt="PrabhakarImg"
              />
              <Typography sx={NameText}>Dean D' Cruz</Typography>
              <Typography sx={ArchitecNameStyle}>Mozaic</Typography>

              <h1 className="ourArchitecText sequence fadeInBottom">
                Mozaic was set up in 2001 by Dean D’ Cruz with the purpose of
                integrating different design approaches to provide holistic
                services. They love the creative process that goes into design,
                the burst of insight and energy represented as confetti in their
                logo.
              </h1>
              {/* <Typography sx={BottomText}>www.hirnePatel.com</Typography> */}
              <a
                href="https://design.mozaic.in"
                style={{ textDecoration: "none", textAlign: "flex-end" }}
              >
                <h1 className="LinkStyleText">www.design.mozaic.in</h1>
              </a>
            </Box>
          </Grid>
          <Grid
            item
            xl={2.4}
            lg={2.4}
            md={2.4}
            sm={2.4}
            xs={12}
            sx={{ mx: "auto", mt: 3 }}
          >
            <Box
              sx={{
                width: "85%",
                maxWidth: "600px",
                textAlign: "center",
                mx: "auto",
              }}
            >
              <LazyLoadImage
                delayTime={2000}
                src={PDSLogo}
                width="98px"
                alt="PrabhakarImg"
              />
              <Typography sx={NameText}>Umesh Shrupali</Typography>
              <Typography sx={ArchitecNameStyle}>
                Place Design Studio
              </Typography>

              <h1 className="ourArchitecText sequence fadeInBottom">
                Umesh is an Architect & Urban Designer with over twenty-four
                years of progressively responsible experience including
                contribution to international repute, urban design in PPP
                projects. He also serves as a visiting faculty at CEPT
                University, Ahmedabad.
              </h1>
              {/* <Typography sx={BottomText}>www.hirnePatel.com</Typography> */}
              <a
                // href="https://design.mozaic.in"
                href="https://www.placedesignstudio.in"
                style={{ textDecoration: "none" }}
              >
                <h1 className="LinkStyleText">www.placedesignstudio.in</h1>
              </a>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Paper>
  );
};

export default OurArchitects;

const NameText = {
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 600,
  // fontSize: { xl: "24px", lg: "24px", md: "22px", sm: "20px", xs: "20px" },
  fontSize: { xl: "24px", lg: "24px", md: "22px", sm: "20px", xs: "20px" },
  lineHeight: "29px",
  color: "#333333",
  textAlign: "center",
  textTransform: "uppercase",
  mt: "20px",
  mb: "20px",
};


const MainTitleStyle = {
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: { xl: "36px", lg: "36px", md: "30px", sm: "26px", xs: "26px" },
  lineHeight: "43px",
  color: "#333333",
  textAlign: "center",
  textTransform: "uppercase",
};

const ArchitecNameStyle = {
  fontSamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "14px",
  lineHeight: "21px",
  textAlign: "center",
  color: "#4F4F4F",
  mt: "0px",
};
