import React, { useState } from "react";
import { Paper, Grid, Stack, Typography, Button, Box } from "@mui/material";
import ProjectCarousel from "../../Pages/Projects/ProjectCarousel";
import HomeCarousel from "../../components/carousel/HomeCarousel";
import HeroSection from "../../Pages/Projects/HeroSection";
import NextArr from "./next_arr.png";
import PreviousArr from "./previous_arr.png";
import Slider from "react-slick";
import AnimateFromDown from "../../components/AnimateFromDown";

import Cara1 from "./Cara1.jsx";

import "./Style.css";

import InversOne from "../../assets/Images/Inverness/inverse1.png";
import InversTwo from "../../assets/Images/Inverness/inverse3.png";

import Carousel, {
  CarouselItem,
} from "../../components/MainPageCarousel/carousel/Carousel";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className="PrevSidebarButtonWrapTwo">
      <button className="PrevSidebarButtonTwo" onClick={onClick}>
        {/* <MdArrowForwardIos style={{ color: "gray" }} size={50} /> */}
        <img src={PreviousArr} style={{ width: "100%", height: "auto" }} />
      </button>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className="PrevSidebarButtonWrapOne" style={{ zIndex: 5 }}>
      <button className="PrevSidebarButtonOne" onClick={onClick}>
        <img
          src={NextArr}
          style={{
            width: "100%",
            height: "auto",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        />
      </button>
    </div>
  );
}
const images = [
  {
    bgImage: "PreviouProjectDivOne",
  },
  {
    bgImage: "PreviouProjectDivTwo",
  },
  {
    bgImage: "PreviouProjectDivThree",
  },
  {
    bgImage: "PreviouProjectDivFour",
  },
  {
    bgImage: "PreviouProjectDivFive",
  },
  {
    bgImage: "PreviouProjectDivSix",
  },
  {
    bgImage: "PreviouProjectDivSeven",
  },
  {
    bgImage: "PreviouProjectDivEight",
  },
  {
    bgImage: "PreviouProjectDivNine",
  },
];

const pages = ["INVERNESS", "MEDHANSH", "INVERNESS"];
const extPages = ["@Aalloa Hills", "@Shela Extension", "@Chekhla"];
const PreviouProjects = () => {
  return (
    <Paper elevation={0}>
      {/* <AnimateFromDown> */}
      <Grid
        container
        sx={{
          height: "100%",
          postion: "releative",
          marginTop: "50px",
          width: "100%",
          mx: "auto",
        }}
      >
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{ mx: "auto", mb: "0px" }}
        >
          <Typography
            sx={{
              fontFamily: "Playfair Display",
              fontStyle: "normal",
              fontWeight: "300",
              fontSize: {
                xl: "37px",
                lg: "37px",
                md: "24px",
                sm: "24px",
                xs: "24px",
              },
              lineHeight: "44px",
              textAlign: "center",
              textTransform: "uppercase",
              color: "rgba(0, 0, 0, 0.4)",
            }}
          >
            Previous
            <span
              style={{
                fontFamily: "Playfair Display",
                color: "#000000",
                marginLeft: "10px",
                fontWeight: 800,
              }}
            >
              Projects
            </span>
          </Typography>
        </Grid>
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{ mx: "auto", marginTop: "20px" }}
        >
          <Slider
            dots={true}
            infinite={true}
            speed={500}
            customPaging={(i) => {
              return <CustomPagination data={i} />;
            }}
            dotsClass="slick-dotnew1 custom-indicatornewcaro1"
            autoplay={true}
            autoplaySpeed={5000}
            slidesToShow={1}
            draggable={false}
            nextArrow={<SampleNextArrow />}
            prevArrow={<SamplePrevArrow />}
          >
            {images.map((step, index) => (
              <Box
                sx={{
                  height: "100%",
                  minHeight: "100%",
                  width: "100%",
                  maxWidth: "100vw",
                  overflow: "hidden",
                }}
              >
                <div
                  style={{
                    height: "100%",
                    minHeight: "100%",
                    width: "100%",
                    maxWidth: "100%",
                    overFlow: "hidden",
                  }}
                >
                  <div className={step.bgImage}></div>
                </div>
              </Box>
            ))}
          </Slider>
        </Grid>
      </Grid>
      {/* </AnimateFromDown> */}
    </Paper>
  );
};
const CustomPagination = (props) => {
  return (
    <Button
      style={{
        width: "12px",
        minWidth: "12px",
        maxWidth: "12px",
        height: "0px",
        minHeight: "0px",
        maxHeight: "0px",
      }}
      onClick={props.onClick}
    ></Button>
  );
};
export default PreviouProjects;

// const StyleButton={}
