import React, { useState } from "react";
import { Typography, Box } from "@mui/material";
import AnimateFromDown from "../../components/AnimateFromDown";
import missiongrey from "./Images/missiongrey.png";
import missionred from "./Images/missionred.png";
import visionred from "./Images/visionred.png";
import visiongrey from "./Images/visiongrey.png";
import "./Css/vm.css";

const VisionMission = () => {
  const [over, setOver] = useState(false);
  const [over1, setOver1] = useState(false);

  return (
    <div
      style={{
        backgroundColor: "#F9F9F9",
        display: "flex",
        marginBottom: "20px",
        paddingBottom: "20px",
        paddingTop: "20px",
      }}
      className="vm-responsive"
    >
      <Box
        sx={{
          width: {
            xl: "65%",
            lg: "70%",
            md: "70%",
            sm: "70%",
            xs: "80%",
          },
          maxWidth: "400px",
          maxHeight: "100%",
          mx: "auto",

          px: "10px",
          bgcolor: "#fff",
          boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px;",
        }}
        className="hover-shadow"
        sxs={{ width: "80%", mx: "auto" }}
      >
        <Box
          sx={{
            width: {
              xl: "120px",
              lg: "120px",
              md: "120px",
              sm: "120px",
              xs: "120px",
            },
            paddingTop: "20px",
            mx: "auto",
          }}
        >
          <div
            onMouseOver={() => setOver1(true)}
            onMouseOut={() => setOver1(false)}
          >
            <img
              src={over1 ? visiongrey : visionred}
              alt="eng"
              style={TitleText1}
            />
          </div>
        </Box>
        <Typography sx={TitleStyle}>Vision</Typography>
        <AnimateFromDown>
          <h1
            className="ourProjectText sequence "
            style={{
              fontFamily: "Playfair Display",
            }}
          >
            Our vision is to be the most trusted name in curating premium unique
            spaces that initiate ripples of happiness and joyful living while
            consistently delivering the best possible service to our clients.
            Trust, Truth & transparency is embedded in our ethos
          </h1>
        </AnimateFromDown>
      </Box>

      <Box
        sx={{
          width: {
            xl: "65%",
            lg: "70%",
            md: "70%",
            sm: "70%",
            xs: "80%",
          },
          maxWidth: "400px",
          maxHeight: "100%",
          mx: "auto",
          px: "10px",
          bgcolor: "#fff",
          boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px;",
        }}
        className="hover-shadow"
        sxs={{ width: "80%", mx: "auto" }}
      >
        <Box
          sx={{
            width: {
              xl: "120px",
              lg: "120px",
              md: "120px",
              sm: "120px",
              xs: "120px",
            },
            paddingTop: "20px",
            mx: "auto",
          }}
        >
          <div
            onMouseOver={() => setOver(true)}
            onMouseOut={() => setOver(false)}
          >
            <img
              src={over ? missiongrey : missionred}
              alt="eng"
              style={TitleText1}
            />
          </div>
        </Box>
        <Typography sx={TitleStyle}>Mission</Typography>
        <AnimateFromDown>
          <h1
            className="ourProjectText sequence "
            style={{
              fontFamily: "Playfair Display",
            }}
          >
            Our mission is to provide exceptional abodes & unparalleled service
            through teamwork, integrity, and innovation & to continually improve
            to meet the changing and unique needs of our customers.
          </h1>
        </AnimateFromDown>
      </Box>
    </div>
  );
};

export default VisionMission;

const TitleText1 = {
  height: "100px",
  width: "auto",
  marginTop: "20px",
  "&:hover": {
    color: "gray",
  },
};

const TitleStyle = {
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "25px",
  lineHeight: "32px",
  color: "#222222",
  marginTop: "20px",
  marginBottom: "20px",
  textAlign: "center",
};
