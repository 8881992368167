import React from "react";
import { Paper, Grid, Typography, Box } from "@mui/material";
// import AllohaIntroImg from "../../assets/Images/AllohaHills/AllohaIntro.png";
// import Carousel, { CarouselItem } from "../../components/carousel/Carousel";
import AnimateFromDown from "../../components/AnimateFromDown";
import "./Style.css";
// import { LazyLoadImage } from "react-lazy-load-image-component";
const AalloaIntro = () => {
  return (
    <Paper elevation={0}>
      <Paper
        sx={{
          height: "100%",
          minHeight: "750px",
          display: "grid",
          placeItems: "center",
          py: "2%",
        }}
        elevation={0}
      >
        <AnimateFromDown>
          <Grid
            container
            sx={{
              py: "20px",
            }}
          >
            <Grid item xl={6} lg={6} md={5.5} sm={12} xs={12}>
              <Box sx={{ width: "90%", mx: "auto" }}>
                <Typography sx={TitleStyle}>Inverness</Typography>
                <Typography
                  sx={{
                    ...TitleStyle,
                    fontSize: {
                      xl: "20px",
                      lg: "20px",
                      md: "20px",
                      sm: "20px",
                      xs: "20px",
                    },
                  }}
                >
                  <span style={{ color: "rgba(0, 0, 0, 0.4)" }}>
                    @Aalloa HILLS
                  </span>
                </Typography>
                <Typography sx={TitleBottomText}>
                  Capture Your Senses
                </Typography>
                <Typography sx={{ ...TitleChildStyle }}>
                  The 117 acres of Inverness are rich with the sound of rustling
                  leaves, breeze that whistles through the grasses and birds
                  singing a merry symphony. The design carefully assesses the
                  land and creates an experience where while traversing through
                  it one is reminded of forest trails, and not roads of a
                  development. The Sun and the changing Sky are the actors, who
                  cast shadows, or highlight the flora. The Jungle pavilion in
                  the club is a glass structure with 360 degree views, for you
                  to have a snack or read or work with the mind at peace.
                </Typography>
                <Typography sx={TitleChildStyle}>
                  Inverness Forest Club houses are very carefully curated so
                  that the houses will overlook calm lakes, or the wild flowers
                  expanses, or grass lands that ripple with the breeze. And how
                  can we forget our four legged friends, here they have a place
                  that is safe, and can run about and play with you in a
                  specially designed Dog park. Then there are special trails for
                  bird watchers, and places to set a telescope and see the night
                  sky while drinking hot cocoa.
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xl={6}
              lg={6}
              md={6.5}
              sm={12}
              xs={12}
              sx={{ textAlign: "right" }}
            >
              <Box
                sx={{
                  width: "100%",
                  maxWidth: "597px",
                  ml: "auto",
                  mr: "auto",
                  mt: {
                    xl: "0px",
                    lg: "0px",
                    md: "20px",
                    sm: "20px",
                    xs: "20px",
                  },
                }}
              >
                <div className="AllohaIntroImgTwo  img-zoom"></div>
                <Typography
                  sx={{
                    ...TitleStyle,

                    fontSize: {
                      xl: "18px",
                      lg: "18px",
                      md: "18px",
                      sm: "18px",
                      xs: "18px",
                    },
                    textAlign: "center",
                  }}
                >
                  <span style={{ color: "rgba(0, 0, 0, 0.4)" }}>
                    Inverness 360
                  </span>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </AnimateFromDown>
      </Paper>
    </Paper>
  );
};

export default AalloaIntro;

const TitleStyle = {
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "32px",
  lineHeight: "45px",
  color: "#333333",
  textAlign: "left",
  textTransform: "uppercase",
};

const TitleChildStyle = {
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: { xl: "16px", lg: "16px", md: "14px", sm: "14px", xs: "14px" },
  mt: 2,
  lineHeight: { xl: "26px", lg: "24px", md: "22px", sm: "22px", xs: "22px" },
  color: "#808186",
  textAlign: "Justify",
  animation: "slide-up 1.3s ease-in-out",
  "@keyframes slide-up": {
    "0%": {
      opacity: 0.2,
      lineHeight: "30px",
    },
    "100%": {
      opacity: 1,
      lineHeight: "26px",
    },
  },
};

const TitleBottomText = {
  // fontFamily: "Kalam, cursive",
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 500,
  mt: 1,
  mb: 1,
  fontSize: { xl: "21px", lg: "21px", md: "20px", sm: "20px", xs: "18px" },
  lineHeight: "25px",
  textAlign: "left",
  color: "#252733",
};
