import React from "react";
import { Link } from "react-router-dom";

import inverse1 from "../../assets/Images/Medhaansh/19_Club_house_Secor_A_new.jpg";
import "./Caroso.css";
const images = [
  {
    photo: inverse1,
    label: "image1",
    type: "",
    placeName: "Inverness",
    extension: "@Chekhla",
    pageLink: "/inverness",
  },
];

const Caraousel4 = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 300,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1124,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div style={{ width: "100%", marginLeft: "auto", marginRight: "auto" }}>
        {images.map((step) => (
          <div key={step.label}>
            <div className="hoverImg">
              <img src={step.photo} alt={step.label} className="caroImg" />
              <Link to="/inverness">
                <div class="layer-one-pic">
                  <h5 style={{ fontFamily: "Playfair Display" }}>
                    {step.type}
                  </h5>
                  <h3 style={{ fontFamily: "Playfair Display" }}>
                    <Link
                      style={{ textDecoration: "none", color: "white" }}
                      to={step.pageLink}
                    >
                      {step.placeName}{" "}
                    </Link>
                    <span className="span">{step.extension}</span>{" "}
                  </h3>
                </div>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Caraousel4;
