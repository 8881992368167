import React, { useState } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";

import AllohaIntro from "../../assets/Images/AllohaHills/g/m_5.jpg";
import "./Caroso.css";
import NextArr from "../../components/CarouselNew/next_arr.png";
import PreviousArr from "../../components/CarouselNew/previous_arr.png";
import med1 from "../../assets/Images/Medhaansh/Rect5.jpg";

const images = [
  {
    photo: AllohaIntro,
    label: "image1",
    placeName: "Inverness",
    extension: "@Aalloa Hills",
    pageLink: "/allohaHills",
  },
  {
    photo: med1,
    label: "image2",
    placeName: "Medhaansh",
    extension: "@shela extension",
    pageLink: "/medhaansh",
  },
];

const Caraousel1 = () => {
  const settings = {
    infinite: true,
    speed: 300,
    autoplay: true,
    autoplay: true,
    slidesToShow: 1.1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1124,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div
        className="CaroAll"
        style={{ width: "100%", marginLeft: "auto", marginRight: "auto" }}
      >
        <Slider {...settings}>
          {images.map((step) => (
            <div key={step.label}>
              <div className="hoverImg">
                <img
                  src={step.photo}
                  alt={step.label}
                  className="carouselProjectImageHeight"
                />
                <div class="layer-one-pic">
                  <Link
                    style={{ textDecoration: "none", color: "white" }}
                    to={step.pageLink}
                  >
                    <h5 style={{ fontFamily: "Playfair Display" }}>
                      {step.type}
                    </h5>
                    <h3 style={{ fontFamily: "Playfair Display" }}>
                      {step.placeName} {"  "}
                      <span className="span">{step.extension}</span>{" "}
                    </h3>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
};
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className="HeroSidebarButtonWrapTwo">
      <button className="HeroSidebarButtonTwo" onClick={onClick}>
        <img src={PreviousArr} style={{ width: "100%", height: "auto" }} />
      </button>
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className="HeroSidebarButtonWrapOne" style={{ zIndex: 5 }}>
      <button
        className="HeroSidebarButtonOne"
        onClick={onClick}
        style={{ textAlign: "center" }}
      >
        <img
          src={NextArr}
          style={{
            width: "100%",
            height: "auto",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        />
      </button>
    </div>
  );
}

export default Caraousel1;
