import React from "react";
import { Paper, Grid, Typography, Box } from "@mui/material";
// import GrayBorder from "../../components/GrayBorder";
// import ImgOne from "../../assets/Images/AllohaHills/One.png";
// import ImgTwo from "../../assets/Images/AllohaHills/Two.png";
// import ImgThree from "../../assets/Images/AllohaHills/Three.png";
// import ImgFour from "../../assets/Images/AllohaHills/Four.png";
// import ImgFive from "../../assets/Images/AllohaHills/Five.png";
import React1 from "../../Pages/AllohaHillsPages/AllohaSense/birds.png";
import React2 from "../../Pages/AllohaHillsPages/AllohaSense/flowers.png";
import React3 from "../../Pages/AllohaHillsPages/AllohaSense/sun.png";
import React4 from "../../Pages/AllohaHillsPages/AllohaSense/seenew.jpg";
import React5 from "../../Pages/AllohaHillsPages/AllohaSense/orange.png";
// import React6 from "../../assets/Images/AllohaHills/Rect6.png";
// import React7 from "../../assets/Images/AllohaHills/Rect7.png";
// import React8 from "../../assets/Images/AllohaHills/Rect8.png";
// import senseImg from "../../Pages/AllohaHillsPages/AllohaSense/senseImg.png";
// import senseImg1 from "../../Pages/AllohaHillsPages/AllohaSense/senseImg1.png";
// import senseImg2 from "../../Pages/AllohaHillsPages/AllohaSense/senseImg2.png";
// import senseImg3 from "../../Pages/AllohaHillsPages/AllohaSense/senseImg3.png";
// import senseImg4 from "../../Pages/AllohaHillsPages/AllohaSense/senseImg4.png";
import AnimateFromDown from "../../components/AnimateFromDown";
import "../../Pages/AllohaHillsPages/AllohaSense/AllohSense.css";
import "./Style.css";
const GalleryWIthInfo = () => {
  return (
    <>
      <Paper
        elevation={0}
        sx={{
          display: {
            xl: "inline",
            lg: "inline",
            md: "inline",
            sm: "none",
            xs: "none",
          },
        }}
      >
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{
            marginLeft: "auto",
            marginRight: "auto",
            width: "100%",
            zIndex: "0",
          }}
        >
          <Grid container textAlign="center" mt={1} mb={3}>
            <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
              <Typography sx={TitleStyle1}>
                Inverness shall &nbsp;
                <span
                  style={{
                    color: "rgba(0, 0, 0, 0.4)",
                    fontFamily: "Playfair Display",
                  }}
                >
                  EVOKE{" "}
                </span>
                your five senses{" "}
                <span style={{ color: "rgba(0, 0, 0, 0.4)" }}></span>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Paper
          sx={{
            height: "100%",
            minHeight: "450px",
            display: "grid",
            placeItems: "center",
          }}
          elevation={0}
        >
          <AnimateFromDown>
            <Grid
              container
              sx={{
                py: "10px",
              }}
            >
              <Grid
                item
                xl={5}
                lg={5}
                md={5}
                sm={12}
                xs={12}
                sx={{ display: "grid", placeItems: "center" }}
              >
                <Box
                  sx={{
                    width: {
                      xl: "80%",
                      lg: "85%",
                      md: "85%",
                      sm: "90%",
                      xs: "90%",
                    },
                    mx: "auto",
                  }}
                >
                  <Typography sx={TitleStyle}>SEE</Typography>

                  <Typography sx={TextThreeStyle}>
                    SEE THE PURITY OF MOTHER NATURE
                  </Typography>
                  {/* <GrayBorder sx={{ width: "90%", mx: "auto" }} />
                <Typography sx={TextThreeStyle}>
                  Birds rise in joyful chorus, belting out melodious tunes where
                  the squirrels gossip and giggle, making the environment more
                  pleasant and flutter friendly. Further the familiar rhythm of
                  cricket’s chirps at the night creating the soundtrack for each
                  moment.
                </Typography> */}
                </Box>
              </Grid>
              <Grid
                item
                xl={7}
                lg={7}
                md={7}
                sm={12}
                xs={12}
                sx={{ textAlign: "right" }}
              >
                <Box
                  sx={{
                    width: "95%",
                    maxWidth: "700px",
                    ml: {
                      xl: "Auto",
                      lg: "Auto",
                      md: "Auto",
                      sm: "auto",
                      xs: "auto",
                    },
                    mr: { xl: 3, lg: 3, md: 3, sm: "auto", xs: "auto" },
                  }}
                >
                  <div className="hoverImgs">
                    <img
                      src={React3}
                      alt="InvernessA"
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                </Box>

                {/* <div className="ImageWRapDivThree sequence fadeInBottom"></div> */}
                {/* >>>>>>> dev */}
              </Grid>
            </Grid>
          </AnimateFromDown>
        </Paper>
        <Paper
          sx={{
            height: "100%",
            minHeight: "425px",
            display: "grid",
            placeItems: "center",
          }}
          elevation={0}
        >
          <AnimateFromDown>
            <Grid
              container
              sx={{
                py: "10px",
              }}
            >
              <Grid item xl={7} lg={7} md={7} sm={12} xs={12}>
                <Box
                  sx={{
                    width: "90%",
                    maxWidth: "700px",
                    ml: {
                      xl: "auto",
                      lg: "auto",
                      md: "auto",
                      sm: "auto",
                      xs: "auto",
                    },
                    mr: {
                      xl: "auto",
                      lg: "auto",
                      md: "auto",
                      sm: "auto",
                      xs: "auto",
                    },
                  }}
                >
                  <div className="hoverImgs">
                    <img
                      src={React2}
                      alt="InvernessA"
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                </Box>
                {/* <div className="ImageWRapDivTwo sequence fadeInBottom"></div> */}
                {/* >>>>>>> dev */}
              </Grid>
              <Grid
                item
                xl={5}
                lg={5}
                md={5}
                sm={12}
                xs={12}
                sx={{ display: "grid", placeItems: "center" }}
              >
                <Box
                  sx={{
                    width: {
                      xl: "80%",
                      lg: "85%",
                      md: "85%",
                      sm: "90%",
                      xs: "90%",
                    },
                    mx: "auto",
                  }}
                >
                  <Typography sx={TitleStyle}>SMELL</Typography>

                  <Typography sx={TextThreeStyle} letterSpacing={0}>
                    SMELL THE FRAGRANCE OF UNREFINED SERENITY
                  </Typography>
                  {/* <GrayBorder sx={{ width: "90%", mx: "auto" }} />
                <Typography sx={TextThreeStyle}>
                  The air is filled with a beautiful fragrance of all the
                  flowers, combined for heavenly senses, and the pop up freshly
                  cut grass, smells so fresh and comforting, for every summer
                  that comes around, there is only beauty to be found going from
                  flower-to-flower.
                </Typography> */}
                </Box>
              </Grid>
            </Grid>
          </AnimateFromDown>
        </Paper>
        <Paper
          sx={{
            height: "100%",
            minHeight: "450px",
            display: "grid",
            placeItems: "center",
            width: "100%",
          }}
          elevation={0}
        >
          <AnimateFromDown>
            <Grid
              container
              sx={{
                py: "10px",
              }}
            >
              <Grid
                item
                xl={5}
                lg={5}
                md={5}
                sm={12}
                xs={12}
                sx={{ display: "grid", placeItems: "center" }}
              >
                <Box
                  sx={{
                    width: {
                      xl: "80%",
                      lg: "85%",
                      md: "85%",
                      sm: "90%",
                      xs: "90%",
                    },
                    mx: "auto",
                  }}
                >
                  <Typography sx={TitleStyle}>HEAR</Typography>

                  <Typography sx={TextThreeStyle}>
                    HEAR THE MUSIC OF YOUR DREAM LIFESTYLE
                  </Typography>
                  {/* <GrayBorder sx={{ width: "90%", mx: "auto" }} />
                <Typography sx={TextThreeStyle}>
                  A walk where sunlight sets the nature leaves a glow, weaving
                  open paths to dapple golden light on all below. Capturing
                  life's beauty and its miracle, as we listen with our hearts
                  while walking among the trees. Seeing the beauty carried on
                  with a fresh breeze, to blend with the nature spirit so it
                  will beguile
                </Typography> */}
                </Box>
              </Grid>
              <Grid
                item
                xl={7}
                lg={7}
                md={7}
                sm={12}
                xs={12}
                sx={{ textAlign: "right" }}
              >
                <Box
                  sx={{
                    width: "100%",
                    maxWidth: "700px",
                    ml: {
                      xl: "Auto",
                      lg: "Auto",
                      md: "Auto",
                      sm: "auto",
                      xs: "auto",
                    },
                    mr: { xl: 3, lg: 3, md: 3, sm: "auto", xs: "auto" },
                  }}
                >
                  <div className="hoverImgs">
                    <img
                      src={React1}
                      alt="InvernessA"
                      style={{
                        height: "100%",
                        width: "100%",
                      }}
                    />
                  </div>
                </Box>

                {/* <div className="ImageWRapDivOne sequence fadeInBottom"></div> */}
                {/* >>>>>>> dev */}
              </Grid>
            </Grid>
          </AnimateFromDown>
        </Paper>

        <Paper
          sx={{
            height: "100%",
            minHeight: "450px",
            display: "grid",
            placeItems: "center",
          }}
          elevation={0}
        >
          <AnimateFromDown>
            <Grid
              container
              sx={{
                py: "20px",
              }}
            >
              <Grid
                item
                xl={7}
                lg={7}
                md={7}
                sm={12}
                xs={12}
                sx={{ textAlign: "left" }}
              >
                {/* {/* <<<<<<< HEAD */}
                <Box
                  sx={{
                    width: "95%",
                    maxWidth: "700px",
                    mx: "auto",
                  }}
                >
                  <div className="hoverImgs">
                    <img
                      src={React4}
                      alt="InvernessA"
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                </Box>
                {/* ======= */}
                {/* <div className="ImageWRapDivFour sequence fadeInBottom"></div> */}
                {/* >>>>>>> dev */}
              </Grid>
              <Grid
                item
                xl={5}
                lg={5}
                md={5}
                sm={12}
                xs={12}
                sx={{ display: "grid", placeItems: "center" }}
              >
                <Box
                  sx={{
                    width: {
                      xl: "80%",
                      lg: "85%",
                      md: "85%",
                      sm: "90%",
                      xs: "90%",
                    },
                    mx: "auto",
                  }}
                >
                  <Typography sx={TitleStyle}>Touch</Typography>

                  <Typography sx={TextThreeStyle}>
                    LET’S GET IN TOUCH WITH OURSELVES
                  </Typography>
                  {/* <GrayBorder sx={{ width: "90%", mx: "auto" }} />
                <Typography sx={TextThreeStyle}>
                  Morning’s soft breeze, touching everything with such care.
                  De-stressing through the hands of nature’s touch, so
                  enchanting, unique and breathtaking. Restoring the mind with
                  happy thoughts.
                </Typography> */}
                </Box>
              </Grid>
            </Grid>
          </AnimateFromDown>
        </Paper>

        <Paper
          sx={{
            height: "100%",
            minHeight: "550px",
            display: "grid",
            placeItems: "center",
          }}
          elevation={0}
        >
          <AnimateFromDown>
            <Grid
              container
              sx={{
                py: "10px",
              }}
            >
              <Grid
                item
                xl={5}
                lg={5}
                md={5}
                sm={12}
                xs={12}
                sx={{ display: "grid", placeItems: "center" }}
              >
                <Box
                  sx={{
                    width: {
                      xl: "80%",
                      lg: "85%",
                      md: "85%",
                      sm: "90%",
                      xs: "90%",
                    },
                    mx: "auto",
                  }}
                >
                  <Typography sx={TitleStyle}>Taste</Typography>

                  <Typography sx={TextThreeStyle} letterSpacing={0}>
                    TASTE THE SWEETNESS OF THE LIFE YOU’VE EARNED
                  </Typography>
                  {/* <GrayBorder sx={{ width: "90%", mx: "auto" }} />
                <Typography sx={TextThreeStyle}>
                  Nostalgia Stories of Indian summer where tasty tangy smells of
                  summer draws from the colors and cultures of India to create a
                  unique taste through different shapes. With roots intertwined
                  with the relationship of love and seasons.
                </Typography> */}
                </Box>
              </Grid>
              <Grid
                item
                xl={7}
                lg={7}
                md={7}
                sm={12}
                xs={12}
                sx={{ textAlign: "right" }}
              >
                <Box
                  sx={{
                    width: "90%",
                    maxWidth: "700px",
                    ml: {
                      xl: "Auto",
                      lg: "Auto",
                      md: "Auto",
                      sm: "auto",
                      xs: "auto",
                    },
                    mr: { xl: 3, lg: 3, md: 3, sm: "auto", xs: "auto" },
                  }}
                >
                  <div className="hoverImgs">
                    <img
                      src={React5}
                      alt="InvernessA"
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                </Box>
              </Grid>
            </Grid>
          </AnimateFromDown>
          <Grid container textAlign="center" mt={1} mb={3}>
            <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
              {/* <Typography sx={TitleStyle1}>
                Inverness shall &nbsp;
                <span
                  style={{
                    color: "rgba(0, 0, 0, 0.4)",
                    fontFamily: "Playfair Display",
                    fontSize: "29px",
                  }}
                >
                  EVOKE{" "}
                </span>
                your five senses{" "}
                <span style={{ color: "rgba(0, 0, 0, 0.4)" }}></span>
              </Typography> */}
              {/* <Typography sx={TitleStyle1} mt={2}>
                &
              </Typography> */}
              <Typography sx={TitleStyle1} mt={2}>
                The sixth SHALL help you make &nbsp;
                <span
                  style={{
                    color: "rgba(0, 0, 0, 0.4)",
                    fontFamily: "Playfair Display",
                  }}
                >
                  THE RIGHT MOVE{" "}
                </span>
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Paper>
      {/* Mobile Version?  -------------------------------------------------------------------------------------------------*/}
      <Paper
        elevation={0}
        sx={{
          display: {
            xl: "none",
            lg: "none",
            md: "none",
            sm: "inline",
            xs: "inline",
          },
        }}
      >
        <Grid container textAlign="center" mt={1} mb={3}>
          <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
            <Typography sx={TitleStyle1}>
              Inverness shall &nbsp;
              <span
                style={{
                  color: "rgba(0, 0, 0, 0.4)",
                  fontFamily: "Playfair Display",
                  // fontSize: "29px",
                }}
              >
                EVOKE{" "}
              </span>
              your five senses{" "}
              <span style={{ color: "rgba(0, 0, 0, 0.4)" }}></span>
            </Typography>
            {/* <Typography sx={TitleStyle1} mt={2}>
                &
              </Typography> */}
            {/* <Typography sx={TitleStyle1} mt={2}>
              The sixth SHALL help u make &nbsp;
              <span
                style={{
                  color: "rgba(0, 0, 0, 0.4)",
                  fontFamily: "Playfair Display",
                }}
              >
                THE RIGHT MOVE{" "}
              </span>
            </Typography> */}
          </Grid>
        </Grid>
        <Paper
          sx={{
            height: "100%",
            mt: "30px",
          }}
          elevation={0}
        >
          <AnimateFromDown>
            <Grid container>
              <Box
                sx={{
                  width: {
                    xl: "80%",
                    lg: "85%",
                    md: "85%",
                    sm: "90%",
                    xs: "90%",
                  },
                  mx: "auto",
                }}
              >
                <Typography sx={TitleStyle}>SEE</Typography>

                <Typography sx={TextThreeStyle}>
                  SEE THE PURITY OF MOTHER NATURE
                </Typography>
                {/* <GrayBorder sx={{ width: "90%", mx: "auto" }} />
                <Typography sx={TextThreeStyle}>
                  Birds rise in joyful chorus, belting out melodious tunes where
                  the squirrels gossip and giggle, making the environment more
                  pleasant and flutter friendly. Further the familiar rhythm of
                  cricket’s chirps at the night creating the soundtrack for each
                  moment.
                </Typography> */}
              </Box>

              <Box
                sx={{
                  width: "100%",
                  maxWidth: "700px",
                  ml: {
                    xl: "Auto",
                    lg: "Auto",
                    md: "Auto",
                    sm: "auto",
                    xs: "auto",
                  },
                  mt: "8px",
                  mr: { xl: 3, lg: 3, md: 3, sm: "auto", xs: "auto" },
                }}
              >
                <div className="hoverImgs">
                  <img
                    src={React3}
                    alt="InvernessA"
                    style={{ width: "100%", height: "100%" }}
                  />
                </div>
              </Box>
            </Grid>
          </AnimateFromDown>
        </Paper>
        <Paper
          sx={{
            height: "100%",
            mt: "30px",
          }}
          elevation={0}
        >
          <AnimateFromDown>
            <Grid container>
              <Box
                sx={{
                  width: {
                    xl: "80%",
                    lg: "85%",
                    md: "85%",
                    sm: "90%",
                    xs: "90%",
                  },
                  mx: "auto",
                }}
              >
                <Typography sx={TitleStyle}>SMELL</Typography>

                <Typography sx={TextThreeStyle}>
                  SMELL THE FRAGRANCE OF UNREFINED SERENITY
                </Typography>
                {/* <GrayBorder sx={{ width: "90%", mx: "auto" }} />
                <Typography sx={TextThreeStyle}>
                  The air is filled with a beautiful fragrance of all the
                  flowers, combined for heavenly senses, and the pop up freshly
                  cut grass, smells so fresh and comforting, for every summer
                  that comes around, there is only beauty to be found going from
                  flower-to-flower.
                </Typography> */}
              </Box>

              <Box
                sx={{
                  width: "100%",
                  maxWidth: "700px",
                  ml: {
                    xl: "Auto",
                    lg: "Auto",
                    md: "Auto",
                    sm: "auto",
                    xs: "auto",
                  },
                  mt: "8px",
                  mr: { xl: 3, lg: 3, md: 3, sm: "auto", xs: "auto" },
                }}
              >
                <div className="hoverImgs">
                  <img
                    src={React2}
                    alt="InvernessA"
                    style={{
                      height: "100%",
                      width: "100%",
                    }}
                  />
                </div>
              </Box>
            </Grid>
          </AnimateFromDown>
        </Paper>
        <Paper
          sx={{
            height: "100%",
            width: "100%",
            mt: "8px",
          }}
          elevation={0}
        >
          <AnimateFromDown>
            <Grid container>
              <Box
                sx={{
                  width: {
                    xl: "80%",
                    lg: "85%",
                    md: "85%",
                    sm: "90%",
                    xs: "90%",
                  },
                  mx: "auto",
                }}
              >
                <Typography sx={TitleStyle}>HEAR</Typography>
                <Typography sx={TextThreeStyle}>
                  HEAR THE MUSIC OF YOUR DREAM LIFESTYLE
                </Typography>
                {/* <GrayBorder sx={{ width: "90%", mx: "auto" }} />
                <Typography sx={TextThreeStyle}>
                  A walk where sunlight sets the nature leaves a glow, weaving
                  open paths to dapple golden light on all below. Capturing
                  life's beauty and its miracle, as we listen with our hearts
                  while walking among the trees. Seeing the beauty carried on
                  with a fresh breeze, to blend with the nature spirit so it
                  will beguile
                </Typography> */}
              </Box>

              <Box
                sx={{
                  width: "100%",
                  maxWidth: "700px",
                  ml: {
                    xl: "Auto",
                    lg: "Auto",
                    md: "Auto",
                    sm: "auto",
                    xs: "auto",
                  },
                  mt: "8px",
                  mr: { xl: 3, lg: 3, md: 3, sm: "auto", xs: "auto" },
                }}
              >
                <div className="hoverImgs">
                  <img
                    src={React1}
                    alt="InvernessA"
                    style={{
                      height: "100%",
                      width: "100%",
                    }}
                  />
                </div>
              </Box>
            </Grid>
          </AnimateFromDown>
        </Paper>

        <Paper
          sx={{
            height: "100%",
            mt: "30px",
          }}
          elevation={0}
        >
          <AnimateFromDown>
            <Grid item sm={12} xs={12} sx={{ mt: "20px" }}>
              <Box
                sx={{
                  width: {
                    xl: "80%",
                    lg: "85%",
                    md: "85%",
                    sm: "90%",
                    xs: "90%",
                  },
                  mx: "auto",
                }}
              >
                <Typography sx={TitleStyle}>Touch</Typography>

                <Typography sx={TextThreeStyle}>
                  LET’S GET IN TOUCH WITH OURSELVES
                </Typography>
                {/* <GrayBorder sx={{ width: "90%", mx: "auto" }} />
                <Typography sx={TextThreeStyle}>
                  Morning’s soft breeze, touching everything with such care.
                  De-stressing through the hands of nature’s touch, so
                  enchanting, unique and breathtaking. Restoring the mind with
                  happy thoughts.
                </Typography> */}
              </Box>

              <Box
                sx={{
                  width: "100%",
                  maxWidth: "700px",
                  mx: "auto",
                  mt: "8px",
                }}
              >
                <div className="hoverImgs">
                  <img
                    src={React4}
                    alt="InvernessA"
                    style={{ width: "100%", height: "100%" }}
                  />
                </div>
              </Box>
            </Grid>
          </AnimateFromDown>
        </Paper>

        <Paper
          sx={{
            height: "100%",
            mt: "30px",
          }}
          elevation={0}
        >
          <AnimateFromDown>
            <Grid container>
              <Box
                sx={{
                  width: {
                    xl: "80%",
                    lg: "85%",
                    md: "85%",
                    sm: "90%",
                    xs: "90%",
                  },
                  mx: "auto",
                }}
              >
                <Typography sx={TitleStyle}>Taste</Typography>

                <Typography sx={TextThreeStyle}>
                  TASTE THE SWEETNESS OF THE LIFE YOU’VE EARNED
                </Typography>
              </Box>

              <Box
                sx={{
                  width: "100%",
                  maxWidth: "700px",
                  ml: {
                    xl: "Auto",
                    lg: "Auto",
                    md: "Auto",
                    sm: "auto",
                    xs: "auto",
                  },
                  mt: "8px",
                  mr: { xl: 3, lg: 3, md: 3, sm: "auto", xs: "auto" },
                }}
              >
                <div className="hoverImgs">
                  <img
                    src={React5}
                    alt="InvernessA"
                    style={{ width: "100%", height: "100%" }}
                  />
                </div>
              </Box>
            </Grid>
          </AnimateFromDown>
          <Grid container textAlign="center" mt={1} mb={3}>
            <Grid xl={12} lg={12} md={12} sm={12} xs={12} item>
              {/* <Typography sx={TitleStyle1}>
                Inverness shall &nbsp;
                <span
                  style={{
                    color: "rgba(0, 0, 0, 0.4)",
                    fontFamily: "Playfair Display",
                    fontSize: "29px",
                  }}
                >
                  EVOKE{" "}
                </span>
                your five senses{" "}
                <span style={{ color: "rgba(0, 0, 0, 0.4)" }}></span>
              </Typography> */}
              {/* <Typography sx={TitleStyle1} mt={2}>
                &
              </Typography> */}
              <Typography sx={TitleStyle1} mt={2}>
                The sixth SHALL help u make &nbsp;
                <span
                  style={{
                    color: "rgba(0, 0, 0, 0.4)",
                    fontFamily: "Playfair Display",
                  }}
                >
                  THE RIGHT MOVE{" "}
                </span>
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Paper>
    </>
  );
};

export default GalleryWIthInfo;

const TitleStyle = {
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: { xl: "33px", lg: "31px", md: "29px", sm: "26px", xs: "26px" },
  lineHeight: { xl: "25px", lg: "25px", md: "25px", sm: "0px", xs: "0px" },
  color: "#333333",
  textAlign: "left",
  textTransform: "uppercase",
  mt: "20px",
};

const TextThreeStyle = {
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: { xl: "17px", lg: "17px", md: "15px", sm: "14px", xs: "14px" },
  lineHeight: { xl: "25px", lg: "25px", md: "25px", sm: "25px", xs: "25px" },
  textAlign: "left",
  color: "#030303",
  opacity: 1,
  letterSpacing: "none",
  mt: "20px",
  ml: "1px",
};

const TitleStyle1 = {
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: { xl: "42px", lg: "42px", md: "35px", sm: "35px", xs: "22px" },
  lineHeight: { xl: "60px", lg: "60px", md: "55px", sm: "40px", xs: "24px" },
  color: "#333333",
  textAlign: "center",
  textTransform: "uppercase",
};
