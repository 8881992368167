import React from "react";
import { Paper, Grid, Typography, Box } from "@mui/material";
// import { Link } from "react-router";
import Kamal from "../../assets/Images/Inverness/Kamal.png";
import "./Style.css";
import Ujjval from "../../assets/Images/Inverness/Ujjval.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import AnimateFromDown from "../../components/AnimateFromDown";
const OurArchitects = () => {
  return (
    <Paper elevation={0}>
      <AnimateFromDown>
        <Grid container pt={5}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Typography sx={MainTitleStyle}>
              <span style={{ color: "rgba(0, 0, 0, 0.4)", fontWeight: 500 }}>
                OUR
              </span>{" "}
              ARCHITECTS{" "}
            </Typography>
          </Grid>
        </Grid>
      </AnimateFromDown>
      <Paper
        elevation={0}
        sx={{
          height: "100%",
          minHeight: "500px",
          pt: 10,
          display: "grid",
          placeItems: "center",
        }}
      >
        <Grid
          container
          sx={{
            width: "100%",
            mx: "auto",
          }}
        >
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <AnimateFromDown>
              <Box
                sx={{
                  width: "70%",
                  maxWidth: "600px",
                  textAlign: "center",
                  mx: "auto",
                  mt: { xl: 0, lg: 0, md: 0, sm: 3, xs: 3 },
                }}
              >
                <LazyLoadImage
                  delayTime={2000}
                  src={Kamal}
                  alt="PrabhakarImg"
                  width="180px"
                />
                <Typography sx={NameText}>Kamal Mangaldas </Typography>
                <Typography sx={ArchitecNameStyle}>
                  Kamal Mangaldas Architects{" "}
                </Typography>
                <h1 className="InvernessIntroText sequence fadeInBottom">
                  Kamal Mangaldas is one of the most renowned architects in
                  Gujarat. Being trained in the 1950’s under the International
                  Style, to him ornament was taboo and his education gave him no
                  understanding of it: according to him, buildings had to be
                  sleek and stark and titillating to the intellect.
                </h1>
                <a
                  href="www.kamalmangaldas.net"
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  <h1 className="LinkStyleText">www.kamalmangaldas.net</h1>
                </a>
              </Box>
            </AnimateFromDown>
          </Grid>
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            sx={{
              mt: { xl: 0, lg: 0, md: 0, sm: 8, xs: 8 },
            }}
          >
            <AnimateFromDown>
              <Box
                sx={{
                  width: "70%",
                  maxWidth: "600px",
                  textAlign: "center",
                  mx: "auto",
                }}
              >
                <LazyLoadImage
                  delayTime={2000}
                  effect="opacity"
                  src={Ujjval}
                  width="125px"
                  alt="PrabhakarImg"
                />
                <Typography sx={NameText}>Ujjval Fadia</Typography>
                <Typography sx={ArchitecNameStyle}>
                  Ujjval Fadia Architects & Interior Designers
                </Typography>
                <h1 className="InvernessIntroText sequence fadeInBottom">
                  Having practiced for nearly five decades now, the firm led by
                  Ujjval Fadia leads the thinking on Landscape Design,
                  Environmental Planning, and Master Planning in the country and
                  is highly regarded for the work it undertakes in Architecture
                  and Interior Design.
                </h1>
                <a
                  href="www.ujjvalfadia.com"
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  <h1 className="LinkStyleText">www.ujjvalfadia.com</h1>
                </a>
              </Box>
            </AnimateFromDown>
          </Grid>
        </Grid>
      </Paper>
    </Paper>
  );
};

export default OurArchitects;

const NameText = {
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: { xl: "24px", lg: "24px", md: "22px", sm: "20px", xs: "20px" },
  lineHeight: "34px",
  color: "#333333",
  textAlign: "center",
  textTransform: "uppercase",
  mt: "20px",
  mb: "20px",
};

const BottomText = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: { xl: "16px", lg: "16px", md: "15px", sm: "14px", xs: "14px" },
  lineHeight: "24px",
  color: "#808186",
  textAlign: "justify",
  animation: "slide-up 1.5s ease-in-out",
  "@keyframes slide-up": {
    "0%": {
      opacity: 0.2,
      lineHeight: "30px",
    },
    "100%": {
      opacity: 1,
      lineHeight: "24px",
    },
  },
};

const MainTitleStyle = {
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: { xl: "36px", lg: "36px", md: "30px", sm: "26px", xs: "26px" },
  lineHeight: "43px",
  color: "#333333",
  textAlign: "center",
  textTransform: "uppercase",
};

const ArchitecNameStyle = {
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "14px",
  lineHeight: "21px",
  textAlign: "center",
  color: "#4F4F4F",
  mt: "30px",
};
