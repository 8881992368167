import { Paper, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import MapImage from "../assets/Images/map.png";

const ImageMap = () => {
  return (
    <Paper elevation={0}>
      <Grid container pt={5}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Typography sx={MainTitleStyle}>
            <span style={{ color: "rgba(0, 0, 0, 0.4)", fontWeight: 500 }}>
              OUR
            </span>{" "}
            LOCATION{" "}
          </Typography>
          
        </Grid>
      </Grid>
      <Box sx={BoxWidth}>
        <img src={MapImage} alt="MapImage" width={"100%"} height={"100%"} />
      </Box>
    </Paper>
  );
};

export default ImageMap;

const BoxWidth = {
  mt: "50px",
  width: { xl: "400px", lg: "400px", md: "350px", sm: "300px", xs: "280px" },
  height: "auto",
  mx: "auto",
};

const MainTitleStyle = {
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: { xl: "36px", lg: "36px", md: "34px", sm: "32px", xs: "30px" },
  lineHeight: "43px",
  color: "#333333",
  textAlign: "center",
  textTransform: "uppercase",
};
