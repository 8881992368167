import { Typography } from "@mui/material";
import React from "react";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

export default function AmenitiesBox({ imageUrl, text }) {
  return (
    <div className="AmentiseOne">
      <LazyLoadImage
        delayTime={2000}
        effect="opacity"
        src={imageUrl}
        alt="OneImg"
        className="am-img"
        width="60px"
      />
      <div className="AmentiseOneHover">
        <Typography sx={OnHoverTextStyle}>{text}</Typography>
      </div>
    </div>
  );
}

const OnHoverTextStyle = {
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "18px",
  lineHeight: "30px",
  color: "#000",
  Opacity: "0.8",
  mt: 2,
  textAlign: "justify",
};
