import React from "react";
import { Paper, Grid, Typography, Box } from "@mui/material";
// import Prabhakar from "../../assets/Images/Inverness/Prabhakar.png";
// import Kamal from "../../assets/Images/Inverness/Kamal.png";
// import { Link } from "react-router-dom";
import HirenPatelArchitects from "../../assets/Images/Medhaansh/HirenPatel.png";
import "./Style.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

import AnimateFromDown from "../../components/AnimateFromDown";
const OurArchitects = () => {
  return (
    <Paper elevation={0} sx={{ bgcolor: "#f9f9f9", width: "100%" }}>
      <AnimateFromDown>
        <Grid container pt={5}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Typography sx={MainTitleStyle}>
              <span style={{ color: "rgba(0, 0, 0, 0.4)", fontWeight: 500 }}>
                OUR
              </span>{" "}
              ARCHITECTS{" "}
            </Typography>
          </Grid>
        </Grid>
      </AnimateFromDown>
      <Paper
        elevation={0}
        sx={{
          height: "100%",
          minHeight: "500px",
          pt: 8,
          display: "grid",
          placeItems: "center",
          bgcolor: "transparent",
        }}
      >
        <AnimateFromDown>
          <Grid
            container
            sx={{
              width: "100%",
              mx: "auto",
            }}
          >
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={{ mx: "auto" }}
            >
              <Box
                sx={{
                  width: "85%",
                  maxWidth: "800px",
                  textAlign: "center",
                  mx: "auto",
                }}
              >
                <LazyLoadImage
                  delayTime={2000}
                  src={HirenPatelArchitects}
                  width="200px"
                  alt="PrabhakarImg"
                />
                {/* <Typography sx={NameText}>
                  HIREN PATEL Architects HPA{" "}
                </Typography>
                <h1 className="ourArchitecText sequence fadeInBottom">
                  HPA was founded by Ar. Hiren Patel in 1989, the young
                  architect took upon himself to create a better world through
                  his unique art and designs. From the very beginning, HPA has
                  been passionate about creating spaces that inspire and
                  transform the way the world perceives art. The firm has now
                  grown exponentially with over three decades of experience in
                  the field of architecture, interior design, and landscape
                  design.
                </h1>
                <a
                  href="https://www.hpa.co.in/"
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  <h1 className="LinkStyleText">www.hpa.co.in</h1>
                </a> */}
                <Typography sx={NameText}>HIREN PATEL Achitects </Typography>
                <Typography sx={ArchitecNameStyle}>HPA </Typography>
                <h1 className="InvernessIntroText HpaIntroTExt sequence fadeInBottom">
                  HPA was founded by Ar. Hiren Patel in 1989, the young
                  architect took upon himself to create a better world through
                  his unique art and designs. From the very beginning, HPA has
                  been passionate about creating spaces that inspire and
                  transform the way the world perceives art. The firm has now
                  grown exponentially with over three decades of experience in
                  the field of architecture, interior design, and landscape
                  design.
                </h1>
                <a
                  href="https://www.hpa.co.in"
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  <h1 className="LinkStyleText">www.hpa.co.in</h1>
                </a>
              </Box>
            </Grid>
          </Grid>
        </AnimateFromDown>
      </Paper>
    </Paper>
  );
};

export default OurArchitects;

const NameText = {
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: { xl: "24px", lg: "24px", md: "24px", sm: "22px", xs: "22px" },
  lineHeight: "34px",
  color: "#333333",
  textAlign: "center",
  textTransform: "uppercase",
  mt: "20px",
  mb: "20px",
};

const BottomText = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: { xl: "16px", lg: "16px", md: "15px", sm: "14px", xs: "14px" },
  lineHeight: "24px",
  color: "#808186",
  textAlign: "center",
  animation: "slide-up 1.1s ease-in-out",
  "@keyframes slide-up": {
    "0%": {
      opacity: 0.2,
      lineHeight: "28px",
    },
    "100%": {
      opacity: 1,
      lineHeight: "24px",
    },
  },
};

const MainTitleStyle = {
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: { xl: "36px", lg: "36px", md: "30px", sm: "26px", xs: "26px" },
  lineHeight: "43px",
  color: "#333333",
  textAlign: "center",
  textTransform: "uppercase",
};

const LinkTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "14px",
  lineHeight: "31px",
  textAlign: "center",
  letterSpacing: "0.04em",
  color: "#5392BF",
  mt: "20px",
};

const ArchitecNameStyle = {
  fontSamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "14px",
  lineHeight: "21px",
  textAlign: "center",
  color: "#4F4F4F",
  mt: "30px",
};
