import React from "react";
import { Paper, Grid, Box, Typography } from "@mui/material";

// import InvernessB from "../../assets/Images/Inverness/InvernessB.png";

// import Carousel, { CarouselItem } from "../../components/carousel/Carousel";
import AnimateFromDown from "../../components/AnimateFromDown";
import "./Style.css";
// import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const InvernessAB = () => {
  return (
    <Paper elevation={0}>
      <Paper
        sx={{
          width: "100%",
          height: "100%",
          minHeight: "600px",
          marginTop: {
            xl: "100px",
            lg: "100px",
            md: "90px",
            sm: "30px",
            xs: "30px",
          },
          py: "2%",
        }}
        elevation={0}
      >
        <AnimateFromDown>
          <Grid
            container
            mt={3}
            sx={{
              py: "20px",
            }}
          >
            <Grid item xl={6} lg={6} md={5.5} sm={12} xs={12}>
              <Box sx={{ width: "90%", maxWidth: "601px", mx: "auto" }}>
                <Typography sx={TitleStyle}>inverness</Typography>
                <Typography
                  sx={{
                    ...TitleStyle,
                    fontSize: {
                      xl: "20px",
                      lg: "20px",
                      md: "20px",
                      sm: "20px",
                      xs: "20px",
                    },
                  }}
                >
                  <span style={{ color: "rgba(0, 0, 0, 0.4)" }}>@ chekhla</span>
                </Typography>
                <h1 className="InvernessIntroTextTitle">Limitless Luxury</h1>
                {/* <h1 className="InvernessIntroText sequence fadeInBottom">
                  World class amenities, unmatched privacy for limitless luxury
                  at The Banyan by Suryam Developers.
                </h1> */}
                <h1 className="InvernessIntroText sequence fadeInBottom">
                  The Inverness - Weekend Homes is a perfect location for
                  rejuvenation & recreation at the grassy environs of Sanand.
                  Carrying forward the rich legacy of Unispace Group, The
                  Inverness heralded a new chapter in the group’s illustrious
                  project portfolio. The Inverness - Weekend Homes is designed
                  by renowned Architect Shri Kamal Mangaldas.
                </h1>
                <h1 className="InvernessIntroText sequence fadeInBottom">
                  The Inverness is loaded with exciting amenities and striking
                  features nestled in green ambience which would undoubtedly
                  bring happiness, peace and prosperity in your life. The
                  Inverness enjoys the advantage of connectivity alongside a
                  strategic location. Blending pleasures of today with
                  prosperous returns of tomorrow, The Inverness will truly bring
                  you a future with fruitful returns.
                </h1>
              </Box>
            </Grid>
            <Grid item xl={6} lg={6} md={6.5} sm={12} xs={12}>
              {/* <Box sx={{ width: "100%", maxWidth: "600px", mr: 3, ml: "auto" }}>
              <div className="InvernessCaroImgThree  img-zoom"></div>
              </Box> */}
              <Box
                sx={{
                  width: "100%",
                  maxWidth: "597px",
                  ml: "auto",
                  mr: "auto",
                  mt: {
                    xl: "0px",
                    lg: "0px",
                    md: "20px",
                    sm: "20px",
                    xs: "20px",
                  },
                }}
              >
                <div className="InvernessCaroImgThree img-zoom"></div>
              </Box>
            </Grid>
          </Grid>
        </AnimateFromDown>
      </Paper>
    </Paper>
  );
};

export default InvernessAB;

const TitleStyle = {
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: { xl: "32px", lg: "32px", md: "28px", sm: "36px", xs: "25px" },
  color: "#333333",
  textAlign: "left",
  textTransform: "uppercase",
};
