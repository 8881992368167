import React, { useEffect } from "react";
import { Paper, Grid, Box, Typography, Button } from "@mui/material";
import AnimateFromDown from "../../components/AnimateFromDown";
const ContactInfo = () => {
  return (
    <Paper
      sx={{
        height: "100%",
        display: "grid",
        placeItems: "center",
        marginTop: {
          xl: "px",
          lg: "0px",
          md: "0px",
          sm: "-20px",
          xs: "-100px",
        },
      }}
      elevation={0}
    >
      <Paper elevation={0} sx={PaperStyle}>
        <Grid container>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <Box sx={ContactInformationBox} className="hover-shadow">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  pt: 5,
                }}
              >
                <Typography sx={MainTitleStyle}>LET’S TALK</Typography>
                <Typography sx={TextStyle}>+91 9898048844</Typography>
                <Typography sx={TextStyle}> +079 26445208</Typography>
                <AnimateFromDown>
                  <Typography sx={MailTextStyle1}>info@unispace.in</Typography>
                </AnimateFromDown>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            sx={{ mt: { xl: 0, lg: 0, md: 0, sm: 3, xs: 3 } }}
          >
            <Box sx={ContactInformationBox} className="hover-shadow">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  pt: 5,
                }}
              >
                <Typography sx={MainTitleStyle}>CORPORATE OFFICE</Typography>
                <Typography sx={TextStyle}>
                  8AB Centrepoint, Panchvati
                </Typography>
                <Typography sx={TextStyle}>
                  crossroads, Ambawadi, Ahmedabad
                </Typography>
                <Typography sx={TextStyle}>380006</Typography>
                <AnimateFromDown>
                  <Typography sx={MailTextStyle}>=Get Directions</Typography>
                </AnimateFromDown>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Paper>
  );
};

export default ContactInfo;

const ContactInformationBox = {
  width: "90%",
  maxWidth: "410px",
  height: "100%",
  background: "#F9F9F9",
  mx: "auto",
  borderRadius: "0px",
  mt: "50px",
  cursor: "pointer",
};

const MainTitleStyle = {
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: { xl: "24px", lg: "24px", md: "24px", sm: "22px", xs: "22px" },
  mb: 2,
  lineHeight: "32px",
  color: "#333333",
  textAlign: "left",
  letterSpacing: "0.7px",
  textTransform: "uppercase",
};

const MailTextStyle = {
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  mt: "15px",
  fontWeight: 600,
  fontSize: "14px",
  lineHeight: "26px",
  color: "#333333",
  textAlign: "left",
  letterSpacing: "0.7px",
  textTransform: "uppercase",
};
const MailTextStyle1 = {
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  mt: "43px",
  fontWeight: 600,
  fontSize: "14px",
  lineHeight: "26px",
  color: "#333333",
  textAlign: "left",
  letterSpacing: "0.7px",
  textTransform: "uppercase",
};

const TextStyle = {
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: { xl: "18px", lg: "16px", md: "14px", sm: "14px", xs: "14px" },
  lineHeight: "22px",
  color: "#868686",
  mt: 1,
  textAlign: "left",
};

const PaperStyle = {
  width: "100%",
  maxWidth: "1000px",
  height: "100%",
  minHeight: "300px",
  mt: "300px",
};
