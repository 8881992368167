import React from "react";
import { Typography } from "@mui/material";

import med1 from "../../assets/Images/Medhaansh/Rect5.jpg";
import "./Caroso.css";
const images = [
  {
    photo: med1,
    label: "image1",
    type: "",
    placeName: "Medhaansh",
    extension: "@shela extension",
    pageLink: "/medhaansh",
  },
];

const Caraousel2 = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 300,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1124,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div style={{ width: "100%", marginLeft: "auto", marginRight: "auto" }}>
        {images.map((step) => (
          <div key={step.label}>
            <div className="hoverImg">
              <Typography sx={TitleBottomText}>No ongoing Projects</Typography>
              {/* <img src={step.photo} alt={step.label} className="caroImg" />
              <Link to="/medhaansh">
                <div class="layer-one-pic">
                  <h5 style={{ fontFamily: "Playfair Display" }}>
                    {step.type}
                  </h5>
                  <h3 style={{ fontFamily: "Playfair Display" }}>
                    <Link
                      style={{ textDecoration: "none", color: "white" }}
                      to={step.pageLink}
                    >
                      {step.placeName}
                    </Link>{" "}
                    <span className="span">{step.extension}</span>{" "}
                  </h3>
                </div>
              </Link> */}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Caraousel2;

const TitleBottomText = {
  // fontFamily: "Kalam, cursive",
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 500,
  mt: "80px",
  mb: 1,
  fontSize: { xl: "21px", lg: "21px", md: "20px", sm: "20px", xs: "18px" },
  lineHeight: "25px",
  textAlign: "center",
  color: "#252733",
};
