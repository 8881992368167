import React, { useState } from "react";
import {
  Paper,
  Grid,
  Box,
  Typography,
  // Input,
  Button,
  // TextField,
  // Stack,
} from "@mui/material";
import "./conversion.css";
import "./startConversation.css";
import AnimateFromDown from "../../components/AnimateFromDown";

const AalloaLocation = () => {
  return (
    <Paper elevation={0} sx={{ py: 10 }} id="mapId">
      <Grid container mb={5}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Box sx={{ width: "98%", mx: "auto", height: "100px" }}>
            <Typography sx={MainTextStyle}>
              SITE{" "}
              <span style={{ fontWeight: 600, color: "black" }}>OFFICE</span>
            </Typography>
            <AnimateFromDown>
              <Typography sx={ChildTextStyle}>
                Beside Aalloa hills, Village Pinadara,Tal. Kalol, Dist
                Gandhinagar , Gujarat 382610
              </Typography>
            </AnimateFromDown>
          </Box>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Box>
            <iframe
              width="100%"
              height="550px"
              frameborder="0"
              marginheight="0"
              marginwidth="0"
              src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=aalloa%20hills%20resort+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            >
              <a href="https://www.maps.ie/distance-area-calculator.html">
                measure acres map
              </a>
            </iframe>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default AalloaLocation;

const MainTextStyle = {
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: { xl: "40px", lg: "36px", md: "25px", sm: "25px", xs: "22px" },
  lineHeight: { xl: "50px", lg: "50px", md: "45px", sm: "40px", xs: "35px" },
  letterSpacing: "1px",
  textTransform: "uppercase",
  color: "rgba(0, 0, 0, 0.4)",
  textAlign: "center",
};

const ChildTextStyle = {
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "25px",
  color: "#808186",
  textAlign: "center",
  mt:1
};
