import React, { useEffect } from "react";
import { Paper, Stack } from "@mui/material";
import Herosection from "../../Pages/AllohaHillsPages/Herosection";
import Aalloa from "../../Pages/AllohaHillsPages/AllohaIntro";
import Amentise from "../../Pages/AllohaHillsPages/Amentise";
import DownloadB from "../../Pages/AllohaHillsPages/DownloadB";
import OurArchitects from "../../Pages/AllohaHillsPages/OurArchitects";
// import OurPartners from "../../Pages/AllohaHillsPages/OurPartners";
// import LegalAdvisor from "../../Pages/AllohaHillsPages/LegalAdvisor";
import ContactInformation from "../../Pages/AllohaHillsPages/ContactInformation";
import GalleryWIthInfo from "../../Pages/AllohaHillsPages/GalleryWIthInfo";
import ImageSection from "../../Pages/AllohaHillsPages/ImageSection";
// import BrandCunsultant from "../../Pages/AllohaHillsPages/BrandCunsultant";
import GalaryGridVeiw from "../../Pages/AllohaHillsPages/GalaxyGridView/GalaryGridView";
import AllohaHillsGalary from "../../Pages/AllohaHillsPages/AalloaGalary";
// import AllohaSense from "../../Pages/AllohaHillsPages/AllohaSense/AllohaSense";
import Ourimagination from "../../Pages/AllohaHillsPages/Ourimagination";
import AirQuality from "../../Pages/AllohaHillsPages/AirQuality";
// import AllohaSense2ndVer from "../../Pages/AllohaHillsPages/AllohaSense/AllohaSense2ndVer";
import ImageMap from "../../components/ImageMap";

import Header from "../../components/Header/HomeHeader";
import AalloaLocation from "./AalloaLocation";

const AllohaHills = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Paper elevation={0}>
      <Header />
      <Herosection />
      <Stack
        sx={{
          width: {
            xl: "90%",
            lg: "90%",
            md: "95%",
            sm: "95%",
            xs: "95%",
            maxWidth: "2400px",
          },
          mx: "auto",
        }}
      >
        <Aalloa />
        <AirQuality />
        <GalleryWIthInfo />
        {/* <AllohaSense /> */}
        {/* <AllohaSense2ndVer /> */}
        <AllohaHillsGalary />
        <Amentise />
        <DownloadB />
      </Stack>
      <ImageSection />
      <Stack
        sx={{
          width: {
            xl: "90%",
            lg: "90%",
            md: "95%",
            sm: "95%",
            xs: "95%",
            maxWidth: "2400px",
          },
          mx: "auto",
        }}
      >
        <OurArchitects />

        {/* <OurPartners /> */}
        {/* <BrandCunsultant /> */}
        {/* <LegalAdvisor /> */}
        <GalaryGridVeiw />
        <Ourimagination />
        <ImageMap />
      </Stack>

      <AalloaLocation />
      <ContactInformation />
      {/* </Stack> */}
    </Paper>
  );
};

export default AllohaHills;
