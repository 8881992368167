import React, { useState } from "react";
import AmentiseImg from "../../assets/Images/Inverness/Amentise.png";
import { Paper, Grid, Box, Button, Typography } from "@mui/material";

import SwimmingPoolImg from "../../assets/Images/Inverness/Amentise/Four.png";
import ClubHouseImg from "../../assets/Images/Inverness/Amentise/Clubhouse.png";
import GardenImg from "../../assets/Images/Inverness/Amentise/Garden.png";
import TennisCourtImg from "../../assets/Images/Inverness/Amentise/TableTennis.png";
import GymImg from "../../assets/Images/Inverness/Amentise/Gym.png";
import JoggingImg from "../../assets/Images/Inverness/Amentise/jogging.png";
import LakeSideImg from "../../assets/Images/Inverness/Amentise/LakeSideSitting.png";
import GazeboImg from "../../assets/Images/Inverness/Amentise/Two.png";
import TableTennis from "../../assets/Images/Inverness/Amentise/Eight.png";
import Childrenplay from "../../assets/Images/Inverness/Amentise/Childrenplay.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import AnimateFromDown from "../../components/AnimateFromDown";
import "./Style.css";
import AmenitiesBox from "../../components/amenities";
import swim from "../../assets/Images/amenities/swim.png";
import childrenPlay from "../../assets/Images/amenities/Childrenplay.png";
import garden from "../../assets/Images/amenities/Garden.png";
import derasar from "../../assets/Images/amenities/deraser.png";
import jogging from "../../assets/Images/amenities/jogging.png";
import merakiGarden from "../../assets/Images/amenities/meraki.png";
import gym from "../../assets/Images/amenities/Gym.png";
import water from "../../assets/Images/amenities/water.png";
import event from "../../assets/Images/amenities/lawn.png";
import chess from "../../assets/Images/amenities/chess-pawn.png";
import pool from "../../assets/Images/amenities/pool.png";
import tableTennis from "../../assets/Images/amenities/table-tennis.png";
import carrom from "../../assets/Images/amenities/carrom.png";
import football from "../../assets/Images/amenities/football.png";

import Banquethall from "../../assets/Images/amenities/Banquethall.png";
import Indoorgames from "../../assets/Images/amenities/Indoorgames.png";
import Guestparking from "../../assets/Images/amenities/Guestparking.png";
import Seniorcitizenseatout from "../../assets/Images/amenities/Seniorcitizenseatout.png";

const Amentise = () => {
  return (
    <Paper
      sx={{
        height: "100%",
        minHeight: "500px",
        maxHeight: "auto",
        marginTop: "100px",
      }}
      elevation={0}
    >
      <Grid container mt={5}>
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{ textAlign: "center" }}
        >
          {/* <div className="Amentise-Div-Image">
            <Box
              sx={{
                position: "absolute",
                width: "80%",
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%)",
              }}
            > */}
          <Typography sx={TitleTextStyle}>AMENITIES</Typography>
        </Grid>

        <Grid
          item
          xl={11}
          lg={11}
          md={11}
          sm={11}
          xs={11}
          sx={{
            height: "100%",
            overflow: "scroll",
            mx: "auto",
            mt: "20px",
          }}
        >
          <Grid container>
            <Grid item xl={3} lg={3} md={3} sm={3} xs={6}>
              <AmenitiesBox imageUrl={swim} text={"Swimming Pool"} />
            </Grid>
            <Grid item xl={3} lg={3} md={3} sm={3} xs={6}>
              <AmenitiesBox
                imageUrl={childrenPlay}
                text={"Children's Play Area"}
              />
            </Grid>
            <Grid item xl={3} lg={3} md={3} sm={3} xs={6}>
              <AmenitiesBox imageUrl={merakiGarden} text={"Miyawaki Garden"} />
            </Grid>
            <Grid item xl={3} lg={3} md={3} sm={3} xs={6}>
              <AmenitiesBox imageUrl={derasar} text={"Derasar"} />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xl={3} lg={3} md={3} sm={3} xs={6}>
              <AmenitiesBox imageUrl={jogging} text={"Jogging Track"} />
            </Grid>
            <Grid item xl={3} lg={3} md={3} sm={3} xs={6}>
              <AmenitiesBox imageUrl={garden} text={"Garden"} />
            </Grid>
            <Grid item xl={3} lg={3} md={3} sm={3} xs={6}>
              <AmenitiesBox imageUrl={gym} text={"Gym"} />
            </Grid>
            <Grid item xl={3} lg={3} md={3} sm={3} xs={6}>
              <AmenitiesBox imageUrl={football} text={"Football"} />
            </Grid>
            {/* <Grid item xl={3} lg={3} md={3} sm={3} xs={6}>
              <AmenitiesBox imageUrl={water} text={"24*7 Water Supply"} />
            </Grid> */}
          </Grid>
          <Grid container>
            <Grid item xl={3} lg={3} md={3} sm={3} xs={6}>
              <AmenitiesBox imageUrl={event} text={"Event Lawn"} />
            </Grid>
            <Grid item xl={3} lg={3} md={3} sm={3} xs={6}>
              <AmenitiesBox imageUrl={pool} text={"Pool"} />
            </Grid>
            {/* <Grid item xl={3} lg={3} md={3} sm={3} xs={6}>
              <AmenitiesBox imageUrl={chess} text={"Chess"} />
            </Grid> */}
            <Grid item xl={3} lg={3} md={3} sm={3} xs={6}>
              <AmenitiesBox imageUrl={tableTennis} text={"Table Tennis"} />
            </Grid>
            <Grid item xl={3} lg={3} md={3} sm={3} xs={6}>
              <AmenitiesBox imageUrl={Banquethall} text={"Banquet Hall"} />
            </Grid>
          </Grid>
          {/* <Grid container>
            <Grid item xl={3} lg={3} md={3} sm={3} xs={6}>
              <AmenitiesBox imageUrl={carrom} text={"Carrom"} />
            </Grid>

            <Grid item xl={3} lg={3} md={3} sm={3} xs={6}>
              <AmenitiesBox imageUrl={Indoorgames} text={"Indoor games"} />
            </Grid>
          </Grid> */}
          {/* <Grid container>
            <Grid item xl={3} lg={3} md={3} sm={3} xs={6}>
              <AmenitiesBox imageUrl={Guestparking} text={"Guest parking"} />
            </Grid>
            <Grid item xl={3} lg={3} md={3} sm={3} xs={6}>
              <AmenitiesBox imageUrl={Seniorcitizenseatout} text={"Senior citizen seat out"} />
            </Grid>
          </Grid> */}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Amentise;

const TitleTextStyle = {
  fontFamily: "Playfair Display",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "32px",
  lineHeight: "38px",
  color: "#000000",
  textAlign: "center",
};

const TextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "18px",
  lineHeight: "30px",
  color: "#BABABA",
  Opacity: "0.8",
  mt: 2,
  textAlign: "justify",
};

const OnHoverTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "18px",
  lineHeight: "30px",
  color: "#000",
  Opacity: "0.8",
  mt: 2,
  textAlign: "justify",
};
